import constants from '../Utils/constants';
const initialState = {
    isGetProjectsLoading: false, isCreateProjectLoading: false, isUpdateProjectLoading: false, isPublishedProjectLoading: false, isDeleteProjectLoading: false,
    projects: [], commercialProject: [], residentialProject: [],landProject: []
}

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PROJECTS_REQUEST:
            return {
                ...state, isGetProjectsLoading: true
            }
        case constants.GET_PROJECTS_SUCCESS:
            if (action.getType === "Residential") {
                return {
                    ...state, isGetProjectsLoading: false, projects: action.payload, residentialProject: action.payload
                }
            }
            else if (action.getType === "Commercial") {
                return {
                    ...state, isGetProjectsLoading: false, projects: action.payload, commercialProject: action.payload
                }
            }
            else if (action.getType === "Land") {
                return {
                    ...state, isGetProjectsLoading: false, projects: action.payload, landProject: action.payload
                }
            }
            else {
            return {
                ...state, isGetProjectsLoading: false, projects: action.payload
            }
        }
        case constants.GET_PROJECTS_FAILURE:
            return {
                ...state, isGetProjectsLoading: false
            }

        case constants.GET_PROJECT_BY_ID_REQUEST:
            return {
                ...state, isGetProjectByIdLoading: true
            }
        case constants.GET_PROJECT_BY_ID_SUCCESS:
            return {
                ...state, isGetProjectByIdLoading: false
            }
        case constants.GET_PROJECT_BY_ID_FAILURE:
            return {
                ...state, isGetProjectByIdLoading: false
            }

        case constants.CREATE_PROJECT_REQUEST:
            return {
                ...state, isCreateProjectLoading: true
            }
        case constants.CREATE_PROJECT_SUCCESS:
            return {
                ...state, isCreateProjectLoading: false
            }
        case constants.CREATE_PROJECT_FAILURE:
            return {
                ...state, isCreateProjectLoading: false
            }

        case constants.UPDATE_PROJECT_REQUEST:
            return {
                ...state, isUpdateProjectLoading: true
            }
        case constants.UPDATE_PROJECT_SUCCESS:
            return {
                ...state, isUpdateProjectLoading: false
            }
        case constants.UPDATE_PROJECT_FAILURE:
            return {
                ...state, isUpdateProjectLoading: false
            }
            case constants.PUBLISHED_PROJECT_REQUEST:
                return {
                    ...state, isPublishedProjectLoading: true
                }
            case constants.PUBLISHED_PROJECT_SUCCESS:
                return {
                    ...state, isPublishedProjectLoading: false
                }
            case constants.PUBLISHED_PROJECT_FAILURE:
                return {
                    ...state, isPublishedProjectLoading: false
                }

        case constants.DELETE_PROJECT_REQUEST:
            return {
                ...state, isDeleteProjectLoading: true
            }
        case constants.DELETE_PROJECT_SUCCESS:
            return {
                ...state, isDeleteProjectLoading: false
            }
        case constants.DELETE_PROJECT_FAILURE:
            return {
                ...state, isDeleteProjectLoading: false
            }
        default:
            return state
    }
}
export default projectsReducer;