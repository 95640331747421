import constants from '../Utils/constants';
const initialState = {
    isGetTasksLoading: false, isGetPreTasksLoading: false, isGetPostTasksLoading: false, isCreateTaskLoading: false, 
    isUpdateTaskLoading: false, isDeleteTaskLoading: false,
    preTask: [], postTask: [], tasks: [],
}

const taskssReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PRE_TASKS_REQUEST:
            return {
                ...state, isGetPreTasksLoading: true
            }
        case constants.GET_PRE_TASKS_SUCCESS:
            return {
                ...state, isGetPreTasksLoading: false, preTask: action.payload
            }
        case constants.GET_PRE_TASKS_FAILURE:
            return {
                ...state, isGetPreTasksLoading: false
            }
            case constants.GET_POST_TASKS_REQUEST:
                return {
                    ...state, isGetPostTasksLoading: true
                }
            case constants.GET_POST_TASKS_SUCCESS:
                return {
                    ...state, isGetPostTasksLoading: false, postTask: action.payload
                }
            case constants.GET_POST_TASKS_FAILURE:
                return {
                    ...state, isGetPostTasksLoading: false
                }

        case constants.GET_TASKS_REQUEST:
            return {
                ...state, isGetTasksLoading: true
            }
        case constants.GET_TASKS_SUCCESS:
            return {
                ...state, isGetTasksLoading: false, tasks: action.payload
            }
        case constants.GET_TASKS_FAILURE:
            return {
                ...state, isGetTasksLoading: false
            }

        case constants.GET_TASK_BY_ID_REQUEST:
            return {
                ...state, isGetTaskByIdLoading: true
            }
        case constants.GET_TASK_BY_ID_SUCCESS:
            return {
                ...state, isGetTaskByIdLoading: false
            }
        case constants.GET_TASK_BY_ID_FAILURE:
            return {
                ...state, isGetTaskByIdLoading: false
            }

        case constants.CREATE_TASK_REQUEST:
            return {
                ...state, isCreateTaskLoading: true
            }
        case constants.CREATE_TASK_SUCCESS:
            return {
                ...state, isCreateTaskLoading: false
            }
        case constants.CREATE_TASK_FAILURE:
            return {
                ...state, isCreateTaskLoading: false
            }

        case constants.UPDATE_TASK_REQUEST:
            return {
                ...state, isUpdateTaskLoading: true
            }
        case constants.UPDATE_TASK_SUCCESS:
            return {
                ...state, isUpdateTaskLoading: false
            }
        case constants.UPDATE_TASK_FAILURE:
            return {
                ...state, isUpdateTaskLoading: false
            }

        case constants.DELETE_TASK_REQUEST:
            return {
                ...state, isDeleteTaskLoading: true
            }
        case constants.DELETE_TASK_SUCCESS:
            return {
                ...state, isDeleteTaskLoading: false
            }
        case constants.DELETE_TASK_FAILURE:
            return {
                ...state, isDeleteTaskLoading: false
            }
            case "REMOVING_USER_CREDENTIALS":
                return {
                    ...initialState
                }
        default:
            return state
    }
}
export default taskssReducer;