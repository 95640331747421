// eslint-disable-next-line
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography, Button, Modal } from "antd";
import "./styles/clients.css";
import no_image from "../assets/images/no_image.png";
const { Text, Title } = Typography;

export const ViewResidentialProjects = ({ id, setPopoverVisible }) => {
  const { projects } = useSelector((state) => state.projects);
  const [open, setOpen] = useState(false);
  const the_project = projects.filter((project) => project.id === id);
  the_project[0].area_id = the_project[0].area.id;
  const defaultValues = the_project[0];
  const {
    images,
    builder,
    property_name,
    project_type,
    area,
    no_of_floor,
    possesion,
    parking,
    description,
    tag_line,
    published,
  } = defaultValues;
  const image = images && images.length > 0 ? images[0].image_url : null;
  const handleOpenRProject = () => {
    setPopoverVisible(false)
    setOpen(true)
  }
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenRProject()}
      >
        ViewProject{" "}
      </Button>
      <Modal
        title="Residential Project Details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <Row gutter={[8, 0]}>
          <Col>
            <Title level={5}>
              Builder:{" "}
              <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                {builder}
              </Text>
            </Title>
          </Col>
        </Row>

       
            <div>
              {image && image.url ? (
                <img
                  className="pCardImage"
                  alt="example"
                  src={image.url}
                  title="View All Images"
                />
              ) : (
                <img
                  className="pCardImage"
                  alt="example"
                  src={no_image}
                  title="View All Images"
                />
              )}
            </div>
         
        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Property Name:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {property_name}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Project Type:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {project_type}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Area:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {area.name}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card>
          <Row>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                No Of Floor:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {no_of_floor}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Possesion:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {possesion}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Parking:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {parking}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
          {description && description.description && (
            <Row gutter={[48,0]}>
              {description.description.map((d, i) => (
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>
                    Type:{" "}
                    <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                      {d.key}
                    </Text>
                  </Title>
                  <Title level={5}>
                    SqFt.:{" "}
                    <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                      {d.value}
                    </Text>
                  </Title>
                </Col>
              ))}
            </Row>
          )}
        </Card>

        <Card>
          <Row>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Tag Line:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {tag_line}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Published:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {published === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
};
