/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";
import {
  Row,
  Col,
  Layout,
  Typography,
  Button,
  Select,
  Input,
  Image,
  Card,
  Space,
  Carousel,
  Spin,
} from "antd";
import { EnvironmentFilled, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./bannerimage.css";
import logo from "../assets/images/logo.svg";
import banner from "../assets/images/banner.png";
import { getPostProperties } from "../Actions/postPropertyAction";
import { getAllAreas } from "../Actions/areasAction";
import PostProperty from "../pages/postProperty";
import PropertyCard from "../components/PropertyCard";
import HomeFooter from "../components/Footer";
import HomeHeader from "../components/Header";
import { getSearchPostProperties } from "../Actions/postPropertyAction";

const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];
const { Text, Title } = Typography;
const { Header, Content, Footer } = Layout;
const BannerImage = (props) => {
  const { setAdvanceSearch } = props;
  const dispatch = useDispatch();
  const { isGetSearchPostPropertiesLoading, searchPostProperties } =
    useSelector((state) => state.postProperties);
  const { isGetPostPropertiesLoading, postProperties } = useSelector(
    (state) => state.postProperties
  );

  const { areas } = useSelector((state) => state.areas);
  useEffect(() => {
    dispatch(getAllAreas());
  }, []);

  const { handleSubmit, control, reset, watch } = useForm({});
  const watching = watch();
  const { property_type, area_id } = watching;
  const handleSearchProperty = (data) => {
    // const { property_type, area_id } = data;
    // const property_type = data.property_type && data.property_type === 0 ? property_type : null
    const property_type = data?.property_type
      ? data?.property_type
      : data?.property_type === 0
      ? data?.property_type
      : null;
    const area_id = data?.area_id ? data?.area_id : null;
    dispatch(getSearchPostProperties(property_type, area_id));
    reset();
  };

  return (
    <Row className="banner_image">
      <Col span={24}></Col>
      <Col span={24}>
        <Row>
          <Col
            xl={{ span: 14, offset: 2 }}
            lg={{ span: 14, offset: 2 }}
            md={{ span: 14, offset: 2 }}
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 22, offset: 2 }}
          >
            <Title className="bannerText">Best Properties for everyone</Title>
            <Typography className="bannerDescription">
              We provide a complete service for the sale, purchase or rental of
              real estate
            </Typography>
          </Col>
        </Row>

        <Row justify="start" style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Col
            xl={{ span: 14, offset: 2 }}
            lg={{ span: 16, offset: 2 }}
            md={{ span: 18, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xs={{ span: 20, offset: 2 }}
          >
            <form onSubmit={handleSubmit(handleSearchProperty)}>
              <Row
                // justify='space-between'
                gutter={10}
                align="middle"
                style={{
                  // background: "#F3F3FA",
                  backgroundColor: "rgba(255,255,255,0.25)",
                  // border: "1px solid #BDBDBD",
                  boxSizing: "border-box",
                  borderRadius: 4,
                  padding: 20,
                  margin: "20px 0px",
                }}
              >
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 8 }}
                  sm={24}
                  xs={24}
                >
                  <Controller
                    as={Select}
                    control={control}
                    options={PropertyType}
                    name="property_type"
                    placeholder="Property Type"
                    style={{ width: "100%", color: "#1C3988" }}
                    // className="property_type_box"
                  />
                </Col>
                <Col
                  xl={{ span: 16 }}
                  lg={{ span: 16 }}
                  md={{ span: 12 }}
                  sm={24}
                  xs={24}
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  <Controller
                    as={Select}
                    control={control}
                    options={
                      areas &&
                      areas.length > 0 &&
                      areas.map((a, i) => {
                        return { value: a.id, label: a.name };
                      })
                    }
                    name="area_id"
                    placeholder="Search of location"
                    prefix={<EnvironmentFilled />}
                    style={{ width: "100%", color: "#1C3988" }}
                  />
                </Col>
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 4 }}
                  sm={24}
                  xs={24}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {isGetSearchPostPropertiesLoading ? (
                    <Spin
                      className="create_client_field"
                      spinning={isGetSearchPostPropertiesLoading}
                    ></Spin>
                  ) : (
                    <Button
                      htmlType="submit"
                      // onClick={handleSearchProperty}
                      style={{
                        backgroundColor: "#1C3988",
                        color: "white",
                        border: "1px solid #BDBDBD",
                        boxSizing: "border-box",
                        borderRadius: 4,
                      }}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          {/* <Col
						xl={{ span: 8, offset: 2 }}
						lg={{ span: 8, offset: 2 }}
						md={{ span: 8, offset: 2 }}
						sm={{ span: 22, offset: 2 }}
						xs={{ span: 22, offset: 2 }}
					>
						<Typography
							onClick={() => setAdvanceSearch(true)}
							style={{ fontWeight: 900, fontSize: 18, color: "white" }}
						>
							Advance Search
						</Typography>
					</Col> */}
        </Row>
      </Col>
    </Row>
  );
};
export default BannerImage;
