/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";
import { getAllProjects } from "../Actions/projectsAction";

import {
  Row,
  Col,
  Layout,
  Typography,
  Input,
  Image,
  Card,
  Space,
  Carousel,
} from "antd";
import { EnvironmentFilled, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./styles/navbar.css";
import logo from "../assets/images/logo.svg";
import banner from "../assets/images/banner.png";
import { getPostProperties } from "../Actions/postPropertyAction";
import { getAllAreas } from "../Actions/areasAction";
import PostProperty from "./postProperty";
import PropertyCard from "../components/PropertyCard";
import HomeFooter from "../components/Footer";
import HomeHeader from "../components/Header";
import BannerImage from "../components/BannerImage";
import Properties from "../components/Properties";
import ProjectGallery from "../components/ProjectGallery";
import Projects from "../components/Projects";
import AdvanceSearch from "../components/advanceSearch";
import AboutUs from "../components/AboutUs";
import PropertyServices from "../components/PropertyServices";

const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];
const { Text } = Typography;
const { Header, Content, Footer } = Layout;
const Landing = () => {
  const dispatch = useDispatch();
  const { isGetPostPropertiesLoading, postProperties } = useSelector(
    (state) => state.postProperties
  );
  const { areas } = useSelector((state) => state.areas);
  const { Meta } = Card;
  const [advSearch, setAdvanceSearch] = useState(false);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const buyRef = useRef();
  const rentRef = useRef();
  const sellRef = useRef();
  const serviceRef = useRef();
  const aboutUsRef = useRef();
  const {
    projects,
    residentialProject,
    commercialProject,
    landProject,
    isGetProjectsLoading,
  } = useSelector((state) => {
    const {
      projects,
      residentialProject,
      commercialProject,
      landProject,
      isGetProjectsLoading,
    } = state.projects;
    return {
      projects,
      residentialProject,
      commercialProject,
      landProject,
      isGetProjectsLoading,
    };
  });
  useEffect(() => {
    dispatch(getPostProperties());
    dispatch(getAllProjects("Residential"));
    dispatch(getAllProjects("Commercial"));
    dispatch(getAllProjects("Land"));
  }, []);

  const { control } = useForm({});
  return (
    <>
      <Layout>
        <Header className="header">
          <HomeHeader
            buyRef={buyRef}
            rentRef={rentRef}
            sellRef={sellRef}
            serviceRef={serviceRef}
            aboutUsRef={aboutUsRef}
          />
        </Header>

        <Content>
          <BannerImage
            advSearch={advSearch}
            setAdvanceSearch={setAdvanceSearch}
          />
          {advSearch && <AdvanceSearch></AdvanceSearch>}
          {/* <Properties /> */}
          {/* <ProjectGallery title="Project Gallery" refTag={buyRef} /> */}
          <Projects title="Residential Plot" projects={landProject} />
          <Projects title="Residential Project" projects={residentialProject} />
          <Projects title="Commercial Project" projects={commercialProject} />

          {/* <ProjectGallery title="Sell" refTag={sellRef}/> */}
          {/* <ProjectGallery title="Rent" refTag={rentRef} /> */}
          <PropertyServices serviceRef={serviceRef} />
          <AboutUs aboutUsRef={aboutUsRef} />
        </Content>

        <Footer className="footer_header">
          <HomeFooter />
        </Footer>
      </Layout>
    </>
  );
};
export default Landing;
