import constants from '../Utils/constants';
const initialState = {
    isGetPostPropertiesLoading: false, isGetSearchPostPropertiesLoading: false, isCreatePostPropertyLoading: false,
    isUpdatePostPropertyLoading: false,
    postProperties: [], searchPostProperties: []
}

const postPropertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_POST_PROPERTIES_REQUEST:
            return {
                ...state, isGetPostPropertiesLoading: true
            }
        case constants.GET_POST_PROPERTIES_SUCCESS:
            return {
                ...state, isGetPostPropertiesLoading: false, postProperties: action.payload
            }
        case constants.GET_POST_PROPERTIES_FAILURE:
            return {
                ...state, isGetPostPropertiesLoading: false
            }

            case constants.GET_SEARCH_POST_PROPERTIES_REQUEST:
                return {
                    ...state, isGetSearchPostPropertiesLoading: true
                }
            case constants.GET_SEARCH_POST_PROPERTIES_SUCCESS:
                return {
                    ...state, isGetSearchPostPropertiesLoading: false, searchPostProperties: action.payload
                }
            case constants.GET_SEARCH_POST_PROPERTIES_FAILURE:
                return {
                    ...state, isGetSearchPostPropertiesLoading: false
                }

        case constants.CREATE_POST_PROPERTY_REQUEST:
            return {
                ...state, isCreatePostPropertyLoading: true
            }
        case constants.CREATE_POST_PROPERTY_SUCCESS:
            return {
                ...state, isCreatePostPropertyLoading: false
            }
        case constants.CREATE_POST_PROPERTY_FAILURE:
            return {
                ...state, isCreatePostPropertyLoading: false
            }

            case constants.UPDATE_POST_PROPERTY_REQUEST:
                return {
                    ...state, isUpdatePostPropertyLoading: true
                }
            case constants.UPDATE_POST_PROPERTY_SUCCESS:
                return {
                    ...state, isUpdatePostPropertyLoading: false
                }
            case constants.UPDATE_POST_PROPERTY_FAILURE:
                return {
                    ...state, isUpdatePostPropertyLoading: false
                }
        
        default:
            return state
    }
}
export default postPropertiesReducer;