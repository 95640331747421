import constants from "../Utils/constants";
const initialState = {
  isGetUsersLoading: false,
  isCreateUserLoading: false,
  isUpdateUserLoading: false,
  isDeleteUserLoading: false,
  isAuth0UserLoading: false,
  users: [],
  auth0User: [],
  isGetCurrentUserLoading: false,
  currentUser: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        users: action.payload,
      };
    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };

    case constants.GET_AUTH0_USERS_REQUEST:
      return {
        ...state,
        isAuth0UserLoading: true,
      };
    case constants.GET_AUTH0_USERS_SUCCESS:
      return {
        ...state,
        auth0User: action.payload,
        isAuth0UserLoading: false,
      };
    case constants.GET_AUTH0_USERS_FAILURE:
      return {
        ...state,
        isAuth0UserLoading: false,
      };
    case constants.GET_AUTH0_USERS_RECONSILE_REQUEST:
      return {
        ...state,
      };
    case constants.GET_AUTH0_USERS_RECONSILE_SUCCESS:
      return {
        ...state,
      };
    case constants.GET_AUTH0_USERS_RECONSILE_FAILURE:
      return {
        ...state,
      };
    case constants.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        isGetCurrentUserLoading: true,
      };
    case constants.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isGetCurrentUserLoading: false,
        currentUser: action.payload,
      };
    case constants.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        isGetCurrentUserLoading: false,
      };

    case constants.CREATE_USER_REQUEST:
      return {
        ...state,
        isCreateUserLoading: true,
      };
    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        isCreateUserLoading: false,
      };
    case constants.CREATE_USER_FAILURE:
      return {
        ...state,
        isCreateUserLoading: false,
      };

    case constants.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdateUserLoading: true,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdateUserLoading: false,
      };
    case constants.UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdateUserLoading: false,
      };

    case constants.DELETE_USER_REQUEST:
      return {
        ...state,
        isDeleteUserLoading: true,
      };
    case constants.DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleteUserLoading: false,
      };
    case constants.DELETE_USER_FAILURE:
      return {
        ...state,
        isDeleteUserLoading: false,
      };
    case "REMOVING_USER_CREDENTIALS":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default usersReducer;
