import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./Utils/store";
import { PersistGate } from "redux-persist/integration/react";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { PrivateRoute } from "./Utils/AppRoute";
import { history } from "./Utils/history";
import { setUser } from "./Actions/authAction";
import AppView from "./components/AppView";
import Landing from "./pages/Landing";
import NewProperty from "./pages/newProperty";

function App() {
  // const dispatch = useDispatch();
  const data = useAuth0();
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = data;
  // if (isAuthenticated) {

  //   // const result = await getIdTokenClaims();
  //   // if (result.__raw) {
  //   //   localStorage.setItem("access_token", result.__raw);
  //   // }

  //   //dispatch(setUser({ user: user, isAuthenticated: isAuthenticated }));
  // }

  if (isLoading)
    return (
      <Spin
        size="large"
        spinning={isLoading}
        style={{
          margin: "auto",
          marginTop: 200,
          width: "100%",
        }}
      />
    );
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route exact path="/" component={Landing} layout={Landing} />
          <Route
            exact
            path="/post"
            component={NewProperty}
            layout={NewProperty}
          />
          <PrivateRoute exact path="/app" component={AppView} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
