import React from "react";
import {
	Row,
	Col,
	Select,
	Button,
	Divider,
	Slider,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { EnvironmentFilled, SearchOutlined } from "@ant-design/icons";
const PropertyType = [
	{ value: 0, label: "Flat" },
	{ value: 1, label: "Pent House" },
	{ value: 2, label: "Bungalow" },
	{ value: 3, label: "Tenements" },
	{ value: 4, label: "Duplex" },
	{ value: 5, label: "Row House" },
	{ value: 6, label: "Fam House" },
	{ value: 7, label: "Office" },
	{ value: 8, label: "Shop" },
	{ value: 9, label: "Showroom" },
	{ value: 10, label: "Godown" },
	{ value: 11, label: "Wear House" },
	{ value: 12, label: "Residential" },
	{ value: 13, label: "Commercial" },
	{ value: 14, label: "Agriculture" },
	{ value: 15, label: "NonAgriculture" },
	{ value: 16, label: "Redevelopment" },
];
const AdvanceSearch = () => {
	const { control } = useForm({});

	return (
		<Row style={{ background: "#F3F3FA" }} justify="center">
			<Col style={{ padding: 40 }}>
				<Row justify="center" gutter={10}>
					<Col
						xl={{ span: 4 }}
						lg={{ span: 4 }}
						md={{ span: 4 }}
						sm={24}
						xs={24}
					>
						<Controller
							as={Select}
							control={control}
							options={PropertyType}
							name="property_type"
							placeholder="Property Type"
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
					<Col
						xl={{ span: 16 }}
						lg={{ span: 16 }}
						md={{ span: 16 }}
						sm={24}
						xs={24}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
					<Col
						xl={{ span: 4 }}
						lg={{ span: 4 }}
						md={{ span: 4 }}
						sm={24}
						xs={24}
						style={{ display: "flex", justifyContent: "space-around" }}
					>
						<Button
							style={{
								backgroundColor: "#1C3988",
								color: "white",
								border: "1px solid #BDBDBD",
								boxSizing: "border-box",
								borderRadius: 4,
							}}
							icon={<SearchOutlined />}
						>
							Search
						</Button>
					</Col>
				</Row>
				<Divider style={{ fontSize: 12, color: "#141B2D", opacity: 0.5 }}>
					Filter Settings
				</Divider>
				{/* <Row justify="center">
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
						style={{ paddingTop: 5, paddingBottom: 5 }}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
				</Row> */}
				<Row gutter={10}>
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
						style={{ paddingTop: 5, paddingBottom: 5 }}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
						style={{ paddingTop: 5, paddingBottom: 5 }}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ minWidth: 200, width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
						style={{ paddingTop: 5, paddingBottom: 5 }}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
						style={{ paddingTop: 5, paddingBottom: 5 }}
					>
						<Controller
							as={Select}
							control={control}
							options={[]}
							name="property_type"
							placeholder="Search of location"
							prefix={<EnvironmentFilled />}
							style={{ width: "100%", color: "#1C3988" }}
							// className="property_type_box"
						/>
					</Col>
				</Row>
				<Row justify="center">
					<Col
						xl={{ span: 6 }}
						lg={{ span: 6 }}
						md={{ span: 6 }}
						sm={24}
						xs={24}
					>
						<Slider
							marks={{ 20: "20", 50: "50" }}
							range
							step={10}
							defaultValue={[20, 50]}
							// onChange={onChange}
							// onAfterChange={onAfterChange}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AdvanceSearch;
