import constants from '../Utils/constants';
const initialState = {
    isGetPublicActivitiesLoading: false,
    publicActivities: []
}

const activitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_ACTIVITIES_REQUEST:
            return {
                ...state, isGetPublicActivitiesLoading: true
            }
        case constants.GET_ACTIVITIES_SUCCESS:
            return {
                ...state, isGetPublicActivitiesLoading: false, publicActivities: action.payload
            }
        case constants.GET_ACTIVITIES_FAILURE:
            return {
                ...state, isGetPublicActivitiesLoading: false
            }
        
        default:
            return state
    }
}
export default activitiesReducer;