import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Steps,
  Divider,
  message,
  Spin,
} from "antd";
// import { EditTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateLead, getAllLeads, getleadtype } from "../Actions/leadsActions";
import "./styles/clients.css";
import { LoadingOutlined } from "@ant-design/icons";
const LeadType = [
  { value: 1, label: "Flat" },
  { value: 2, label: "Pent House" },
  { value: 3, label: "Bunglow" },
  { value: 4, label: "Tenament" },
  { value: 5, label: "Duplex" },
  { value: 6, label: "Row House" },
  { value: 7, label: "Fam House" },
  { value: 8, label: "Office" },
  { value: 9, label: "Shop" },
  { value: 10, label: "Showroom" },
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 13, label: "Residential" },
  { value: 14, label: "Commercial" },
  { value: 15, label: "Agriculture" },
  { value: 16, label: "NonAgriculture" },
  { value: 17, label: "Redevelopment" },
];
const ClientType = [
  { value: 1, label: "Residential" },
  { value: 2, label: "Residential Rent" },
  { value: 3, label: "Commercial" },
  { value: 4, label: "Commercial Rent" },
  { value: 5, label: "Plot" },
];
const Zone = [
  { value: 1, label: "R1" },
  { value: 2, label: "R2" },
  { value: 3, label: "R3" },
];
const LeadAreaType = [
  { value: 1, label: "SqFt" },
  { value: 2, label: "Yard" },
];

const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Lead Details",
    // content: 'Second-content',
  },
  // {
  //   title: "Amenity",
  //   // content: 'Last-content',
  // },
];

const schema = yup.object().shape({
  client_id: yup.string().required("Required"),
  // area_id: yup.string().required("Required"),
  // lead_type: yup.number().transform(value => (isNaN(value) ? undefined : value)).required("Required"),
  min_budget: yup.string().required("Required"),
  max_budget: yup.string().required("Required"),
  // priority: yup.string().required("Required"),
  // gender: yup.string().required("Required"),
  // dete_of_birth: yup.string().required("Required"),
  // date_of_anniversary: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  // landmark: yup.string().required("Required"),
  // area: yup.string().required("Required"),
  // city: yup.string().required("Required"),
  // budget: yup.string().required("Required"),
  // home_number: yup.string().matches(phoneRegExp).required("Required"),
  // office_number: yup.string().matches(phoneRegExp).required("Required"),
  // email: yup.string().email().required("Required"),
  // business: yup.string().required("Required"),
  // company: yup.string().required("Required"),
  // designation: yup.string().required("Required"),
  // reference: yup.string().required("Required"),
  // note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdateLead = ({ id, setPopoverVisible, isurgentLead }) => {
  const dispatch = useDispatch();
  const { leads, isUpdateLeadLoading } = useSelector((state) => state.leads);
  const { clients } = useSelector((state) => state.clients);
  const { areas } = useSelector((state) => state.areas);

  const { lead_typs } = useSelector((state) => {
    const { leads } = state;
    const { lead_typs } = leads;
    return {
      lead_typs,
    };
  });

  const the_lead = leads.filter((lead) => lead.id === id);
  const newDate = the_lead[0].created_at
    ? the_lead[0].created_at.split("/")
    : {};
  const newDate1 = the_lead[0].last_communication_date
    ? the_lead[0].last_communication_date.split("/")
    : {};
  the_lead[0].created_at =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  the_lead[0].last_communication_date =
    newDate1.length > 2
      ? `${newDate1[2]}-${newDate1[1]}-${newDate1[0]}`
      : newDate1[0];
  const [open, setOpen] = useState(false);
  the_lead[0].area_id = the_lead[0]?.area?.id;
  the_lead[0].client_id = the_lead[0]?.client?.id;

  const leadtypes = the_lead[0]?.types_of_lead?.types;
  const area = the_lead[0]?.areas_of_lead?.map((id) => id.area_id);

  const [selectMultipleArea, setSelectMultipleArea] = useState(area);
  const [selectMultipleLeadTypes, SetselectMultipleLeadTypes] =
    useState(leadtypes);

  useEffect(() => {
    dispatch(getleadtype());
  }, []);

  const {
    handleSubmit,
    errors,
    control,
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_lead[0],
    mode: "onChange",
  });
  const watching = getValues([
    "is_residential",
    "is_commercial",
    "is_plot",
    "client_id",
    "area_id",
    "lead_type",
    "min_budget",
    "max_budget",
  ]);
  const { is_residential, is_commercial } = watching;
  const [createDialog, setCreateDialog] = useState(false);

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    console.log("called next");
    if (selectMultipleArea.length === 0) {
      console.log("selectMultipleArea.length > 0", { selectMultipleArea });
      setError("area_ids", {
        types: {
          required: "Area is required",
        },
      });
    } else if (selectMultipleLeadTypes.length === 0) {
      console.log("selectMultipleArea.length > 0", { selectMultipleLeadTypes });
      setError("types_of_lead", {
        types: {
          required: "Lead type is required",
        },
      });
    } else {
      const {
        client_id: cid,
        area_ids: aid,
        types_of_lead: lT,
        min_budget: minbgt,
        max_budget: maxbgt,
      } = getValues([
        "client_id",
        "area_ids",
        "types_of_lead",
        "min_budget",
        "max_budget",
      ]);

      if (current === 0 && (!cid || !aid || !lT || !minbgt || !maxbgt)) {
        trigger([
          "client_id",
          "area_ids",
          "types_of_lead",
          "min_budget",
          "max_budget",
        ]);
      }
      // else if (current === 1 && !bgt) {
      //   trigger(["budget"]);
      // }
      else {
        setCurrent(current + 1);
      }
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  // const handleUpdateLead = (data, event) => {
  //   // event.preventDefault();
  //   data.id = id;
  //   setTimeout(() => {
  //     message.success("Update Lead successful.");
  //   }, 1000);
  //   dispatch(updateLead(data, `/leads?is_urgent=${isurgentLead}`));
  //   setOpen(false);
  //   setCurrent(0);
  //   reset(data);
  // };
  const handleUpdateLead = (data, event) => {
    event.preventDefault();
    data.id = id;
    data.area_ids = selectMultipleArea;
    data.types_of_lead = selectMultipleLeadTypes;
    // dispatch(updateLead(data,`/leads?is_urgent=${isurgentLead}`, UpdateCallBack));
    dispatch(
      updateLead(
        data,
        `/leads?is_urgent=${isurgentLead}`,
        successUpdateLead,
        failureUpdateLead
        // IsEmpty
      )
    );
  };
  const successUpdateLead = () => {
    setCreateDialog(false);
    setCurrent(0);
    setOpen(false);
    reset();
    setTimeout(() => {
      message.success("You have successfully update lead.");
    }, 2000);
    // dispatch(getAllLeads(`/leads?is_urgent=${isurgentLead}`));
  };
  const failureUpdateLead = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  // const UpdateCallBack = (data) => {
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset(data);
  //   setOpen(false);
  //   setTimeout(() => {
  //     message.success("Update Lead successful.");
  //   }, 2000);
  //   dispatch(getAllLeads(`/leads?is_urgent=${isurgentLead}`));
  // };
  const handleCancel = () => {
    setOpen(false);
    setCurrent(0);
    clearErrors();
    reset();
  };
  const handleUpdateLeadOpen = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  const onMultipleSelectAreaHandler = (data) => {
    setSelectMultipleArea(data);
    setError("area_ids", {});
  };

  const onMultipleSelectLeadTypeHandler = (data) => {
    SetselectMultipleLeadTypes(data);
    setError("types_of_lead", {});
  };

  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleUpdateLeadOpen()}
      >
        Edit
      </Button>

      <Modal
        title="Edit Lead details"
        visible={open}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Steps
          current={current}
          size="small"
          responsive="true"
          labelPlacement="vertical"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <form onSubmit={handleSubmit(handleUpdateLead)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col>
            {/* <Button type="link" onClick={() => setcreateNewClientDialog(true)}>Create New</Button>
        {createDialog && (
        <Clients  
        createNewClientDialog={createNewClientDialog}
        setcreateNewClientDialog={setcreateNewClientDialog} 
          // handleDialog={() => setCreateDialog(true)} 

           />
      )} */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ClientType}
                style={{ width: "100%" }}
                name="client_type"
              />
              {errors.client_type && (
                <span className="error_style">Client Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Zone</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Zone}
                name="zone"
                style={{ width: "100%" }}
              />
              {errors.zone && (
                <span className="error_style">Zone is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Areas</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    showSearch={false}
                    value={selectMultipleArea}
                    options={
                      areas &&
                      areas.length > 0 &&
                      areas.map((val, index) => {
                        return { label: val.name, value: val.id };
                      })
                    }
                    placeholder="Select areas"
                    style={{ width: "100%" }}
                    onChange={(value) => onMultipleSelectAreaHandler(value)}
                  />
                )}
                name="area_ids"
              />
              {errors?.area_ids?.types?.required && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="vertical" className="create_client_field">
                <Text>Priority</Text>
                <Controller
                  as={Input}
                  name="priority"
                  type="number"
                  control={control}
                  placeholder="priority"
                  style={{ width: 200 }}
                />
                {errors.priority && (
                  <span className="error_style">Priority is required</span>
                )}
              </Space>
            </Col> */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Types</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    showSearch={false}
                    value={selectMultipleLeadTypes}
                    options={lead_typs}
                    placeholder="select lead types"
                    style={{ width: "100%" }}
                    onChange={(value) => onMultipleSelectLeadTypeHandler(value)}
                  />
                )}
                name="types_of_lead"
              />
              {errors?.types_of_lead?.types?.required && (
                <span className="error_style">Lead Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              // style={{
              //   display:
              //     current === 1 && is_residential === true ? "block" : "none",
              // }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Bhk</Text>
              </Row>
              <Controller
                as={Input}
                name="bhk"
                control={control}
                placeholder="Total Bhk"
                type="number"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Residential?</Text>
                <Controller
                  name="is_residential"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_commercial', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Commercial?</Text>
                <Controller
                  name="is_commercial"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Plot?</Text>
                <Controller
                  name="is_plot"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_commercial', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Budget</Text>
              </Row>
              <Controller
                name="min_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.min_budget && (
                <span className="error_style">Minimum Budget is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Budget</Text>
              </Row>
              <Controller
                name="max_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.max_budget && (
                <span className="error_style">Maximum Budget is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction After</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_after"
                control={control}
                placeholder="Construction After"
              />
              {errors.construction_after && (
                <span className="error_style">
                  Construction After is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>is New?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={5}
              lg={5}
              md={5}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>is argent?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_urgent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>is Resell?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>Home Loan?</Text>
                <Controller
                  name="home_loan"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}

          <Row></Row>
          {/* <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Rent?</Text>
                <Controller
                  name="is_rent"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Lease?</Text>
                <Controller
                  name="is_lease"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {" "}
              <Text>Details</Text>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[minimum_sqFt]"
                type="number"
                control={control}
                placeholder="Minimum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[maximum_sqFt]"
                type="number"
                control={control}
                placeholder="Maximum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Area Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={LeadAreaType}
                name="lead_area_type"
                style={{ width: "100%" }}
              />
              {errors.lead_area_type && (
                <span className="error_style">Lead Area Type is required</span>
              )}
            </Col>
          </Row>
          {/* <Button
                type="primary"
                htmlType="submit"
                block
                style={{ top: 10 }}
              >
                Create Lead
              </Button> */}
          <Row>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Furniture</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Air Condition?</Text>
                <Controller
                  name="lead_furniture_attributes[air_condition]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Amenity</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Water 24hr?</Text>
                <Controller
                  name="lead_amenity_attributes[water_24hr]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Png?</Text>
                <Controller
                  name="lead_amenity_attributes[png]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Security?</Text>
                <Controller
                  name="lead_amenity_attributes[security]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>cctv?</Text>
                <Controller
                  name="lead_amenity_attributes[cctv]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Lift?</Text>
                <Controller
                  name="lead_amenity_attributes[lift]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Parking?</Text>
                <Controller
                  name="lead_amenity_attributes[common_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Paid parking?</Text>
                <Controller
                  name="lead_amenity_attributes[paid_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Garden?</Text>
                <Controller
                  name="lead_amenity_attributes[garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Children Play Area?</Text>
                <Controller
                  name="lead_amenity_attributes[children_play_area]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Club House?</Text>
                <Controller
                  name="lead_amenity_attributes[club_house]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Gymnasium?</Text>
                <Controller
                  name="lead_amenity_attributes[gymnasium]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Plot?</Text>
                <Controller
                  name="lead_amenity_attributes[common_plot]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Nearby</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Religious Place?</Text>
                <Controller
                  name="lead_nearby_attributes[religious_place]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Park Garden?</Text>
                <Controller
                  name="lead_nearby_attributes[park_garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Educational Institute?</Text>
                <Controller
                  name="lead_nearby_attributes[educational_institute]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Hospital/Dispansary?</Text>
                <Controller
                  name="lead_nearby_attributes[hospital_dispansary]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>

            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Mall/market?</Text>
                <Controller
                  name="lead_nearby_attributes[mall_market]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Bus Stop?</Text>
                <Controller
                  name="lead_nearby_attributes[bus_stop]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row justify="end">
            {!isUpdateLeadLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <>
                {isUpdateLeadLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                )}
              </>
            )}
          </Row>
          {/* <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{ top: 10 }}
                >
                  Update Lead
                </Button> */}
        </form>
      </Modal>
    </>
  );
};
