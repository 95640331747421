import constants from '../Utils/constants';
const initialState = {
    isGetInterestedsLoading: false, isCreateInterestedLoading: false, isUpdateInterestedLoading: false, isDeleteInterestedLoading: false,
    interesteds: [],
}

const interestedsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_INTERESTEDS_REQUEST:
            return {
                ...state, isGetInterestedsLoading: true
            }
        case constants.GET_INTERESTEDS_SUCCESS:
            return {
                ...state, isGetInterestedsLoading: false, interesteds: action.payload
            }
        case constants.GET_INTERESTEDS_FAILURE:
            return {
                ...state, isGetInterestedsLoading: false
            }

        case constants.CREATE_INTERESTED_REQUEST:
            return {
                ...state, isCreateInterestedLoading: true
            }
        case constants.CREATE_INTERESTED_SUCCESS:
            return {
                ...state, isCreateInterestedLoading: false
            }
        case constants.CREATE_INTERESTED_FAILURE:
            return {
                ...state, isCreateInterestedLoading: false
            }

        case constants.UPDATE_INTERESTED_REQUEST:
            return {
                ...state, isUpdateInterestedLoading: true
            }
        case constants.UPDATE_INTERESTED_SUCCESS:
            return {
                ...state, isUpdateInterestedLoading: false
            }
        case constants.UPDATE_INTERESTED_FAILURE:
            return {
                ...state, isUpdateInterestedLoading: false
            }

        case constants.DELETE_INTERESTED_REQUEST:
            return {
                ...state, isDeleteInterestedLoading: true
            }
        case constants.DELETE_INTERESTED_SUCCESS:
            return {
                ...state, isDeleteInterestedLoading: false
            }
        case constants.DELETE_INTERESTED_FAILURE:
            return {
                ...state, isDeleteInterestedLoading: false
            }
        default:
            return state
    }
}
export default interestedsReducer;