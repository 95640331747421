import React from "react";
import { Row, Col, Typography, Image, Card } from "antd";
import no_image from "../assets/images/no_image.png";
// import Rupees from "../assets/images/rupees.svg";
import Semifurnished from "../assets/images/semifurnished.svg";
import Home from "../assets/images/home.svg";
// import Sqft from "../assets/images/sqft.svg";
import Allot from "../assets/images/allot.svg";
import Location from "../assets/images/location.svg";
import "../components/propertycard.css";
const ProjectCard = (props) => {
  const { images, tag_line, builder, area, property_name, parking, description, project_type } = props.project;
  const image = images && images.length > 0 ? images[0].image_url : null
  return (
    <div style={{ padding: 15 }}>
      <Card className="pCard" bodyStyle={{ padding: 15 }} hoverable
      cover={
        <div>
						{image && image.url ? <img
							className="pCardImage"
							alt="example"
							src={image.url}
							title="View All Images"
            /> :
            <img
							className="pCardImage"
							alt="example"
							src={no_image}
							title="View All Images"
            />}
					</div>
      }
      >
         {/* <Row>
          <Col span={12}>
            <Typography >{tag_line}</Typography>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" value="small">
              For sale
            </Button>
          </Col>
        </Row> */}

        <Row gutter={10} style={{ paddingTop: 20 }}>
          <Col span={24}>
            <Typography className="typography1">{builder}</Typography>
          </Col>
        </Row>

        <Row justify="start">
          <Col>
            <Typography className="typography2">
              <Image preview={false} src={Location} />
              {area && area.name}
            </Typography>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col span={24}>
            <Typography className="price">
              <Image preview={false} src={Rupees} /> 50.3 Lac
            </Typography>
          </Col>
        </Row> */}

        <Row justify="space-between">
          <Col>
            <Row>
              <img src={Semifurnished} alt="" />
              <Typography className="semi_furnished">{tag_line}</Typography>
            </Row>
          </Col>
        </Row>
        <Row>
              <img src={Home} alt="" />
              <Typography className="semi_furnished">
                {property_name}
              </Typography>
            </Row>
        { description && description.description && (
        <Row justify='center' gutter={[8,0]}>
          {description.description.map((d,i) => 
          <Col key={i} span={24}>
            <Row justify='center' gutter={[8,0]}>
              <Col span={24}></Col>
              <Col>
          <Typography>{d.key}</Typography>
          </Col>
          <Col>
          <Typography>{d.value}</Typography>
          </Col>
          </Row>
          </Col>
          
           )}
          

        </Row>
        )}
        <Row justify="space-between">
          <Col>
          {/* { description && description.description && (
            <Row>
              <img src={Sqft} />
              {description.description.map((d,i) => 
              <Typography className="semi_furnished">{d.value}</Typography>
              )}
            </Row>
            )} */}
          </Col>
          <Col>
          {project_type === "Residential" ?
            <Row>
              <img src={Allot} alt= "" />
              <Typography className="semi_furnished"> {parking}</Typography>
            </Row>
            : null }
          </Col>
        </Row> 
      </Card>
    </div>
  );
};

export default ProjectCard;
