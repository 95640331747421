import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Select,
  Row,
  Divider,
  message,
  Spin,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateProject } from "../Actions/projectsAction";
import no_image from "../assets/images/no_image.png";
import "./styles/clients.css";
const schema = yup.object().shape({});
const { Text } = Typography;
export const UpdateCommercialProjects = ({ id, setPopoverVisible }) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { projects, isUpdateProjectLoading } = useSelector(
    (state) => state.projects
  );
  const the_project = projects.filter((project) => project.id === id);
  const image =
    the_project[0].images && the_project[0].images.length > 0
      ? the_project[0].images[0].image_url
      : null;
  const [files, setFiles] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  const [name, setName] = useState("");
  const oldDescription = the_project[0].description.description;
  const [description = oldDescription, setDes] = useState();

  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_project[0],
  });
  const handleUpdateProject = (data, event) => {
    event.preventDefault();
    data.id = id;
    data.project_type = "Commercial";
    data.image_url = files.length > 0 ? files[0] : null;
    data.description = { description };
    dispatch(
      updateProject(
        data,
        "Commercial",
        successUpdateCommercialProject,
        failureUpdateCommercialProject
      )
    );

    // setOpen(false);
    // reset(data);
  };
  const successUpdateCommercialProject = () => {
    setOpen(false);
    reset();
    setTimeout(() => {
      message.success("You have successfully update commercial project.");
    }, 2000);
  };
  const failureUpdateCommercialProject = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleImageChange = (event) => {
    const { value, files } = event.target;
    setName(value);
    setFiles(files);
  };
  const handleAdd = () => {
    const newDescription = description.concat([
      { key: "", value: "", id: Math.random() },
    ]);
    setDes(newDescription);
  };
  const handleRemove = (id) => {
    const newDescription = description.filter((x) => x.id !== id);
    setDes(newDescription);
  };
  const handleRemoveImage = () => {
    setRemoveImage(true);
  };
  const handleOnChange = (e, id) => {
    const { name, value } = e.target;
    // const newDescription = description
    const selectedObject = description.find((item) => item.id === id);

    selectedObject[name] = value;
    // the_project[0].description.description = newDescription;
    setDes([...description]);
  };
  const handleOpen = (data) => {
    setPopoverVisible(false);
    setOpen(true);
    reset(data);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpen()}
      >
        Edit{" "}
      </Button>
      <Modal
        title="Edit Project details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateProject)}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div>
                {image && image.url && !removeImage && files.length === 0 ? (
                  <img className="pCardImage" alt="" src={image.url} />
                ) : files.length > 0 ? (
                  <img
                    className="pCardImage"
                    alt=""
                    src={URL.createObjectURL(files[0])}
                  />
                ) : (
                  <img className="pCardImage" alt="" src={no_image} />
                )}
              </div>
              <Button type="link" onClick={() => handleRemoveImage()}>
                Remove
              </Button>
              {removeImage && (
                <Button type="link" onClick={() => setRemoveImage(false)}>
                  Cancel
                </Button>
              )}
              <input
                name="image_one"
                value={name}
                onChange={(e) => handleImageChange(e)}
                type="file"
              ></input>

              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Project Name.</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="property_name"
                    control={control}
                    placeholder="Property Name."
                  />
                  {errors.property_name && (
                    <span className="error_style">
                      Property Name is required
                    </span>
                  )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Builder</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="builder"
                    control={control}
                    placeholder="Builder"
                  />
                  {errors.builder && (
                    <span className="error_style">Builder is required</span>
                  )}
                </Col>
              </Row>

              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>No of Floor</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="no_of_floor"
                    control={control}
                    placeholder="No of Floor"
                  />
                  {errors.no_of_floor && (
                    <span className="error_style">No of Floor is required</span>
                  )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Area</Text>
                  </Row>
                  <Controller
                    as={Select}
                    control={control}
                    options={
                      areas &&
                      areas.length > 0 &&
                      areas.map((val, index) => {
                        return { label: val.name, value: val.id };
                      })
                    }
                    style={{ width: "100%" }}
                    name="area_id"
                  />
                  {errors.area_id && (
                    <span className="error_style">Area is required</span>
                  )}
                </Col>
              </Row>
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Tag Line</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="tag_line"
                    control={control}
                    placeholder="Tag Lin"
                  />
                  {errors.tag_line && (
                    <span className="error_style">Tag Lin is required</span>
                  )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Possesion</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="possesion"
                    control={control}
                    placeholder="Possesion"
                  />
                  {errors.possesion && (
                    <span className="error_style">possesion is required</span>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row>
                <Button
                  onClick={() => handleAdd()}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </Row>

              {description.map((des, index) => {
                return (
                  <Row key={index} gutter={[8, 16]}>
                    <Col span={24}></Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row>
                        <Text>Key</Text>
                      </Row>
                      <Input
                        key={des.id}
                        name={`key`}
                        value={des.key}
                        onChange={(e) => handleOnChange(e, des.id)}
                        placeholder="e.g. 3BHK, 4BHK Pent house"
                      />
                      {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row>
                        <Text>value</Text>
                      </Row>
                      <Input
                        key={des.id}
                        name={`value`}
                        value={des.value}
                        onChange={(e) => handleOnChange(e, des.id)}
                        placeholder="e.g. 2500.Sqft"
                      />
                      {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                    </Col>
                    {index !== 0 && (
                      <Button type="link" onClick={() => handleRemove(des.id)}>
                        Remove
                      </Button>
                    )}
                  </Row>
                );
              })}

              <Row justify="end" gutter={[0, 16]}>
                <Col span={24}></Col>
                <Col>
                  {isUpdateProjectLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 24,
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
