/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Col,
  Row,
  Calendar,
  Badge,
  List,
  Divider,
  Typography,
  Popover,
  Spin,
  Button,
  Input,
  Modal,
  Space,
  notification,
  Popconfirm,
} from "antd";
import "../App.css";
import * as yup from "yup";
import { getAllPreTasks, getAllPostTasks } from "../Actions/tasksAction";
import { getBirthdate } from "../Actions/birthdateAction";
import { UpdatTask } from "../pages/updateTask";
import {
  createNotification,
  getAllNotification,
  deleteNotification,
} from "../Actions/notificationAction";
import MyTable from "../components/table";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";

const { Title } = Typography;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isGetPreTasksLoading, isGetPostTasksLoading, preTask, postTask } =
    useSelector((state) => state.tasks);
  const { users } = useSelector((state) => state.users);
  const { birthdate, isGetBirthdateLoading } = useSelector(
    (state) => state.birthdate
  );
  const {
    isGetNotificationsLoading,
    isCreateNotificationLoading,
    isDeleteNotificationLoading,
    notifications,
  } = useSelector((state) => state.notifications);
  const { client_birthday, renewal_property } = birthdate || {};
  const [createDialog, setCreateDialog] = useState(false);
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState();
  const getToken = async () => {
    try {
      const result = await getIdTokenClaims();
      setToken(result.__raw);
    } catch (err) {
      alert(err.message);
    }
  };
  useEffect(() => {
    getToken();
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(getAllPreTasks("pre"));
      dispatch(getAllPostTasks("post"));
      dispatch(getAllNotification());
      dispatch(getBirthdate());
    }
  }, [token]);
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [CurrentPreTask, setCurrentPreTask] = useState({});
  const [CurrentPostTask, setCurrentPostTask] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const onRowSelect = (record) => {
    setCurrentPreTask(record);
    setCurrentPostTask(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const dateCellRender = (v) => {
    const date = v.date();
    const month = v.month() + 1;
    const monthStr =
      month.toString().length === 1 ? "0" + month.toString() : month;
    const matchingBirthday = client_birthday?.filter((b) => {
      if (date.toString() === b.date && monthStr === b.month) {
        return b;
      }
    });
    const renewalProperty = renewal_property?.filter((b) => {
      if (date.toString() === b.date && monthStr === b.renewal_month) {
        return b;
      }
    });
    if (matchingBirthday?.length > 0 || renewalProperty?.length > 0) {
      return (
        <div>
          {matchingBirthday?.map((mb, i) => (
            <Popover content={`${mb.name}'s birthday`} key={i}>
              <Badge
                count={matchingBirthday.count}
                status="processing"
                text={mb.name}
              ></Badge>
            </Popover>
          ))}
          {renewalProperty?.map((rp, i) => (
            <Popover
              content={`The renewal date is ${rp.renewal_date} of ${rp.client_name}'s property`}
              key={i}
            >
              <Badge
                count={renewalProperty.count}
                status="error"
                text={rp.client_name}
              >
                {" "}
              </Badge>
            </Popover>
          ))}
        </div>
      );
    }
    // birthdate.forEach(element => {
    //   if (element.date_of_birth) {
    //     const b = element.date_of_birth.slice(0, 2)
    //   //  console.log("Birth Val", b, date.toString())
    //     if (b === date.toString()){
    //       console.log("Birth Val", b)

    //       return (
    //         <div style={{width:'100%'}}>
    //         <li key={element.id}>
    //       <Badge  text={element.name} />
    //     </li>
    //     </div>
    //       )
    //     }
    //   }

    // });
  };
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const Taskcolumns = (users, type) => [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("user_name"),
      fixed: "left",
      render: (record, object) => {
        return (
          <Button
            //  onClick={() => handleViewLead(record)}
            type="link"
          >
            {record}
          </Button>
        );
      },
    },
    {
      title: "Due Date",
      // width: 250,
      dataIndex: "due_date",
      key: "due_date",
      sorter: (a, b) => a.due_date - b.due_date,
    },
    {
      title: "Note",
      // width: 250,
      dataIndex: "note",
      key: "note",
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "id",
      // width: 250,
      dataIndex: "id",
      key: "1",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <TaskActionComponent id={id} type={type} />,
    },
  ];
  const handlePanelChange = () => {
    dispatch(getBirthdate());
  };

  return (
    <>
      <Row justify="space-around">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Divider orientation="left">
            Notifications
            <Button
              type="primary"
              onClick={() => setCreateDialog(true)}
              style={{ marginLeft: 20, fontWeight: 500, color: "#FFFFF" }}
            >
              Create
            </Button>
          </Divider>
          <Spin
            spinning={
              isGetNotificationsLoading ||
              isCreateNotificationLoading ||
              isDeleteNotificationLoading
            }
          >
            <List
              bordered
              dataSource={notifications}
              renderItem={(item) => (
                <List.Item id={item.id}>
                  <Typography.Text>{item.text}</Typography.Text>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => dispatch(deleteNotification(item.id))}
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </List.Item>
              )}
            />
          </Spin>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Calendar
            fullscreen={false}
            dateCellRender={dateCellRender}
            onPanelChange={() => handlePanelChange()}
          />
        </Col>
      </Row>

      <Row gutter={[8, 0]}>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Row className="rowWithPadding" justify="space-between">
            <Col>
              <Title level={4}>
                Pre Tasks({preTask.length})
                {/* for {currentProperty.client.name} */}
              </Title>
            </Col>
          </Row>
          <Spin spinning={isGetPreTasksLoading}>
            <MyTable
              columns={Taskcolumns(users, "pre")}
              data={preTask}
              onRowSelect={(record) => onRowSelect(record)}
            />
          </Spin>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Row className="rowWithPadding" justify="space-between">
            <Col>
              <Title level={4}>
                Post Tasks({postTask.length})
                {/* for {currentProperty.client.name}'s requirements */}
              </Title>
            </Col>
          </Row>
          <Spin spinning={isGetPostTasksLoading}>
            <MyTable
              columns={Taskcolumns(users, "post")}
              data={postTask}
              onRowSelect={(postTask) => onRowSelect(postTask)}
            />
          </Spin>
        </Col>
      </Row>
      {createDialog && (
        <CreateNotification
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}

          // handleDialog={() => setCreateDialog(true)}
        />
      )}
    </>
  );
};

const TaskActionComponent = ({ id, type }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <UpdatTask id={id} type={type} setPopoverVisible={setPopoverVisible} />
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};

export default Dashboard;

const schema = yup.object().shape({
  text: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;

const CreateNotification = (props) => {
  const dispatch = useDispatch();
  const { createDialog, setCreateDialog } = props;
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreateNotification = (data, event) => {
    event.preventDefault();
    data.published = true;
    dispatch(createNotification(data));
    setCreateDialog(false);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    reset();
  };
  return (
    <>
      {/* <Spin
      spinning={
        isCreateNotificationLoading
      }
    ></Spin> */}

      <Modal
        title="Create Notification"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
        centered
      >
        {/* <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}> */}
        <form onSubmit={handleSubmit(handleCreateNotification)}>
          <Col>
            <Col>
              <Text>Note</Text>
              <Controller
                as={TextArea}
                name="text"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.text && (
                <span className="error_style">Note is required</span>
              )}
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ top: 10 }}
              >
                Create Notification
              </Button>
            </Col>
          </Col>
        </form>
        {/* </Col>
        </Row> */}
      </Modal>
    </>
  );
};
