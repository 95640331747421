// eslint-disable-next-line
export default {
  /***************************** USER CONSTANTS STARTS *********************************** */
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  GET_AUTH0_USERS_REQUEST: "GET_AUTH0_USERS_REQUEST",
  GET_AUTH0_USERS_SUCCESS: "GET_AUTH0_USERS_SUCCESS",
  GET_AUTH0_USERS_FAILURE: "GET_AUTH0_USERS_FAILURE",

  GET_AUTH0_USERS_RECONSILE_REQUEST: "GET_AUTH0_USERS_RECONSILE_REQUEST",
  GET_AUTH0_USERS_RECONSILE_SUCCESS: "GET_AUTH0_USERS_RECONSILE_SUCCESS",
  GET_AUTH0_USERS_RECONSILE_FAILURE: "GET_AUTH0_USERS_RECONSILE_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  /***************************** USER CONSTANTS ENDED *********************************** */

  /***************************** CLIENT CONSTANTS STARTS *********************************** */
  GET_CLIENTS_REQUEST: "GET_CLIENTS_REQUEST",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE: "GET_CLIENTS_FAILURE",

  CREATE_CLIENT_REQUEST: "CREATE_CLIENT_REQUEST",
  CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAILURE: "CREATE_CLIENT_FAILURE",

  UPDATE_CLIENT_REQUEST: "UPDATE_CLIENT_REQUEST",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE: "UPDATE_CLIENT_FAILURE",

  DELETE_CLIENT_REQUEST: "DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE: "DELETE_CLIENT_FAILURE",
  /***************************** CLIENT CONSTANTS ENDED *********************************** */

  /***************************** CLIENT BIRTHDATE CONSTANTS STARTS *********************************** */
  GET_CLIENTS_BIRTHDATE_REQUEST: "GET_CLIENTS_BIRTHDATE_REQUEST",
  GET_CLIENTS_BIRTHDATE_SUCCESS: "GET_CLIENTS_BIRTHDATE_SUCCESS",
  GET_CLIENTS_BIRTHDATE_FAILURE: "GET_CLIENTS_BIRTHDATE_FAILURE",

  /***************************** CLIENT BIRTHDATE CONSTANTS ENDED *********************************** */

  /***************************** PROPERTY CONSTANTS STARTS *********************************** */
  GET_PROPERTIES_REQUEST: "GET_PROPERTIES_REQUEST",
  GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
  GET_PROPERTIES_FAILURE: "GET_PROPERTIES_FAILURE",

  GET_PROPERTY_BY_ID_REQUEST: "GET_PROPERTY_BY_ID_REQUEST",
  GET_PROPERTY_BY_ID_SUCCESS: "GET_PROPERTY_BY_ID_SUCCESS",
  GET_PROPERTY_BY_ID_FAILURE: "GET_PROPERTY_BY_ID_FAILURE",

  GET_SEARCH_PROPERTIES_REQUEST: "GET_SEARCH_PROPERTIES_REQUEST",
  GET_SEARCH_PROPERTIES_SUCCESS: "GET_SEARCH_PROPERTIES_SUCCESS",
  GET_SEARCH_PROPERTIES_FAILURE: "GET_SEARCH_PROPERTIES_FAILURE",

  CREATE_PROPERTY_REQUEST: "CREATE_PROPERTY_REQUEST",
  CREATE_PROPERTY_SUCCESS: "CREATE_PROPERTY_SUCCESS",
  CREATE_PROPERTY_FAILURE: "CREATE_PROPERTY_FAILURE",

  PUBLISHED_PROPERTY_REQUEST: "PUBLISHED_PROPERTY_REQUEST",
  PUBLISHED_PROPERTY_SUCCESS: "PUBLISHED_PROPERTY_SUCCESS",
  PUBLISHED_PROPERTY_FAILURE: "PUBLISHED_PROPERTY_FAILURE",

  UPDATE_PROPERTY_REQUEST: "UPDATE_PROPERTY_REQUEST",
  UPDATE_PROPERTY_SUCCESS: "UPDATE_PROPERTY_SUCCESS",
  UPDATE_PROPERTY_FAILURE: "UPDATE_PROPERTY_FAILURE",

  DELETE_PROPERTY_REQUEST: "DELETE_PROPERTY_REQUEST",
  DELETE_PROPERTY_SUCCESS: "DELETE_PROPERTY_SUCCESS",
  DELETE_PROPERTY_FAILURE: "DELETE_PROPERTY_FAILURE",
  /***************************** PROPERTY CONSTANTS ENDED *********************************** */

  /***************************** Post PROPERTY CONSTANTS STARTS *********************************** */
  GET_POST_PROPERTIES_REQUEST: "GET_POST_PROPERTIES_REQUEST",
  GET_POST_PROPERTIES_SUCCESS: "GET_POST_PROPERTIES_SUCCESS",
  GET_POST_PROPERTIES_FAILURE: "GET_POST_PROPERTIES_FAILURE",

  GET_SEARCH_POST_PROPERTIES_REQUEST: "GET_SEARCH_POST_PROPERTIES_REQUEST",
  GET_SEARCH_POST_PROPERTIES_SUCCESS: "GET_SEARCH_POST_PROPERTIES_SUCCESS",
  GET_SEARCH_POST_PROPERTIES_FAILURE: "GET_SEARCH_POST_PROPERTIES_FAILURE",

  CREATE_POST_PROPERTY_REQUEST: "CREATE_POST_PROPERTY_REQUEST",
  CREATE_POST_PROPERTY_SUCCESS: "CREATE_POST_PROPERTY_SUCCESS",
  CREATE_POST_PROPERTY_FAILURE: "CREATE_POST_PROPERTY_FAILURE",

  UPDATE_POST_PROPERTY_REQUEST: "UPDATE_POST_PROPERTY_REQUEST",
  UPDATE_POST_PROPERTY_SUCCESS: "UPDATE_POST_PROPERTY_SUCCESS",
  UPDATE_POST_PROPERTY_FAILURE: "UPDATE_POST_PROPERTY_FAILURE",
  /***************************** Post PROPERTY CONSTANTS ENDED *********************************** */

  /***************************** LEAD CONSTANTS STARTS *********************************** */
  GET_LEADS_REQUEST: "GET_LEADS_REQUEST",
  GET_LEADS_SUCCESS: "GET_LEADS_SUCCESS",
  GET_LEADS_FAILURE: "GET_LEADS_FAILURE",

  GET_LEAD_TYPES_REQUEST: "GET_LEAD_TYPES_REQUEST",
  GET_LEAD_TYPES_SUCCESS: "GET_LEAD_TYPES_SUCCESS",
  GET_LEAD_TYPES_FAILURE: "GET_LEAD_TYPES_FAILURE",

  GET_LEAD_BY_ID_REQUEST: "GET_LEAD_BY_ID_REQUEST",
  GET_LEAD_BY_ID_SUCCESS: "GET_LEAD_BY_ID_SUCCESS",
  GET_LEAD_BY_ID_FAILURE: "GET_LEAD_BY_ID_FAILURE",

  CREATE_LEAD_REQUEST: "CREATE_LEAD_REQUEST",
  CREATE_LEAD_SUCCESS: "CREATE_LEAD_SUCCESS",
  CREATE_LEAD_FAILURE: "CREATE_LEAD_FAILURE",

  UPDATE_LEAD_REQUEST: "UPDATE_LEAD_REQUEST",
  UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
  UPDATE_LEAD_FAILURE: "UPDATE_LEAD_FAILURE",

  DELETE_LEAD_REQUEST: "DELETE_LEAD_REQUEST",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
  DELETE_LEAD_FAILURE: "DELETE_LEAD_FAILURE",
  /***************************** LEAD CONSTANTS ENDED *********************************** */

  /***************************** AREA CONSTANTS STARTS *********************************** */
  GET_AREAS_REQUEST: "GET_AREAS_REQUEST",
  GET_AREAS_SUCCESS: "GET_AREAS_SUCCESS",
  GET_AREAS_FAILURE: "GET_AREAS_FAILURE",

  GET_AREA_BY_ID_REQUEST: "GET_AREA_BY_ID_REQUEST",
  GET_AREA_BY_ID_SUCCESS: "GET_AREA_BY_ID_SUCCESS",
  GET_AREA_BY_ID_FAILURE: "GET_AREA_BY_ID_FAILURE",

  CREATE_AREA_REQUEST: "CREATE_AREA_REQUEST",
  CREATE_AREA_SUCCESS: "CREATE_AREA_SUCCESS",
  CREATE_AREA_FAILURE: "CREATE_AREA_FAILURE",

  UPDATE_AREA_REQUEST: "UPDATE_AREA_REQUEST",
  UPDATE_AREA_SUCCESS: "UPDATE_AREA_SUCCESS",
  UPDATE_AREA_FAILURE: "UPDATE_AREA_FAILURE",

  DELETE_AREA_REQUEST: "DELETE_AREA_REQUEST",
  DELETE_AREA_SUCCESS: "DELETE_AREA_SUCCESS",
  DELETE_AREA_FAILURE: "DELETE_AREA_FAILURE",
  /***************************** AREA CONSTANTS ENDED *********************************** */

  /***************************** TASK CONSTANTS STARTS *********************************** */
  GET_PRE_TASKS_REQUEST: "GET_PRE_TASKS_REQUEST",
  GET_PRE_TASKS_SUCCESS: "GET_PRE_TASKS_SUCCESS",
  GET_PRE_TASKS_FAILURE: "GET_PRE_TASKS_FAILURE",

  GET_POST_TASKS_REQUEST: "GET_POST_TASKS_REQUEST",
  GET_POST_TASKS_SUCCESS: "GET_POST_TASKS_SUCCESS",
  GET_POST_TASKS_FAILURE: "GET_POST_TASKS_FAILURE",

  GET_TASKS_REQUEST: "GET_TASKS_REQUEST",
  GET_TASKS_SUCCESS: "GET_TASKS_SUCCESS",
  GET_TASKS_FAILURE: "GET_TASKS_FAILURE",

  GET_TASK_BY_ID_REQUEST: "GET_TASK_BY_ID_REQUEST",
  GET_TASK_BY_ID_SUCCESS: "GET_TASK_BY_ID_SUCCESS",
  GET_TASK_BY_ID_FAILURE: "GET_TASK_BY_ID_FAILURE",

  CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",

  UPDATE_TASK_REQUEST: "UPDATE_TASK_REQUEST",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",

  DELETE_TASK_REQUEST: "DELETE_TASK_REQUEST",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",
  /***************************** TASK CONSTANTS ENDED *********************************** */

  /***************************** NOTIFICATION CONSTANTS STARTS *********************************** */
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  GET_NOTIFICATION_BY_ID_REQUEST: "GET_NOTIFICATION_BY_ID_REQUEST",
  GET_NOTIFICATION_BY_ID_SUCCESS: "GET_NOTIFICATION_BY_ID_SUCCESS",
  GET_NOTIFICATION_BY_ID_FAILURE: "GET_NOTIFICATION_BY_ID_FAILURE",

  CREATE_NOTIFICATION_REQUEST: "CREATE_NOTIFICATION_REQUEST",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",

  UPDATE_NOTIFICATION_REQUEST: "UPDATE_NOTIFICATION_REQUEST",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",

  DELETE_NOTIFICATION_REQUEST: "DELETE_NOTIFICATION_REQUEST",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",
  /***************************** NOTIFICATION CONSTANTS ENDED *********************************** */

  /***************************** INTERESTED CONSTANTS STARTS *********************************** */
  GET_INTERESTEDS_REQUEST: "GET_INTERESTEDS_REQUEST",
  GET_INTERESTEDS_SUCCESS: "GET_INTERESTEDS_SUCCESS",
  GET_INTERESTEDS_FAILURE: "GET_INTERESTEDS_FAILURE",

  GET_INTERESTED_BY_ID_REQUEST: "GET_INTERESTED_BY_ID_REQUEST",
  GET_INTERESTED_BY_ID_SUCCESS: "GET_INTERESTED_BY_ID_SUCCESS",
  GET_INTERESTED_BY_ID_FAILURE: "GET_INTERESTED_BY_ID_FAILURE",

  CREATE_INTERESTED_REQUEST: "CREATE_INTERESTED_REQUEST",
  CREATE_INTERESTED_SUCCESS: "CREATE_INTERESTED_SUCCESS",
  CREATE_INTERESTED_FAILURE: "CREATE_INTERESTED_FAILURE",

  UPDATE_INTERESTED_REQUEST: "UPDATE_INTERESTED_REQUEST",
  UPDATE_INTERESTED_SUCCESS: "UPDATE_INTERESTED_SUCCESS",
  UPDATE_INTERESTED_FAILURE: "UPDATE_INTERESTED_FAILURE",

  DELETE_INTERESTED_REQUEST: "DELETE_INTERESTED_REQUEST",
  DELETE_INTERESTED_SUCCESS: "DELETE_INTERESTED_SUCCESS",
  DELETE_INTERESTED_FAILURE: "DELETE_INTERESTED_FAILURE",
  /***************************** INTERESTED CONSTANTS ENDED *********************************** */

  /***************************** PROJECT CONSTANTS STARTS *********************************** */
  GET_PROJECTS_REQUEST: "GET_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE: "GET_PROJECTS_FAILURE",

  GET_PROJECT_BY_ID_REQUEST: "GET_PROJECT_BY_ID_REQUEST",
  GET_PROJECT_BY_ID_SUCCESS: "GET_PROJECT_BY_ID_SUCCESS",
  GET_PROJECT_BY_ID_FAILURE: "GET_PROJECT_BY_ID_FAILURE",

  CREATE_PROJECT_REQUEST: "CREATE_PROJECT_REQUEST",
  CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE: "CREATE_PROJECT_FAILURE",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

  PUBLISHED_PROJECT_REQUEST: "PUBLISHED_PROJECT_REQUEST",
  PUBLISHED_PROJECT_SUCCESS: "PUBLISHED_PROJECT_SUCCESS",
  PUBLISHED_PROJECT_FAILURE: "PUBLISHED_PROJECT_FAILURE",

  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",
  /***************************** PROJECT CONSTANTS ENDED *********************************** */

  GET_ACTIVITIES_REQUEST: "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE: "GET_ACTIVITIES_FAILURE",
};
