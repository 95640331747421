import constants from '../Utils/constants';
const initialState = {
    isGetBirthdateLoading: false,
    birthdate: []
}

const birthdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_CLIENTS_BIRTHDATE_REQUEST:
            return {
                ...state, isGetBirthdateLoading: true
            }
        case constants.GET_CLIENTS_BIRTHDATE_SUCCESS:
            return {
                ...state, isGetBirthdateLoading: false, birthdate: action.payload
            }
        case constants.GET_CLIENTS_BIRTHDATE_FAILURE:
            return {
                ...state, isGetBirthdateLoading: false
            }
        
        default:
            return state
    }
}
export default birthdateReducer;