import constants from '../Utils/constants';
const initialState = {
    isGetNotificationsLoading: false, isCreateNotificationLoading: false, isUpdateNotificationLoading: false, isDeleteNotificationLoading: false,
    notifications: [],
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state, isGetNotificationsLoading: true
            }
        case constants.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state, isGetNotificationsLoading: false, notifications: action.payload
            }
        case constants.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state, isGetNotificationsLoading: false
            }

        case constants.GET_NOTIFICATION_BY_ID_REQUEST:
            return {
                ...state, isGetNotificationByIdLoading: true
            }
        case constants.GET_NOTIFICATION_BY_ID_SUCCESS:
            return {
                ...state, isGetNotificationByIdLoading: false
            }
        case constants.GET_NOTIFICATION_BY_ID_FAILURE:
            return {
                ...state, isGetNotificationByIdLoading: false
            }

        case constants.CREATE_NOTIFICATION_REQUEST:
            return {
                ...state, isCreateNotificationLoading: true
            }
        case constants.CREATE_NOTIFICATION_SUCCESS:
            return {
                ...state, isCreateNotificationLoading: false
            }
        case constants.CREATE_NOTIFICATION_FAILURE:
            return {
                ...state, isCreateNotificationLoading: false
            }

        case constants.UPDATE_NOTIFICATION_REQUEST:
            return {
                ...state, isUpdateNotificationLoading: true
            }
        case constants.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state, isUpdateNotificationLoading: false
            }
        case constants.UPDATE_NOTIFICATION_FAILURE:
            return {
                ...state, isUpdateNotificationLoading: false
            }

        case constants.DELETE_NOTIFICATION_REQUEST:
            return {
                ...state, isDeleteNotificationLoading: true
            }
        case constants.DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state, isDeleteNotificationLoading: false
            }
        case constants.DELETE_NOTIFICATION_FAILURE:
            return {
                ...state, isDeleteNotificationLoading: false
            }
        default:
            return state
    }
}
export default notificationReducer;