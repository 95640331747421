import axios from "axios";

const fetchClient = () => {
  // const baseUrl = process.NODE_ENV === "development" ? "https://dev-vastu-api.herokuapp.com/api" : "https://prod-vastu-api.herokuapp.com//api"
  // https://dev-vastu-api.herokuapp.com/api
  // process.env.REACT_APP_API_URL
  // console.log("REACT", process.env.REACT_APP_API_URL)

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.vastu-web-backend.v1",
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("access_token");
    config.headers.Authorization = token;
    return config;
  });
  return instance;
};
export default fetchClient();
