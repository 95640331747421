import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Modal,
} from "antd";
import "./styles/clients.css";
// import AllResidentialProperties from "./RentResidentialProperties";
const { Text, Title } = Typography;
export const ViewClient = ({ id, setPopoverVisible }) => {
// export const ViewClient = ({ id, open, handleViewClientCancel }) => {
  const { clients } = useSelector((state) => state.clients);
  const the_client = clients.filter((client) => client.id === id);
  // const [open, setOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const defaultValues = the_client[0];
  const {
    name,
    mobile_number,
    office_number,
    home_number,
    email,
    client_type,
    property_type,
    address,
    area,
    landmark,
    city,
    business,
    designation,
    company,
    date_of_birth,
    date_of_anniversary,
    gender,
    note,
    reference,
    created_at,
  // } = defaultValues || {};
} = defaultValues;

const handleOpenViewClient = () => {
  setPopoverVisible(false)
  setOpen(true)
}
  return (
    <>
      {/* <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        ViewClient{" "}
      </Button> */}
 <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenViewClient()}
      >
        ViewClient{" "}
      </Button>
      <Modal
        title="View Client Details"
        visible={open}
        // onCancel={() => handleViewClientCancel()}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <Row justify='space-between'>
        <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {name}
                </Text>
                <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Created At:{" "}
              <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                {created_at}
              </Text>
              </Title>
            </Col>
          </Row>
            <Row justify='space-around' gutter={[8,0]}>
              <Col>
                <Title level={5}>Reference:</Title>
                </Col>
                <Col>
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {reference}
                </Text>
                </Col>
            </Row>
        </Row>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Contact Details</Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
              <Row gutter={[48,0]}>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Mobile Number: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {mobile_number}
                  </Text>
                  </Title>
                </Col>

                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Mobile 2: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {home_number}
                  </Text>
                  </Title>
                </Col>
                
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Office Number: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {office_number}
                  </Text>
                  </Title>
                </Col>
          
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Email-id: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {email}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Client Type: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {client_type}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Property Type: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {property_type}
                  </Text>
                  </Title>
                </Col>
          </Row>
        </Card>
        
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Location Details</Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
              <Row gutter={[48,0]}>
                <Col>
                  <Title level={5}>Address: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {address}
                  </Text>
                  </Title>
                </Col>
          </Row>

              <Row gutter={[48,0]}>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Area: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {area}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Landmark: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {landmark}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>City: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {city}
                  </Text>
                  </Title>
                </Col>
          </Row>
        </Card>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Profession Details</Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
              <Row gutter={[48,0]}>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Bussiness: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {business}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Designation:{" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {designation}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Company: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {company}
                  </Text>
                  </Title>
                </Col>
              </Row>
        </Card>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Personal Details</Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
              <Row gutter={[48,0]}>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>BirthDate: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {date_of_birth}
                  </Text>
                  </Title>
                </Col>

                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Marriage anniversary: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {date_of_anniversary}
                  </Text>
                  </Title>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Title level={5}>Gender: {" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {gender}
                  </Text>
                  </Title>
                </Col>
              </Row>
        </Card>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Other Details</Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Title level={5}>Detail: {" "}
              <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                {note}
              </Text>
              </Title>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
};
