/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from 'react-highlight-words';
import { useSelector, useDispatch } from "react-redux";
import { Typography, Spin, Row, Col, Button, Input, Space} from "antd";
import { getAllAreas } from "../Actions/areasAction";
import { getClients } from "../Actions/clientActions";
import { getAllInBoundProperties } from "../Actions/propertiesActions";
import { updatePostPropery } from "../Actions/postPropertyAction";
import MyTable from "../components/table";
import { SearchOutlined } from "@ant-design/icons";
import "./styles/clients.css"; 
const { Title } = Typography;

const InBoundProperties = () => {
    const dispatch = useDispatch();
    const {
      properties,
      isGetPropertiesLoading,
    } = useSelector((state) => {
      const {
        properties,
        isGetPropertiesLoading,
      } = state.properties;
      const { clients } = state.clients;
      const { areas } = state.areas;
      return {
        clients,
        areas,
        properties,
        isGetPropertiesLoading,
      };
    });
    useEffect(() => {
      dispatch(getAllInBoundProperties());
    }, []);
    useEffect(() => {
        dispatch(getClients());
      }, []);
      useEffect(() => {
        dispatch(getAllAreas());
      }, []);
      return (
        <Spin
        className='create_client_field'
          spinning={
            isGetPropertiesLoading 
          }
        >
            {properties && <AllInBoundProperties data={properties} />}
    </Spin>
  );
};
export default InBoundProperties;

const AllInBoundProperties = (props) => {
    const { areas } = useSelector((state) => state.areas);
    const { clients } = useSelector((state) => state.clients);
    const [CurrentInboundProperty, setCurrentInboundProperty] = useState({});
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] =useState('')
    const onRowSelect = (record) => {
      setCurrentInboundProperty(record);
    };
    let searchInput = useRef('')
  const getColumnSearchProps = (dataIndex ) => { 
    return ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
         <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
         <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
  
     filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />, 
       onFilter: (value, record) => 
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
      
       onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: text => 
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
      
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
   const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };
  const columns = (areas, clients) =>  [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "1",
      width: '100%',
      fixed: "left",
      ...getColumnSearchProps("client_name"),
      render: (record, object) => {
        return <Button type="link">{record} </Button>;
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "2",
      width: '100%',
      fixed: "left",
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "6",
    //   width: '30%',
    //   filters: [
    //     {
    //       text: "address",
    //       value: "address",
    //     },
    //   ],
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    {
      title: "Property",
      dataIndex: "property_type",
      key: "3",
      width: '100%',
      ...getColumnSearchProps("property_type"),
    },
    // {
    //   title: "DealFor",
    //   dataIndex: "deal_for",
    //   key: "1",
    //   width: '15%',
    // },
    {
      title: "Super buildup area sqft",
      dataIndex: "property_area_attributes",
      key: "4",
      width: '50%',
      render: (record, object) => {
        return <>{record ? record.super_builtup_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Less %",
      dataIndex: "property_area_attributes",
      key: "5",
      width: '50%',
      render: (record, object) => {
        return <>{record ? record.less_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Carpet Area Sqft",
      dataIndex: "property_area_attributes",
      key: "6",
      width: '50%',
      render: (record, object) => {
        return <>{record ? record.carpet_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Plot in Yard",
      dataIndex: "property_area_attributes",
      key: "7",
      width: '50%',
      render: (record, object) => {
        return <>{record ? record.super_plot_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Construction in Yard",
      dataIndex: "property_area_attributes",
      key: "8",
      width: '50%',
      render: (record, object) => {
        return <>{record ? record.builtup_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Price per Sqft",
      dataIndex: "cost_one",
      key: "9",
      width: '50%',
      render: (cost_one) => {
        return <div>{`₹ ${cost_one}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
      },
      sorter: (a, b) => a.cost_one - b.cost_one,
    },
    {
      title: "Extra",
      dataIndex: "cost_three",
      key: "10",
      width: '50%',
      render: (cost_three) => {
        return <div>{`₹ ${cost_three}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
      },
      sorter: (a, b) => a.cost_three - b.cost_three,
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "11",
      width: '50%',
      render: (cost) => {
        return <div>{`₹ ${cost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Entry Date",
      dataIndex: "created_at",
      key: "12",
      width: '50%',
      sorter: (a, b) => a.created_at - b.created_at,
    },
    {
      title: "Last communication date",
      dataIndex: "last_communication_date",
      key: "13",
      width: '50%',
      sorter: (a, b) => a.last_communication_date - b.last_communication_date,
    },
    {
      title: "BHK",
      dataIndex: "bhk",
      key: "14",
      width: '50%',
      sorter: (a, b) => a.bhk - b.bhk,
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "15",
      width: '50%',
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Total Floor",
      dataIndex: "total_floor",
      key: "16",
      width: '50%',
      sorter: (a, b) => a.total_floor - b.total_floor,
    },
    {
      title: "Property No.",
      dataIndex: "unique_number",
      key: "17",
      width: '50%',
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "18",
      width: '100%',
    },
    {
      title: "id",
      width: 250,
      dataIndex: "id",
      key: "19",
      ...getColumnSearchProps('id'),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <ActionComponent id={id} />,
    },
];

    return (
      <>
      <Row className="rowWithPadding">
        <Col>
        <Title level={4} className="title_all_clients">
        Inbound Properties({props.data.length})
        </Title>
        </Col>
        </Row>
        <MyTable columns={columns(areas, clients)} data={props.data} onRowSelect={(record) => onRowSelect(record)} />
      </>
    );
  };
const ActionComponent = ({ id }) => {
  const dispatch = useDispatch();
  const {
    isUpdatePostPropertyLoading,
  } = useSelector((state) => {
  const { isUpdatePostPropertyLoading } = state.postProperties;
  return {
    isUpdatePostPropertyLoading,
  };
});
  const handleUpdateProperty = (data, event) => {
    id = id;
    dispatch(updatePostPropery({inbound_property: false, id: id}));
  };
  return (
    <>
    {isUpdatePostPropertyLoading ?
    <Spin
        className='create_client_field'
          spinning={
            isUpdatePostPropertyLoading 
          }
        >
    </Spin> :
      <Button type='link'
      onClick={() => handleUpdateProperty()}
      >Move to Property</Button>
        }
</>
  );
};
