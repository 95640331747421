// eslint-disable-next-line
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography, Button, Modal } from "antd";
import "./styles/clients.css";
const { Text, Title } = Typography;

export const ViewLead = ({ id, setPopoverVisible }) => {
  const { leads } = useSelector((state) => state.leads);
  const the_lead = leads.filter((lead) => lead.id === id);
  const [open, setOpen] = useState(false);
  the_lead[0].area_id = the_lead[0]?.area?.id;
  the_lead[0].client_id = the_lead[0]?.client?.id;
  const defaultValues = the_lead[0];
  const {
    client_name,
    max_budget,
    min_budget,
    area,
    areas_of_lead,
    is_lead,
    deal_for,
    home_loan,
    construction_after,
    vastu_complient,
    bhk,
    lead_type,
    types_of_lead,
    facing,
    details,
    is_residential,
    is_commercial,
    is_plot,
    lead_furniture_attributes,
    lead_amenity_attributes,
    lead_nearby_attributes,
    lead_area_attributes,
  } = defaultValues;
  const {
    fix_drawing_room,
    mod_drawing_room,
    fix_bedroom,
    mod_bedroom,
    fix_kitchen,
    mod_kitchen,
    air_condition,
  } = lead_furniture_attributes;
  const {
    water_24hr,
    png,
    security,
    cctv,
    lift,
    common_parking,
    paid_parking,
    garden,
    children_play_area,
    club_house,
    gymnasium,
    common_plot,
  } = lead_amenity_attributes;
  const {
    religious_place,
    park_garden,
    educational_institute,
    hospital_dispansary,
    mall_market,
    bus_stop,
  } = lead_nearby_attributes;
  const { minimum_sqFt, maximum_sqFt } = lead_area_attributes;
  const handleViewLead = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  const getLastElementofArray = (arr) => {
    const lengthOfArray = arr.length;
    const lastElement = arr[lengthOfArray - 1];
    console.log({ lastElement, lengthOfArray });
    return lastElement;
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleViewLead()}
      >
        ViewLead{" "}
      </Button>
      <Modal
        title="View Lead Details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <Row gutter={[8, 0]}>
          <Col>
            <Title level={5}>
              Client:{" "}
              <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                {client_name}
              </Text>
            </Title>
          </Col>
        </Row>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Budget:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {`₹ ${min_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} -{" "}
                  {`${max_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Area:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {areas_of_lead?.map(
                    (area) =>
                      area?.area_name +
                      `${
                        getLastElementofArray(areas_of_lead)?.area_name ===
                        area?.area_name
                          ? ""
                          : ", "
                      }`
                  )}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
        <Card>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Constuction After:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {construction_after}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Vastu Complient:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {vastu_complient}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Is Residential/Commercial/Plot?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {is_lead}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Is New/Resell/Rent/Lease?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {deal_for}
                </Text>
              </Title>
            </Col>

            <Col
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Home Load?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {home_loan}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card>
          <Row gutter={[48, 0]}>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                BHK:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {bhk}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Lead Type:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {types_of_lead?.types?.map(
                    (type) =>
                      type +
                      `${
                        getLastElementofArray(types_of_lead?.types) === type
                          ? ""
                          : ", "
                      }`
                  )}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Facing:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {facing}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Detail:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {details}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Furniture</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Row gutter={[48, 0]}>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Drawing Room?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_drawing_room === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Drawing Room?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_drawing_room === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Bedroom?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_bedroom === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Bedroom?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_bedroom === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Kitchen?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_kitchen === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Kitchen?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_kitchen === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Air Condition?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {air_condition === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Amenity</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
            backgroundColor: "#F3F3FA",
          }}
        >
          <Row gutter={[48, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Water 24hr?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {water_24hr === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Png?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {png === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Security?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {security === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Cctv?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {cctv === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Lift?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {lift === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Common Parking?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {common_parking === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Paid Parking?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {paid_parking === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Garden?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {garden === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Children Play Area?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {children_play_area === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Club House?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {club_house === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Gymnasium?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {gymnasium === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Common Plot?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {common_plot === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Nearby</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Row gutter={[48, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Religious Place?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {religious_place === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Park/Garden?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {park_garden === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Educational/Institute?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {educational_institute === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Hospital/Dispansary?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {hospital_dispansary === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Mall/market?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mall_market === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Bus Stop?{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {bus_stop === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Area SqFt.</Title>
          </Col>
        </Row>
        <Card
          style={{ backgroundColor: is_plot === true ? "#fffff" : "#F3F3FA" }}
        >
          <Row gutter={[48, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Minimum SqFt{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {minimum_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Maximum SqFt{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {maximum_sqFt}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
};
