/*eslint-disable*/
import React from "react";
import "../components/footer.css";
import {
	Row,
	Col,
	Layout,
	Typography, Image,
} from "antd";

import {
	InstagramFilled,
	YoutubeFilled,
} from "@ant-design/icons";

import Facebook from "../assets/images/facebook.svg";
import logo from "../assets/images/logo.svg";

const { Text, Link } = Typography;
const { Footer } = Layout;
const HomeFooter = () => {
	return (
		// <Footer className="footer_header">
		<div>
			<Row>
				<Col span={2}></Col>
				<Col style={{ top: -50 }} span={22}>
					<Image alt="logo" src={logo} preview={false} />
				</Col>
			</Row>

			<Row gutter={10}>
				<Col xl={2}></Col>
				<Col
					xl={{ span: 10 }}
					lg={{ span: 10 }}
					md={{ span: 22 }}
					xs={{ span: 22 }}
					sm={{ span: 22 }}
				>
					<Typography className="footer_text1">
						Shree Vastu Developers and Management
					</Typography>
					<Typography className="footer_text2">
						121/1, 4th Floor, Left Wing, Nalanda Complex, Near Mansi Circle, Vastrapur, Ahmedabad-380015
					</Typography>
					<Typography className="footer_text2">
						P :079 6662 0666
					</Typography>
					<Typography className="footer_text2">
						E :info@svmdproperty.com
					</Typography>
					<Typography className="footer_text2">
						We provide a complete service for the sale,
					</Typography>
					<Typography className="footer_text3">
						purchase or rental of real estate.
					</Typography>
					<Typography className="footer_text4">
						Copyrights @2021 Shree Vastu Developers and Management
					</Typography>
				</Col>
				<Col
					xl={{ span: 10 }}
					lg={{ span: 10 }}
					md={{ span: 22 }}
					xs={{ span: 22 }}
					sm={{ span: 22 }}
				>
					<Row justify="space-around">
						<Col
							xl={{ span: 5 }}
							lg={{ span: 8 }}
							md={{ span: 5 }}
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							style={{ paddingTop: 10 }}
						>
							<div className="footercontent">
								<Typography className="quick_links">Quick Links</Typography>
								<Typography>
									<Link href="#" className="quick_links_buy">
										Buy
									</Link>
								</Typography>
								<Typography>
									<Link href="#" className="quick_links_rent">
										Rent
									</Link>
								</Typography>
								<Typography>
									<Link href="#" className="quick_links_sales">
										Sales
									</Link>
								</Typography>
							</div>
						</Col>
						<Col
							xl={{ span: 5 }}
							lg={{ span: 8 }}
							md={{ span: 5 }}
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							style={{ paddingTop: 10 }}
						>
							<div className="footercontent">
								<Typography className="company">Company</Typography>
								<Typography>
									<Link href="#" className="about_us">
										About Us
									</Link>
								</Typography>
								<Typography>
									<Link href="#" className="contact_us">
										Contact Us
									</Link>
								</Typography>
								<Typography>
									<Link href="#" className="property_service">
										Property Service
									</Link>
								</Typography>
							</div>
						</Col>
						<Col
							xl={{ span: 4 }}
							lg={{ span: 8 }}
							md={{ span: 24 }}
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							style={{ paddingTop: 30 }}
						>
							<Row justify="space-around" className="social-links">
								<Col>
									<Link href="https://www.instagram.com/shree_vastu_management/">
										<InstagramFilled className="instagram_filled" />
									</Link>
								</Col>
								{/* <Col>
									<Link href="#">
										<YoutubeFilled className="youtube_filled" />
									</Link>
								</Col> */}
								<Col>
									<Link href="https://www.facebook.com/svmdproperty">
										<Image preview={false} src={Facebook} className="facebook_filled" />
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xl={2}></Col>
			</Row>
			{/* </Footer> */}
		</div>
	);
};
export default HomeFooter;
