/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";

import { Row, Col, Typography } from "antd";
import { getPostProperties } from "../Actions/postPropertyAction";
import "../components/properties.css";
import PropertyCard from "../components/PropertyCard";
import Text from "antd/lib/typography/Text";
const { Title } = Typography;
const Properties = () => {
	return (
		<>
			<div className="section-padding">
				<Row justify="start" style={{ paddingTop: 20 }}>
					<Col span={2}></Col>
					<Col>
						<Title level={1}>Properties</Title>
					</Col>
				</Row>
				<Row justify="start">
					<Col offset={2}>
						<Title level={5}>
							Total 6 properties matches with your requirements.
						</Title>
					</Col>
				</Row>
				<Row style={{ paddingTop: 50 }}>
					<Col span={2}></Col>
					<Col span={20}>
						<Row justify="start">
							<Col xxl={6} xl={6} lg={12} md={12}>
								<PropertyCard></PropertyCard>
							</Col>
							<Col xxl={6} xl={6} lg={12} md={12}>
								<PropertyCard></PropertyCard>
							</Col>
							<Col xxl={6} xl={6} lg={12} md={12}>
								<PropertyCard></PropertyCard>
							</Col>
							<Col xxl={6} xl={6} lg={12} md={12}>
								<PropertyCard></PropertyCard>
							</Col>
						</Row>
					</Col>


				</Row>
			</div>
		</>
	);
};
export default Properties;
