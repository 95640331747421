/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";

import { Row, Col, Typography, Card } from "antd";
import { getPostProperties } from "../Actions/postPropertyAction";
import "../components/propertyservices.css";
const { Title } = Typography;
const PropertyServices = (props) => {
	const { serviceRef } = props;

	return (
		<>
			<Row ref={serviceRef} style={{ padding: "100px 0px" }}>
				<Col xl={24}>
					<Row justify="start">
						<Col span={2}></Col>
						<Col xl={20} lg={20} md={20} sm={20} xs={{ span: 20 }}>
							<Title level={1}>Property Services</Title>
						</Col>
					</Row>
					<Row>
						<Col offset={2} xl={20} lg={20} md={20} sm={20}>
							<Title level={5}>
								One-stop destination for all your property-related needs
							</Title>
						</Col>
					</Row>
					<Row style={{ paddingTop: 20 }}>
						<Col span={2}></Col>
						<Col xl={20} lg={20} md={20} sm={20} xs={20}>
							<Row justify="space-around" gutter={[16, 24]}>
								<Col xl={8} lg={8} md={8}>
									<Card className="card">
										<p className="heading">Property Management</p>
										<p className="details">
											Choose from a wide range of tailor-made packages from our
											trusted service partners for a perfect at-home service
											experience.
										</p>
									</Card>
								</Col>
								<Col xl={8} lg={8} md={8}>
									<Card className="card">
										<p className="heading">NRI Property Management</p>
										<p className="details">
											Choose from a wide range of tailor-made packages from our
											trusted service partners for a perfect at-home service
											experience.
										</p>
									</Card>
								</Col>
								<Col xl={8} lg={8} md={8}>
									<Card className="card">
										<p className="heading">Rent Agreement and Contract</p>
										<p className="details">
											Choose from a wide range of tailor-made packages from our
											trusted service partners for a perfect at-home service
											experience.
										</p>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col span={2}></Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default PropertyServices;
