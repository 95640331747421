import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Checkbox,
  Select,
  InputNumber,
  Steps,
  Divider,
  Row,
  message,
  Spin,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updatePropery, getAllProperties } from "../Actions/propertiesActions";
import "./styles/clients.css";
import { LoadingOutlined } from "@ant-design/icons";
const PropertyType = [
  { value: 8, label: "Office" },
  { value: 9, label: "Shop" },
  { value: 10, label: "Showroom" },
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 17, label: "Redevelopment" },
];
const PropertyStatus = [
  { value: 0, label: "Available" },
  { value: 1, label: "Sold Out" },
  { value: 2, label: "On hold" },
  { value: 3, label: "Rented" },
];
const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Property Details",
    // content: 'Second-content',
  },
];

const schema = yup.object().shape({
  client_id: yup.string().required("Required"),
  area_id: yup.string().required("Required"),
  cost: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdateRentCommercialProperties = ({ id, setPopoverVisible }) => {
  const dispatch = useDispatch();
  const { properties, isUpdatePropertyLoading } = useSelector(
    (state) => state.properties
  );
  const { clients } = useSelector((state) => state.clients);
  const { areas } = useSelector((state) => state.areas);
  const the_property = properties.filter((property) => property.id === id);
  const newDate = the_property[0].created_at
    ? the_property[0].created_at.split("/")
    : {};
  const newDate1 = the_property[0].last_communication_date
    ? the_property[0].last_communication_date.split("/")
    : {};
  the_property[0].created_at =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  the_property[0].last_communication_date =
    newDate1.length > 2
      ? `${newDate1[2]}-${newDate1[1]}-${newDate1[0]}`
      : newDate1[0];
  const [open, setOpen] = useState(false);
  the_property[0].area_id = the_property[0].area.id;
  the_property[0].client_id = the_property[0].client.id;
  const { handleSubmit, errors, control, reset, getValues, trigger, watch } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: the_property[0],
      mode: "all",
    });
  const watching = watch(["is_furnished"]);
  const { is_furnished } = watching;
  const [createDialog, setCreateDialog] = useState(false);

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    const {
      client_id: cid,
      area_id: aid,
      property_type: PT,
      cost: cst,
    } = getValues(["client_id", "area_id", "property_type", "cost"]);
    if (current === 0 && (!cid || !aid || !PT)) {
      trigger(["client_id", "area_id", "property_type"]);
    } else if (current === 1 && !cst) {
      trigger(["cost"]);
    } else {
      // if (Object.keys(errors).length === 0){
      setCurrent(current + 1);
      // }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  // const handleUpdateProperty = (data, event) => {
  //   event.preventDefault();
  //   data.id = id;
  //   dispatch(updatePropery(data,"is_commercial", UpdateCallBack));
  // };
  // const UpdateCallBack = (data) => {
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset(data);
  //   setOpen(false);
  //   setTimeout(() => {
  //     message.success("Update RentCommercial property successful.");
  //   }, 2000);
  //   dispatch(getAllProperties());
  // };
  const handleUpdateProperty = (data, event) => {
    data.is_commercial = true;
    data.id = id;
    event.preventDefault();
    dispatch(
      updatePropery(
        data,
        "is_commercial",
        successUpdateRentCommercialProperty,
        failureUpdateRentCommercialProperty
        // IsEmpty
      )
    );
  };
  const successUpdateRentCommercialProperty = () => {
    setOpen(false);
    // reset();
    setTimeout(() => {
      message.success("You have successfully update rent commercial property.");
    }, 2000);
  };
  const failureUpdateRentCommercialProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setOpen(false);
    reset();
  };
  const handleCancel = () => {
    setOpen(false);
    setCurrent(0);
    reset();
  };
  const handleUCP = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleUCP()}
      >
        Edit
      </Button>
      {/* <EditTwoTone
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        /> */}
      <Modal
        title="Edit Rent Commercial Property details"
        visible={open}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Row>
          <Steps
            current={current}
            size="small"
            responsive="true"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <form onSubmit={handleSubmit(handleUpdateProperty)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Office/Shop/Godown No.</Text>
              </Row>
              <Controller
                as={Input}
                name="unique_number"
                control={control}
                placeholder="Office/Shop/Godown No."
              />
              {errors.unique_number && (
                <span className="error_style">
                  Office/Shop/Godown No is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={16}
              lg={16}
              md={16}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.unique_number && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Google Link</Text>
              </Row>
              <Controller
                as={Input}
                name="google_link"
                control={control}
                placeholder="City"
              />
              {errors.google_link && (
                <span className="error_style">City is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Status</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyStatus}
                name="property_status"
                style={{ width: "100%" }}
              />
              {errors.property_status && (
                <span className="error_style">Property Status is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super Builtup Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super Builtup Area SqFt"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Less %</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less %"
              />
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Builtup Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup Area SqFt"
              />
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Carpet Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet Area SqFt"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="floor"
                control={control}
                placeholder="Floor"
                type="number"
              />
              {errors.floor && (
                <span className="error_style">Floor is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="total_floor"
                control={control}
                placeholder="Total Floor"
                type="number"
              />
              {errors.total_floor && (
                <span className="error_style">Total Floor is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Cost</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {/* <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Price per Sqft</Text>
              </Row>
              <Controller
                name="cost_one"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost_one && (
                <span className="error_style">Price per Sqft is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Extra per Sqft </Text>
              </Row>
              <Controller
                name="cost_two"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost_two && (
                <span className="error_style">Extra per Sqft is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Cost </Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Total Cost  is required</span>
              )}
            </Col> */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>No of Deposite</Text>
              </Row>
              <Controller
                name="cost_two"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    // formatter={(value) =>
                    //   `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    // }
                    {...props}
                  />
                )}
              />
              {errors.cost_two && (
                <span className="error_style">No of Deposite is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maintenance</Text>
              </Row>
              <Controller
                name="cost_three"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost_three && (
                <span className="error_style">Maintenance is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Rent </Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <Input
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Total Rent is required</span>
              )}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction Year</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_year"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Visit Time</Text>
              </Row>
              <Controller
                as={Input}
                name="visit_time"
                control={control}
                placeholder="Visit Time"
                type="time"
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Agreement Date</Text>
              </Row>
              <Controller
                as={Input}
                name="agreement_date"
                type="date"
                format="DD-MM-YYYY"
                control={control}
                placeholder="Agreement Date"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Renewal Date</Text>
              </Row>
              <Controller
                as={Input}
                name="renewal_date"
                type="date"
                control={control}
                placeholder="Renewal Date"
              />
            </Col>

            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>About Key</Text>
              </Row>
              <Controller
                as={Input}
                name="about_key"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>New</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Resell</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Rent</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_rent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lease</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_lease"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="notes_box"
            >
              <Row>
                <Text>Details</Text>
              </Row>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Furniture</Divider>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Furnished</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_furnished"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Electronics</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[electronics]"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Pentry</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[pentry]"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Attachable Washroom</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[attachable_washroom]"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 1 && is_furnished === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Air Condition</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[air_condition]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Amenity</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water Supply</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_supply]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water 24hr</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_24hr]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Png</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[png]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Security</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[security]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>cctv</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[cctv]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            {/* </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col> */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lift</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[lift]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Parking</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Paid parking</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[paid_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Plot</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_plot]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Nearby</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Religious Place</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[religious_place]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Park Garden</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[park_garden]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Educational Institute</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[educational_institute]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Hospital/Dispansary</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[hospital_dispansary]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Mall/market</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[mall_market]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Bus Stop</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[bus_stop]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="end" gutter={[8, 16]}>
            <Col span={24}></Col>
            {!isUpdatePropertyLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <>
                {isUpdatePropertyLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Edit
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>{" "}
      </Modal>
    </>
  );
};
