/*eslint-disable*/
import React from "react";
import "antd/dist/antd.css";
import { Table, Row, Col } from "antd";
import "./styles/AppView.css";

export default (props) => {
  const { data, columns, onRowSelect } = props;
  return (
    <Row>
      <Col>
        <Table
        key={data}
          pagination={false}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => onRowSelect(record),
          //   };
          // }}

          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onRowSelect(record),
            };
          }}
          columns={columns ? columns : columns}
          dataSource={data ? data : data}
          scroll={{ x: 1500 }}
          sticky
          size="small"
        />
      </Col>
    </Row>
  );
};
