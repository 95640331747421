/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Select,
  Row,
  Popconfirm,
  message,
} from "antd";
import { DeleteFilled, EditTwoTone, SearchOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getUsers,
  createUser,
  deleteUser,
  getAuth0User,
  auth0UserReconsile,
} from "../Actions/usersAction";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateEmployeeUser } from "./updateEmployeeUser";

const Role = [
  { value: 0, label: "Admin" },
  { value: 1, label: "Employee" },
];
// const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
});
// const { TextArea } = Input;
const { Text, Title } = Typography;

const EmployeeUsers = () => {
  const dispatch = useDispatch();
  const {
    users,
    isGetUsersLoading,
    isCreateUserLoading,
    isUpdateUserLoading,
    isDeleteUserLoading,
    isAuth0UserLoading,
    auth0User,
  } = useSelector((state) => state.users);
  useEffect(() => {
    // dispatch(getUsers());
    dispatch(getAuth0User());
  }, []);

  const auth0db_user = auth0User
    ?.map((user) => {
      return user?.db_user;
    })
    .filter((dbUser) => dbUser !== null && dbUser !== undefined);

  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const [createDialog, setCreateDialog] = useState(false);
  const [error, setError] = useState();

  const handleCreateUser = (data, event) => {
    // event.preventDefault();
    dispatch(createUser(data, successCreateUser, failureCreateUser));
  };

  const successCreateUser = () => {
    setCreateDialog(false);
    dispatch(getAuth0User());
    reset();
    setTimeout(() => {
      message.success("You have successfully create user.");
    }, 2000);
    // dispatch(getUsers());
  };

  const failureCreateUser = (res) => {
    setTimeout(() => {
      message.info(res?.response?.data?.email);
    }, 2000);
    setCreateDialog(false);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    reset();
  };

  return (
    <Spin
      spinning={
        isAuth0UserLoading || isUpdateUserLoading || isDeleteUserLoading
      }
    >
      {/* <Button type="text" onClick={() => setCreateDialog(true)} style={{ fontWeight: 500, color: '#1890ff' }}>Create User</Button> */}
      <Modal
        title="Create User"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
      >
        <Spin spinning={isCreateUserLoading}>
          <form onSubmit={handleSubmit(handleCreateUser)}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Space direction="vertical" className="create_client_field">
                  <Text>First Name</Text>
                  <Controller
                    as={Input}
                    name="first_name"
                    control={control}
                    placeholder="First Name"
                    style={{ width: 200 }}
                  />
                  {errors.first_name && (
                    <span className="error_style">First Name is required</span>
                  )}
                </Space>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Space direction="vertical" className="create_client_field">
                  <Text>Last Name</Text>
                  <Controller
                    as={Input}
                    name="last_name"
                    control={control}
                    placeholder="Last Name"
                    style={{ width: 200 }}
                  />
                  {errors.last_name && (
                    <span className="error_style">Last Name is required</span>
                  )}
                </Space>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Space direction="vertical" className="create_client_field">
                  <Text>E-mail</Text>
                  <Controller
                    as={Input}
                    name="email"
                    control={control}
                    placeholder="E-mail"
                    style={{ width: 200 }}
                  />
                  {errors.email && (
                    <span className="error_style">E-mail is required</span>
                  )}
                </Space>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Space direction="vertical" className="create_client_field">
                  <Text>Role Type</Text>
                  <Controller
                    as={Select}
                    control={control}
                    options={Role}
                    style={{ width: 200 }}
                    name="role"
                  />
                  {errors.role && (
                    <span className="error_style">Role Type is required</span>
                  )}
                </Space>
              </Col>
            </Row>
            <Col className="notes_box">
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ top: 10 }}
              >
                Create User
              </Button>
            </Col>
          </form>
        </Spin>
      </Modal>
      <Col className="notes_box"></Col>
      {auth0db_user && (
        <AllEmployeeUsers
          data={auth0db_user}
          handleDialog={() => setCreateDialog(true)}
        />
      )}
    </Spin>
  );
};
export default EmployeeUsers;

const AllEmployeeUsers = (props) => {
  const [CurrentEmployeeUser, setCurrentEmployeeUser] = useState({});
  const { handleDialog } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const dispatch = useDispatch();
  const onRowSelect = (record) => {
    setCurrentEmployeeUser(record);
  };
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "First Name",
      width: 150,
      dataIndex: "first_name",
      key: "1",
      fixed: "left",
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Last Name",
      // width: 250,
      dataIndex: "last_name",
      key: "2",
      // fixed: 'left',
      ...getColumnSearchProps("last_name"),
    },

    {
      title: "E-mail",
      dataIndex: "email",
      key: "3",
      // width: 200,
    },
    {
      title: "Role Type",
      dataIndex: "role",
      key: "4",
      // width: 150,
      // filters: [
      //   {
      //     text: "role",
      //     value: "role",
      //   },
      // ],
      // onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "id",
      width: 250,
      dataIndex: "id",
      key: "5",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <ActionComponent id={id} />,
    },
  ];
  const reconcileAuth0Users = () => {
    dispatch(
      auth0UserReconsile(successAuth0UserReconsile, failureAuth0UserReconsile)
    );
  };
  const successAuth0UserReconsile = () => {
    dispatch(getAuth0User());
  };
  const failureAuth0UserReconsile = () => {};
  return (
    <>
      {/* <Title level={4} className='title_all_clients'>All Users</Title> */}
      <Row className="rowWithPadding" justify="space-between">
        <Col xs={24} sm={24} md={12} lg={16} xl={18}>
          <Title level={4}>All Users({props.data.length})</Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Row justify="space-between">
            <Col>
              <Button type="primary" onClick={() => handleDialog()}>
                Create User
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => reconcileAuth0Users()}>
                Auth 0 Reconsile
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <MyTable
            columns={columns}
            data={props.data}
            onRowSelect={(record) => onRowSelect(record)}
          />
        </Col>
      </Row>
    </>
  );
};

const ActionComponent = ({ id }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteUser = (user_id) => {
    dispatch(deleteUser(user_id, successDeleteUser, failureDeleteUser));
  };
  const successDeleteUser = () => {
    setTimeout(() => {
      message.success("You have successfully delete user.");
    }, 2000);
    dispatch(getAuth0User());
  };
  const failureDeleteUser = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };

  const content = (
    <Row>
      <Col>
        <UpdateEmployeeUser id={id} setPopoverVisible={setPopoverVisible} />
        &nbsp;&nbsp;
        <Popconfirm
          title="Sure to delete?"
          // onConfirm={() => dispatch(deleteUser(id))}
          onConfirm={() => handleDeleteUser(id)}
        >
          <Button type="link" onClick={() => setPopoverVisible(false)}>
            Delete
          </Button>
        </Popconfirm>
        {/* <DeleteFilled
        onClick={() => dispatch(deleteUser(id))}
        style={{ cursor: "pointer", color: "red" }}
      /> */}
      </Col>
    </Row>
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};
