/*eslint-disable*/
import React from "react";
import { Row, Col, Typography, Image, Card } from "antd";
import "../components/aboutus.css";
import bluesquare from "../assets/images/aboutus.svg";

const { Title, Text } = Typography;
const AboutUs = (props) => {
	const { aboutUsRef } = props
	return (
		<>
			<Row ref={aboutUsRef} style={{ background: "#fff", padding: "100px 0px" }} gutter={[4, 12]}>
				<Col span={2}></Col>
				<Col span={22} style={{ paddingTop: 20 }}>
					<Title level={1} className="aboutus">
						About Us
					</Title>
				</Col>
				<Col
					xl={{ span: 6 }}
					lg={{ span: 8, offset: 2 }}
					md={{ span: 12, offset: 2 }}
					xs={{ span: 16, offset: 2 }}
					sm={{ span: 10, offset: 2 }}
				>
					<Image preview={false} src={bluesquare} className="aboutimg" />
				</Col>
				<Col xl={10} lg={12} md={20} sm={10} xs={{ span: 16, offset: 2 }} sm={{ span: 16, offset: 2 }}>
					<div className="aboutContent">
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest. Fulfill your career
							dreams, enjoy all the achievements of the city center and luxury
							housing to the fullest.
					</Text>
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
						<br />
						<br />
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest. Fulfill your career
							dreams, enjoy all the achievements of the city center and luxury
							housing to the fullest.
					</Text>
						<br />
						<br />
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
						<Text>
							Fulfill your career dreams, enjoy all the achievements of the city
							center and luxury housing to the fullest.
					</Text>
					</div>
				</Col>



			</Row>
		</>
	);
};
export default AboutUs;
