import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setUser } from "../Actions/authAction";
import { useEffect } from "react";

export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const data = useAuth0();
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = data;
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUser({ user: user, isAuthenticated: isAuthenticated }));
      getToken();
    }
  }, []);
  const getToken = async () => {
    const result = await getIdTokenClaims();
    if (result.__raw) {
      localStorage.setItem("access_token", result.__raw);
    }
  };
  // if (isAuthenticated) {
  //   getToken();
  // }
  const { isAuthenticated: isStateAuthenticated } = useSelector(
    (state) => state.auth
  );
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
