import React from "react";
import { Row, Col, Typography, Button, Image, Card } from "antd";
import cardImage from "../assets/images/cardImage.svg";
import Rupees from "../assets/images/rupees.svg";
import Semifurnished from "../assets/images/semifurnished.svg";
import Home from "../assets/images/home.svg";
import Sqft from "../assets/images/sqft.svg";
import Allot from "../assets/images/allot.svg";
import Location from "../assets/images/location.svg";
import "../components/propertycard.css";
const PropertyCard = () => {
	return (
		<div style={{ padding: 15 }}>
			<Card
				className="pCard"
				bodyStyle={{ padding: 15 }}
				hoverable
				cover={
					<div>
						<img
							className="pCardImage"
							alt="example"
							src={cardImage}
							title="View All Images"
						/>
					</div>
				}
				bordered={false}
			>
				<Row>
					<Col span={12}>
						<Typography className="view_all_images">View All Images</Typography>
					</Col>
					<Col span={12} style={{ textAlign: "right" }}>
						<Button type="primary" value="small">
							For Resale
						</Button>
					</Col>
				</Row>

				<Row gutter={10} style={{ paddingTop: 20 }}>
					<Col span={24}>
						<Typography className="typography1">
							3 BHK Flat for sale Shivalik
						</Typography>
					</Col>
				</Row>

				<Row justify="start">
					<Col>
						<Typography className="typography2">
							<Image preview={false} src={Location} />
							Science city road, Sola
						</Typography>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={24}>
						<Typography className="price">
							<Image preview={false} src={Rupees} /> 50.3 Lac
						</Typography>
					</Col>
				</Row>

				<Row justify="space-between">
					<Col>
						<Row>
							<img src={Semifurnished} alt= "" />
							<Typography className="semi_furnished">SemiFurnished</Typography>
						</Row>
					</Col>
					<Col>
						<Row>
							<img src={Home} alt="" />
							<Typography className="semi_furnished">
								Highrise Apartment
							</Typography>
						</Row>
					</Col>
				</Row>

				<Row justify="space-between">
					<Col>
						<Row>
							<img src={Sqft} alt="" />
							<Typography className="semi_furnished">1685 sqft</Typography>
						</Row>
					</Col>
					<Col>
						<Row>
							<img src={Allot} alt="" />
							<Typography className="semi_furnished"> 1 Alloted</Typography>
						</Row>
					</Col>
				</Row>

				{/* <Row justify="end" className="end">
					<Col>Posted 3 weeks ago by Owner</Col>
				</Row> */}
			</Card>
		</div>
	);
};

export default PropertyCard;
