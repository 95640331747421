import API from "../Utils/API";

export const getAllProjects = (type) => (dispatch) => {
  dispatch({ type: "GET_PROJECTS_REQUEST" });
  API.get(`/projects?type=${type}`)
    .then((response) => {
      dispatch({
        type: "GET_PROJECTS_SUCCESS",
        payload: response.data,
        getType: type,
      });
    })
    .catch((error) => {
      dispatch({ type: "GET_PROJECTS_FAILURE", payload: error.response.data });
    });
};

export const createProject =
  (data, type, successCreateProject, failureCreateProject) => (dispatch) => {
    dispatch({ type: "CREATE_PROJECT_REQUEST", payload: data });
    var formData = new FormData();
    formData.append("image_url", data.image_url);
    formData.append("project[project_type]", data.project_type);
    formData.append("project[description]", JSON.stringify(data.description));
    formData.append("project[property_name]", data.property_name);
    formData.append("project[builder]", data.builder);
    formData.append("project[no_of_floor]", data.no_of_floor);
    formData.append("project[area_id]", data.area_id);
    formData.append("project[tag_line]", data.tag_line);
    formData.append("project[possesion]", data.possesion);
    formData.append("project[google_link]", data.google_link);
    formData.append("project[parking]", data.parking);
    formData.append("imageable_id", data.id);
    formData.append("imageable_type", "Project");

    API.post("/projects", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({ type: "CREATE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successCreateProject ? successCreateProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureCreateProject ? failureCreateProject() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "CREATE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };

export const updateProject =
  (data, type, successUpdateProject, failureUpdateProject) => (dispatch) => {
    dispatch({ type: "UPDATE_PROJECT_REQUEST", payload: data });
    var formData = new FormData();
    formData.append("image_url", data.image_url);
    formData.append("project[project_type]", data.project_type);
    formData.append("project[description]", JSON.stringify(data.description));
    formData.append("project[property_name]", data.property_name);
    formData.append("project[builder]", data.builder);
    formData.append("project[no_of_floor]", data.no_of_floor);
    formData.append("project[area_id]", data.area_id);
    formData.append("project[tag_line]", data.tag_line);
    formData.append("project[possesion]", data.possesion);
    formData.append("project[google_link]", data.google_link);
    formData.append("project[parking]", data.parking);
    API.put(`/projects/${data.id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({ type: "UPDATE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successUpdateProject ? successUpdateProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureUpdateProject ? failureUpdateProject() : null;
        } catch {}
      });
    // })
    // .catch(error => {
    //     dispatch({ type: "UPDATE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };

export const publishedProject =
  (data, type, publishedBoolean) => (dispatch) => {
    dispatch({ type: "PUBLISHED_PROJECT_REQUEST", payload: data });
    API.put(`/published/project/${data}`, { published: publishedBoolean })
      .then((response) => {
        dispatch({ type: "PUBLISHED_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
      })
      .catch((error) => {
        dispatch({
          type: "PUBLISHED_PROJECT_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteProject =
  (project_id, type, successDeleteProject, failureDeleteProject) =>
  (dispatch) => {
    dispatch({ type: "DELETE_PROJECT_REQUEST" });
    API.delete(`/projects/${project_id}`)
      .then((response) => {
        dispatch({ type: "DELETE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successDeleteProject ? successDeleteProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureDeleteProject ? failureDeleteProject() : null;
        } catch {}
      });
    // })
    // .catch(error => {
    //     dispatch({ type: "DELETE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };
