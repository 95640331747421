import constants from '../Utils/constants';
const initialState = {
    isGetClientsLoading: false, isCreateClientLoading: false, isUpdateClientLoading: false, isDeleteClientLoading: false,
    clients: []
}

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_CLIENTS_REQUEST:
            return {
                ...state, isGetClientsLoading: true
            }
        case constants.GET_CLIENTS_SUCCESS:
            return {
                ...state, isGetClientsLoading: false, clients: action.payload
            }
        case constants.GET_CLIENTS_FAILURE:
            return {
                ...state, isGetClientsLoading: false
            }

        case constants.CREATE_CLIENT_REQUEST:
            return {
                ...state, isCreateClientLoading: true
            }
        case constants.CREATE_CLIENT_SUCCESS:
            return {
                ...state, isCreateClientLoading: false
            }
        case constants.CREATE_CLIENT_FAILURE:
            return {
                ...state, isCreateClientLoading: false
            }

        case constants.UPDATE_CLIENT_REQUEST:
            return {
                ...state, isUpdateClientLoading: true
            }
        case constants.UPDATE_CLIENT_SUCCESS:
            return {
                ...state, isUpdateClientLoading: false
            }
        case constants.UPDATE_CLIENT_FAILURE:
            return {
                ...state, isUpdateClientLoading: false
            }

        case constants.DELETE_CLIENT_REQUEST:
            return {
                ...state, isDeleteClientLoading: true
            }
        case constants.DELETE_CLIENT_SUCCESS:
            return {
                ...state, isDeleteClientLoading: false
            }
        case constants.DELETE_CLIENT_FAILURE:
            return {
                ...state, isDeleteClientLoading: false
            }
            case "REMOVING_USER_CREDENTIALS":
                return {
                    ...initialState
                }
        default:
            return state
    }
}
export default clientsReducer;