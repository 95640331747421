import API from "../Utils/API";
import constants from "../Utils/constants";
export const getClients = () => (dispatch) => {
  dispatch({ type: constants.GET_CLIENTS_REQUEST });
  API.get("/clients")
    .then((response) => {
      dispatch({ type: constants.GET_CLIENTS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_CLIENTS_FAILURE,
        payload: error.response.data,
      });
    });
};

export const createClient =
  (data, successCreateClient, failureCreateClient) => (dispatch) => {
    dispatch({ type: constants.CREATE_CLIENT_REQUEST, payload: data });
    API.post("/clients", data)
      .then((response) => {
        dispatch({
          type: constants.CREATE_CLIENT_SUCCESS,
          payload: response.data,
        });
        dispatch(getClients());
        const SCA = successCreateClient ? successCreateClient() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_CLIENT_FAILURE",
            payload: error.response.data,
          });

          const FCA = failureCreateClient ? failureCreateClient() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: constants.CREATE_CLIENT_FAILURE, payload: error.response.data })
//         })
// }

export const updateClient =
  (data, successUpdateClient, failureUpdateClient) => (dispatch) => {
    dispatch({ type: constants.UPDATE_CLIENT_REQUEST, payload: data });
    API.put(`/clients/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_CLIENT_SUCCESS,
          payload: response.data,
        });
        dispatch(getClients());
        const SLA = successUpdateClient ? successUpdateClient() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_CLIENT_FAILURE",
            payload: error.response.data,
          });

          const FLA = failureUpdateClient ? failureUpdateClient() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: constants.UPDATE_CLIENT_FAILURE, payload: error.response.data })
//         })
// }

export const deleteClient =
  (client_id, successDeleteClient, failureDeleteClient) => (dispatch) => {
    dispatch({ type: constants.DELETE_CLIENT_REQUEST });
    API.delete(`/clients/${client_id}`)
      .then((response) => {
        dispatch({
          type: constants.DELETE_CLIENT_SUCCESS,
          payload: response.data,
        });
        dispatch(getClients());
        const SLA = successDeleteClient ? successDeleteClient() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_CLIENT_FAILURE",
            payload: error.response.data,
          });

          const FLA = failureDeleteClient ? failureDeleteClient() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: constants.DELETE_CLIENT_FAILURE, payload: error.response.data })
//         })
// }
