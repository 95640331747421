/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Spin,
  Select,
  Row,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUsers } from "../Actions/usersAction";
import { createTask } from "../Actions/tasksAction";
import "./styles/clients.css";

const schema = yup.object().shape({
  due_date: yup.string().required("Required"),
  note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const CreateTask = ({
  attachment_id,
  attachment_type,
  setPopoverVisible,
}) => {
  const { users, isCreateTaskLoading } = useSelector((state) => {
    const { isCreateTaskLoading } = state.tasks;
    const { users, isGetUsersLoading } = state.users;
    return {
      users,
      isGetUsersLoading,
      isCreateTaskLoading,
    };
  });
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreateTask = (data, event) => {
    event.preventDefault();
    data.attachment_id = attachment_id;
    data.attachment_type = attachment_type;
    dispatch(createTask(data));
    setOpen(false);
    reset();
  };
  const handleCancel = () => {
    setOpen(false);
    reset();
  };
  const handleOpenAssignTask = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <Spin spinning={isCreateTaskLoading}></Spin>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenAssignTask()}
      >
        AssignTask
      </Button>
      {/* <CopyOutlined 
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        /> */}
      <Modal
        title="Create Task"
        visible={open}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateTask)}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>User Name</Text>
                <Controller
                  as={Select}
                  showSearch
                  control={control}
                  options={
                    users &&
                    users.length > 0 &&
                    users.map((val, index) => {
                      return { label: val.first_name, value: val.id };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                  name="user_id"
                />
                {errors.user_id && (
                  <span className="error_style">User name is required</span>
                )}
              </Space>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>Due Date</Text>
                <Controller
                  as={Input}
                  name="due_date"
                  control={control}
                  placeholder="Due Date"
                  type="date"
                  style={{ width: 200 }}
                />
                {errors.due_date && (
                  <span className="error_style">Due Date is required</span>
                )}
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="notes_box">
              <Text>Note</Text>
              <Controller
                as={TextArea}
                name="note"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.note && (
                <span className="error_style">Note is required</span>
              )}
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                block
                // style={{ top: 10 }}
              >
                Create Task
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
