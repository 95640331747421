/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Select,
  Row,
  Popconfirm,
  Divider,
  message,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import {
  getAllProjects,
  createProject,
  deleteProject,
  updateProject,
  publishedProject,
} from "../Actions/projectsAction";
import MyTable from "../components/table";
import "./styles/clients.css";
import { ViewCommercialProjects } from "../pages/ViewCommercialProjects";
import { UpdateCommercialProjects } from "../pages/updateCommercialProjects";
const schema = yup.object().shape({
  property_name: yup.string().required("Required"),
  tag_line: yup.string().required("Required"),
  builder: yup.string().required("Required"),
  no_of_floor: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  possesion: yup.string().required("Required"),
  // parking: yup.string().required("Required"),
});
const { Text, Title } = Typography;

const CommercialProjects = () => {
  const dispatch = useDispatch();
  const {
    areas,
    projects,
    isGetProjectsLoading,
    isCreateProjectLoading,
    isUpdateProjectLoading,
    isDeleteProjectLoading,
  } = useSelector((state) => {
    const {
      projects,
      isGetProjectsLoading,
      isCreateProjectLoading,
      isUpdateProjectLoading,
      isDeleteProjectLoading,
    } = state.projects;
    const { areas } = state.areas;
    return {
      areas,
      projects,
      isGetProjectsLoading,
      isCreateProjectLoading,
      isUpdateProjectLoading,
      isDeleteProjectLoading,
    };
  });
  useEffect(() => {
    dispatch(getAllProjects("Commercial"));
  }, []);
  useEffect(() => {
    dispatch(getAllAreas());
  }, []);
  const { register, handleSubmit, errors, clearErrors, control, reset } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: {},
      mode: "onChange",
    });
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");
  const [createDialog, setCreateDialog] = useState(false);
  const [description, setDescription] = useState([
    { key: "", value: "", id: Math.random() },
  ]);
  const handleResetForm = () => {
    setDescription([...description, { key: "", value: "" }]);
  };
  const handleCreateProject = (data, type, event) => {
    // event.preventDefault();
    data.parking = 0;
    data.image_url = files.length > 0 ? files[0] : null;
    data.project_type = "Commercial";
    data.description = { description };
    dispatch(
      createProject(data, "Commercial", successCreateArea, failureCreateArea)
    );
  };
  const successCreateArea = () => {
    setFiles([]);
    setName("");
    setDescription([{ key: "", value: "" }]);
    setCreateDialog(false);
    reset({
      property_name: "",
      no_of_floor: "",
      area_id: "",
      image_url: "",
      builder: "",
      tag_line: "",
      possesion: "",
    });
    setTimeout(() => {
      message.success("You have successfully create commercial project.");
    }, 2000);
  };
  const failureCreateArea = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    reset();
  };
  const handleAdd = () => {
    const newDescription = description.concat([
      { key: "", value: "", id: Math.random() },
    ]);
    setDescription(newDescription);
  };
  const handleRemove = (id) => {
    const newDescription = description.filter((x) => x.id !== id);
    setDescription(newDescription);
  };
  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const newDescription = description;
    const selectedObject = newDescription[index];
    selectedObject[name] = value;
    setDescription(newDescription);
  };
  const handleCancel = () => {
    reset({
      property_name: "",
      no_of_floor: "",
      area_id: "",
      image_url: "",
      builder: "",
      tag_line: "",
      possesion: "",
    });
    setFiles([]);
    setName("");
    clearErrors();
    setDescription([{ key: "", value: "" }]);
    setCreateDialog(false);
  };
  const handleImageChange = (event) => {
    const { value, files } = event.target;
    setName(value);
    setFiles(files);
  };
  return (
    <Spin
      spinning={
        isGetProjectsLoading ||
        isCreateProjectLoading ||
        isUpdateProjectLoading ||
        isDeleteProjectLoading
      }
    >
      <Modal
        title="Create Commercial Project"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateProject)}>
          {files.length > 0 && (
            <img
              className="pCardImage"
              alt=""
              src={URL.createObjectURL(files[0])}
            />
          )}
          <input
            name="image_url"
            value={name}
            onChange={(e) => handleImageChange(e)}
            type="file"
          ></input>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Project Name.</Text>
              </Row>
              <Controller
                as={Input}
                name="property_name"
                control={control}
                placeholder="Property Name."
              />
              {errors.property_name && (
                <span className="error_style">Property Name is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Builder</Text>
              </Row>
              <Controller
                as={Input}
                name="builder"
                control={control}
                placeholder="Builder"
              />
              {errors.builder && (
                <span className="error_style">Builder is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>No of Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="no_of_floor"
                control={control}
                placeholder="No of Floor"
              />
              {errors.no_of_floor && (
                <span className="error_style">No of Floor is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Tag Line</Text>
              </Row>
              <Controller
                as={Input}
                name="tag_line"
                control={control}
                placeholder="Tag Lin"
              />
              {errors.tag_line && (
                <span className="error_style">Tag Lin is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Possesion</Text>
              </Row>
              <Controller
                as={Input}
                name="possesion"
                control={control}
                placeholder="Possesion"
              />
              {errors.possesion && (
                <span className="error_style">possesion is required</span>
              )}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Button onClick={handleAdd} type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          </Row>

          {description.map((des, index) => {
            return (
              <Row key={index} gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Key</Text>
                  </Row>
                  <Input
                    key={des.id}
                    name={`key`}
                    onChange={(e) => handleOnChange(e, index)}
                    placeholder="e.g. Showroom, Shopes Offices"
                  />
                  {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>value</Text>
                  </Row>
                  <Input
                    key={des.id}
                    name={`value`}
                    onChange={(e) => handleOnChange(e, index)}
                    placeholder="e.g. 2500.Sqft"
                  />
                  {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                </Col>
                {index !== 0 && (
                  <Button type="link" onClick={() => handleRemove(des.id)}>
                    Remove
                  </Button>
                )}
              </Row>
            );
          })}

          <Row justify="end" gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col>
              {isCreateProjectLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button type="primary" htmlType="submit">
                  Create Commercial Project
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Modal>
      {projects && (
        <AllCommercialProjects
          data={projects}
          handleDialog={() => setCreateDialog(true)}
        />
      )}
      {/* {projects && <AllProperties data={projects} />} */}
    </Spin>
  );
};
export default CommercialProjects;

const AllCommercialProjects = (props) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { handleDialog } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onRowSelect = (record) => {
    setCurrentProject(record);
    setShowSearch(true);
  };
  const onTaskRowClick = (task) => {};
  // useEffect(() => {
  //   if (createProject !== {}) {
  //     // dispatch() getTasks
  //     // dispatch() getSearch Properies
  //   }
  // }, [currentProject]);
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = (areas) => [
    {
      title: "Project Name",
      // width: 250,
      dataIndex: "property_name",
      key: "1",
      // fixed: "left",
    },
    {
      title: "Tag Line",
      // width: 250,
      dataIndex: "tag_line",
      key: "2",
      // fixed: "left",
    },
    {
      title: "Builder",
      // width: 250,
      dataIndex: "builder",
      key: "3",
      // fixed: "left",
    },
    {
      title: "No Of Floor",
      // width: 150,
      dataIndex: "no_of_floor",
      key: "4",
      // fixed: "left",
    },
    {
      title: "Possesion",
      dataIndex: "possesion",
      key: "5",
      // width: 150,
    },
    // {
    //   title: "Parking",
    //   dataIndex: "parking",
    //   key: "4",
    //   // width: 150,
    // },
    {
      title: "Area",
      dataIndex: "area",
      key: "6",
      width: "20%",
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    {
      title: "id",
      width: 250,
      dataIndex: "id",
      key: "7",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <ActionComponent
          id={id}
          type="Commercial"
          published={currentProject.published}
        />
      ),
    },
  ];
  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>All Commercial Projects({props.data.length})</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create Commercial Project
          </Button>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
      />
    </>
  );
};
const ActionComponent = ({ id, type, published }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteCommercialProject = (project_id) => {
    project_id = id;
    dispatch(
      deleteProject(
        project_id,
        "Commercial",
        successDeleteCommercialProject,
        failureDeleteCommercialProject
      )
    );
  };

  const successDeleteCommercialProject = () => {
    // setCreateDialog(false);
    setTimeout(() => {
      message.success("You have successfully delete commercial project.");
    }, 2000);
  };
  const failureDeleteCommercialProject = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleUpdatePublished = () => {
    // event.preventDefault();
    setPopoverVisible(false);
    dispatch(publishedProject(id, type, true));
  };
  const handleUpdateUnPublished = () => {
    // event.preventDefault();
    setPopoverVisible(false);
    dispatch(publishedProject(id, type, false));
  };
  const content = (
    <div>
      {/* <ViewProperty id={id} />
      &nbsp;&nbsp; */}
      {/* <UpdateProperty id={id} />
      &nbsp;&nbsp; */}
      &nbsp;&nbsp;
      <ViewCommercialProjects id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      {published ? (
        <Button type="link" onClick={() => handleUpdateUnPublished()}>
          Un Published
        </Button>
      ) : (
        <Button type="link" onClick={() => handleUpdatePublished()}>
          Published
        </Button>
      )}
      &nbsp;&nbsp;
      <UpdateCommercialProjects id={id} setPopoverVisible={setPopoverVisible} />
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deleteProject(id, type))}
        onConfirm={() => handleDeleteCommercialProject(id, type)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      {/* <DeleteFilled
        onClick={() => dispatch(deletePropery(id))}
        style={{ cursor: "pointer", color: "red" }}
      /> */}
    </div>
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};
