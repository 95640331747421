import { combineReducers } from "redux";
import authReducer from './authReducer';
import leadsReducer from './leadsReducer';
import clientsReducer from './clientsReducer';
import propertiesReducer from './propertiesReducer';
import areasReducer from './areasReducer';
import taskssReducer from './tasksReducer';
import usersReducer from './usersReducer';
import postPropertiesReducer from './postPropertyReducer';
import notificationReducer from './notificationReducer';
import interestedsReducer from "./interestedReducer";
import birthdateReducer from "./birthdateReducer";
import projectsReducer from "./projectsReducer";
import activitiesReducer from "./activitiesReducer";
const rootReducer = combineReducers({
    auth: authReducer,
    leads: leadsReducer,
    clients: clientsReducer,
    properties: propertiesReducer,
    areas: areasReducer,
    tasks: taskssReducer,
    users: usersReducer,
    postProperties: postPropertiesReducer,
    notifications: notificationReducer,
    interesteds: interestedsReducer,
    birthdate: birthdateReducer,
    projects: projectsReducer,
    activities: activitiesReducer
})

export default rootReducer;