import constants from "../Utils/constants";
const initialState = {
  leads: [],
  lead_typs: [],
  isGetLeadsLoading: false,
  isCreateLeadLoading: false,
  isUpdateLeadLoading: false,
  isDeleteLeadLoading: false,
  isGetLeadTypeLoading: false,
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LEADS_REQUEST:
      return {
        ...state,
        isGetLeadsLoading: true,
      };
    case constants.GET_LEADS_SUCCESS:
      return {
        ...state,
        isGetLeadsLoading: false,
        leads: action.payload,
      };
    case constants.GET_LEADS_FAILURE:
      return {
        ...state,
        isGetLeadsLoading: false,
      };
    case constants.GET_LEAD_TYPES_REQUEST:
      return {
        ...state,
        isGetLeadTypeLoading: true,
      };
    case constants.GET_LEAD_TYPES_SUCCESS:
      return {
        ...state,
        lead_typs: action.payload,
        isGetLeadTypeLoading: true,
      };
    case constants.GET_LEAD_TYPES_FAILURE:
      return {
        ...state,
        isGetLeadTypeLoading: true,
      };

    case constants.GET_LEAD_BY_ID_REQUEST:
      return {
        ...state,
        isGetLeadByIdLoading: true,
      };
    case constants.GET_LEAD_BY_ID_SUCCESS:
      return {
        ...state,
        isGetLeadByIdLoading: false,
      };
    case constants.GET_LEAD_BY_ID_FAILURE:
      return {
        ...state,
        isGetLeadByIdLoading: false,
      };

    case constants.CREATE_LEAD_REQUEST:
      return {
        ...state,
        isCreateLeadLoading: true,
      };
    case constants.CREATE_LEAD_SUCCESS:
      return {
        ...state,
        isCreateLeadLoading: false,
      };
    case constants.CREATE_LEAD_FAILURE:
      return {
        ...state,
        isCreateLeadLoading: false,
      };

    case constants.UPDATE_LEAD_REQUEST:
      return {
        ...state,
        isUpdateLeadLoading: true,
      };
    case constants.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        isUpdateLeadLoading: false,
      };
    case constants.UPDATE_LEAD_FAILURE:
      return {
        ...state,
        isUpdateLeadLoading: false,
      };

    case constants.DELETE_LEAD_REQUEST:
      return {
        ...state,
        isDeleteLeadLoading: true,
      };
    case constants.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        isDeleteLeadLoading: false,
      };
    case constants.DELETE_LEAD_FAILURE:
      return {
        ...state,
        isDeleteLeadLoading: false,
      };
    case "REMOVING_USER_CREDENTIALS":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default leadsReducer;
