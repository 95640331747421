import constants from '../Utils/constants';
const initialState = {
    isGetAreasLoading: false, isCreateAreaLoading: false, isUpdateAreaLoading: false, isDeleteAreaLoading: false,
    areas: []
}

const areasReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_AREAS_REQUEST:
            return {
                ...state, isGetAreasLoading: true
            }
        case constants.GET_AREAS_SUCCESS:
            return {
                ...state, isGetAreasLoading: false, areas: action.payload
            }
        case constants.GET_AREAS_FAILURE:
            return {
                ...state, isGetAreasLoading: false
            }

        case constants.GET_AREA_BY_ID_REQUEST:
            return {
                ...state, isGetAreaByIdLoading: true
            }
        case constants.GET_AREA_BY_ID_SUCCESS:
            return {
                ...state, isGetAreaByIdLoading: false
            }
        case constants.GET_AREA_BY_ID_FAILURE:
            return {
                ...state, isGetAreaByIdLoading: false
            }

        case constants.CREATE_AREA_REQUEST:
            return {
                ...state, isCreateAreaLoading: true
            }
        case constants.CREATE_AREA_SUCCESS:
            return {
                ...state, isCreateAreaLoading: false
            }
        case constants.CREATE_AREA_FAILURE:
            return {
                ...state, isCreateAreaLoading: false
            }

        case constants.UPDATE_AREA_REQUEST:
            return {
                ...state, isUpdateAreaLoading: true
            }
        case constants.UPDATE_AREA_SUCCESS:
            return {
                ...state, isUpdateAreaLoading: false
            }
        case constants.UPDATE_AREA_FAILURE:
            return {
                ...state, isUpdateAreaLoading: false
            }

        case constants.DELETE_AREA_REQUEST:
            return {
                ...state, isDeleteAreaLoading: true
            }
        case constants.DELETE_AREA_SUCCESS:
            return {
                ...state, isDeleteAreaLoading: false
            }
        case constants.DELETE_AREA_FAILURE:
            return {
                ...state, isDeleteAreaLoading: false
            }
        default:
            return state
    }
}
export default areasReducer;