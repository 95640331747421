import API from '../Utils/API';

export const getBirthdate = () => dispatch => {
    dispatch({ type: "GET_CLIENTS_BIRTHDATE_REQUEST" })
    API.get('/client_birth_date')
        .then(response => {
            dispatch({ type: "GET_CLIENTS_BIRTHDATE_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_CLIENTS_BIRTHDATE_FAILURE", payload: error.response.data })
        })
}
