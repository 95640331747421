/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";

import {
  Row,
  Col,
  Layout,
  Typography,
  Input,
  Form,
  Card,
  Space,
  InputNumber,
  Select,
  Button,
  Checkbox,
} from "antd";
// import "./styles/navbar.css";
import "../components/bannerimage.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { createPostPropery } from "../Actions/postPropertyAction";
// import "./styles/clients.css";
import { getPostProperties } from "../Actions/postPropertyAction";
import HomeFooter from "../components/Footer";
import HomeHeader from "../components/Header";
import BannerImage from "../components/BannerImage";
import banner from "../assets/images/banner.png";
import { SuccessPage } from "../components/SuccessPage";

const { Header, Content, Footer } = Layout;
const owner = [
  { label: "Dhruv", value: "Dhruv" },
  { label: "Pooja", value: "Pooja" },
];
const sale = [
  { label: "flat", value: "flat" },
  { label: "bunglow", value: "bunglow" },
];
const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];

const { TextArea } = Input;
const { Text, Title } = Typography;
const NewProperty = (props) => {
  const { postProperty } = props;
  const dispatch = useDispatch();
  const { areas, postProperties, isCreatePostPropertyLoading } = useSelector(
    (state) => {
      const { postProperties, isCreatePostPropertyLoading } =
        state.postProperties;
      const { areas } = state.areas;
      return {
        areas,
        postProperties,
        isCreatePostPropertyLoading,
      };
    }
  );
  useEffect(() => {
    dispatch(getAllAreas());
    dispatch(getPostProperties());
  }, []);

  const [termsAgreed, setTermsAgreed] = useState({
    isChecked: false,
    message: null,
  });

  const handleCheckboxChange = (e) => {
    setTermsAgreed({
      isChecked: e.target.checked,
      message: null,
    });
  };
  const schema = yup.object().shape({
    client: yup.object().shape({
      name: yup.string().required("Client Name is required"),
      // email: yup.string().email().required("Email is required"),
      mobile_number: yup.string().required("Mobile Number is required"),
    }),
    property: yup.object().shape({
      property_type: yup.string().required("Property Type is required"),
      cost: yup.number().required("Cost is required"),
      construction_year: yup.date().required("Construction Year is required"),
    }),
  });

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const handleChange = () => {
    // form.setFieldsValue({ sights: [] });
  };
  const [current, setCurrent] = React.useState(0);
  const [createDialog, setCreateDialog] = useState(false);
  const handlePostProperty = (data, event) => {
    if (!termsAgreed?.isChecked) {
      setTermsAgreed({
        message: "Please accepts the term and conditons",
      });
      return;
    }
    event.preventDefault();
    dispatch(createPostPropery(data));
    setCreateDialog(true);
  };

  return (
    <>
      <Layout>
        <Header className="header">
          <HomeHeader postProperty={true} />
        </Header>

        <Content style={{ backgroundColor: "white" }}>
          <Row className="banner_image">
            <Col span={24}></Col>

            <Col span={24}>
              <Row>
                <Col
                  xl={{ span: 14, offset: 2 }}
                  lg={{ span: 14, offset: 2 }}
                  md={{ span: 14, offset: 2 }}
                  xs={{ span: 22, offset: 2 }}
                  sm={{ span: 22, offset: 2 }}
                >
                  <div className="newPropertyContent">
                    <Title className="bannerText">
                      Sell and Rent Your Property
                    </Title>
                    <Typography className="bannerDescription">
                      We have build one stop solutions for you.
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="start" className="pForm">
            <Col xl={1} lg={2} md={2} sm={24} xs={24}></Col>
            {/* </div> */}
            <Col xl={16} lg={20} md={20} sm={24} xs={24}>
              <Card>
                <form onSubmit={handleSubmit(handlePostProperty)}>
                  <Card className="card1">
                    <Typography className="personal_details pSectionTitle">
                      Personal Details
                    </Typography>
                    <Card className="formcard">
                      <Row span={24}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Form.Item style={{ margin: "8px" }}>
                            <Controller
                              as={Input}
                              name="client[name]"
                              control={control}
                              placeholder="Enter your name"
                            />
                            {errors?.client?.name?.message && (
                              <span className="error_style">
                                {/* Client Name is required */}
                                {errors?.client?.name?.message}
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item style={{ margin: "8px" }}>
                            <Controller
                              as={Input}
                              name="client[email]"
                              control={control}
                              placeholder="Enter your Email"
                            />
                            {errors.priority && (
                              <span className="error_style">
                                Email-Id is required
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item style={{ margin: "8px" }}>
                            <Controller
                              as={Input}
                              name="client[mobile_number]"
                              type="number"
                              control={control}
                              placeholder="+91"
                            />
                            {errors?.client?.mobile_number?.message && (
                              <span className="error_style">
                                {errors?.client?.mobile_number?.message}
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Typography className="properties_details pSectionTitle">
                        Properties Details
                      </Typography>
                      <Card className="formcard">
                        <Row span={24}>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Property Type</Text>
                              <Controller
                                as={Select}
                                control={control}
                                options={PropertyType}
                                name="property[property_type]"
                                placeholder="Select Property Type"
                              />
                              {errors?.property?.property_type?.message && (
                                <span className="error_style">
                                  {errors?.property?.property_type?.message}
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Bhk</Text>
                              <Controller
                                as={Input}
                                name="property[bhk]"
                                control={control}
                                placeholder="Total Bhk"
                                type="number"
                              />
                              {errors.bhk && (
                                <span className="error_style">
                                  Bhk is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Floor</Text>
                              <Controller
                                as={Input}
                                name="property[floor]"
                                control={control}
                                placeholder="Floor"
                                type="number"
                                style={{ width: 200 }}
                              />
                              {errors.floor && (
                                <span className="error_style">
                                  Floor is required
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Total Floor</Text>
                              <Controller
                                as={Input}
                                name="property[total_floor]"
                                control={control}
                                placeholder="Total Floor"
                                type="number"
                                style={{ width: 200 }}
                              />
                              {errors.total_floor && (
                                <span className="error_style">
                                  Total Floor is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Cost</Text>
                              <Controller
                                name="property[cost]"
                                control={control}
                                render={(props) => (
                                  <InputNumber
                                    style={{ width: 200 }}
                                    formatter={(value) =>
                                      `₹ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    {...props}
                                  />
                                )}
                              />
                              {errors?.property?.cost?.message && (
                                <span className="error_style">
                                  {errors?.property?.cost?.message}
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Society Transfer Fee</Text>
                              <Controller
                                name="property[society_transfer_fee]"
                                control={control}
                                render={(props) => (
                                  <InputNumber
                                    style={{ width: 200 }}
                                    formatter={(value) =>
                                      `₹ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    {...props}
                                  />
                                )}
                              />
                              {errors.society_transfer_fee && (
                                <span className="error_style">
                                  Society Transfer Fee is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Construction Year</Text>
                              <Controller
                                as={Input}
                                name="property[construction_year]"
                                control={control}
                                placeholder="Construction Year"
                                type="date"
                                style={{ width: 200 }}
                              />
                              {errors?.property?.construction_year?.message && (
                                <span className="error_style">
                                  {errors?.property?.construction_year?.message}
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Visit Time</Text>
                              <Controller
                                as={Input}
                                name="property[visit_time]"
                                control={control}
                                placeholder="Visit Time"
                                type="time"
                                style={{ width: 200 }}
                              />
                              {errors.visit_time && (
                                <span className="error_style">
                                  Visit Time is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>About Key</Text>
                              <Controller
                                as={Input}
                                name="property[about_key]"
                                control={control}
                                placeholder="About Key"
                                style={{ width: 200 }}
                              />
                              {errors.about_key && (
                                <span className="error_style">
                                  About Key is required
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Vastu Complient</Text>
                              <Controller
                                as={Input}
                                name="property[vastu_complient]"
                                control={control}
                                placeholder="Vastu Complient"
                                type="number"
                                style={{ width: 200 }}
                              />
                              {errors.vastu_complient && (
                                <span className="error_style">
                                  Vastu Complient is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Residential</Text>
                              <Controller
                                name="property[is_residential]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Commercial</Text>
                              <Controller
                                name="property[is_commercial]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Plot</Text>
                              <Controller
                                name="property[is_plot]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>New</Text>
                              <Controller
                                name="property[is_new]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Resell</Text>
                              <Controller
                                name="property[is_resell]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Rent</Text>
                              <Controller
                                name="property[is_rent]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="horizontal"
                              className="create_client_field"
                            >
                              <Text>Lease</Text>
                              <Controller
                                name="property[is_lease]"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Facing</Text>
                              <Controller
                                as={Input}
                                name="property[facing]"
                                control={control}
                                placeholder="Facing"
                                style={{ width: 200 }}
                              />
                              {errors.facing && (
                                <span className="error_style">
                                  Facing is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="notes_box"
                          >
                            <Text>Details</Text>
                            <Controller
                              as={TextArea}
                              name="property[details]"
                              control={control}
                              rows={4}
                              showCount
                              maxLength={100}
                            />
                            {errors.details && (
                              <span className="error_style">
                                Details is required
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Card>
                      <Typography className="properties_location pSectionTitle">
                        Properties Location
                      </Typography>
                      <Card className="formcard3 formcard">
                        {/* <Form> */}
                        <Row span={24}>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Address</Text>
                              <Controller
                                as={Input}
                                name="property[address]"
                                control={control}
                                placeholder="Address"
                                style={{ width: 200 }}
                              />
                              {errors.address && (
                                <span className="error_style">
                                  Address is required
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Area</Text>
                              <Controller
                                as={Select}
                                control={control}
                                options={
                                  areas &&
                                  areas.length > 0 &&
                                  areas.map((val, index) => {
                                    return { label: val.name, value: val.id };
                                  })
                                }
                                style={{ width: 200 }}
                                name="property[area_id]"
                              />
                              {errors.area_id && (
                                <span className="error_style">
                                  Area is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        <Row span={24}>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>Landmark</Text>
                              <Controller
                                as={Input}
                                name="property[landmark]"
                                control={control}
                                placeholder="Landmark"
                                style={{ width: 200 }}
                              />
                              {errors.landmark && (
                                <span className="error_style">
                                  Landmark is required
                                </span>
                              )}
                            </Space>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Space
                              direction="vertical"
                              className="create_client_field"
                            >
                              <Text>City</Text>
                              <Controller
                                as={Input}
                                name="property[city]"
                                control={control}
                                placeholder="City"
                                style={{ width: 200 }}
                              />
                              {errors.city && (
                                <span className="error_style">
                                  City is required
                                </span>
                              )}
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Card>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Checkbox
                          className="checkbox pSectionTitle"
                          onChange={handleCheckboxChange}
                        >
                          I am agree with terms and conditions.
                        </Checkbox>
                        {termsAgreed?.message && (
                          <span className="error_style">
                            {termsAgreed?.message}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button_postproperty"
                        >
                          Post Property
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </form>
              </Card>
            </Col>
          </Row>
          {createDialog && (
            <SuccessPage
              createDialog={createDialog}
              setCreateDialog={setCreateDialog}
              // handleDialog={() => setCreateDialog(true)}
            />
          )}
        </Content>
        <Footer className="footer_header">
          <HomeFooter />
        </Footer>
      </Layout>
    </>
  );
};
export default NewProperty;
