import API from '../Utils/API';

export const getActivities = (url) => dispatch => {
    dispatch({ type: "GET_ACTIVITIES_REQUEST" })
    API.get(url)
        .then(response => {
            dispatch({ type: "GET_ACTIVITIES_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_ACTIVITIES_FAILURE", payload: error.response.data })
        })
}
