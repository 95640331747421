import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Row,
  message,
} from "antd";
// import { EditTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateUser } from "../Actions/usersAction";
import "./styles/clients.css";
// const Role = [
//   { value: 0, label: "Admin" },
//   { value: 1, label: "Employee" },
// ];
// const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
});
const { Text } = Typography;
export const UpdateEmployeeUser = ({ id, setPopoverVisible }) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const the_user = users.filter((client) => client.id === id);
  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_user[0],
  });
  // const handleUpdateUser = (data, event) => {
  //   event.preventDefault();
  //   data.id = id;
  //   dispatch(updateUser(data));
  //   setOpen(false);
  //   reset(data)
  // };
  const handleUpdateUser = (data, event) => {
    data.id = id;
    // event.preventDefault();
    dispatch(updateUser(data, successUpdateUser, failureUpdateUser));
  };
  const successUpdateUser = () => {
    setOpen(false);
    reset();
    setTimeout(() => {
      message.success("You have successfully update user.");
    }, 2000);
  };
  const failureUpdateUser = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    reset();
  };
  const handleOpenEmployeeuser = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenEmployeeuser()}
      >
        Edit
      </Button>
      <Modal
        title="Edit User details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateUser)}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>First Name</Text>
                <Controller
                  as={Input}
                  name="first_name"
                  control={control}
                  placeholder="First Name"
                  style={{ width: 200 }}
                />
                {errors.first_name && (
                  <span className="error_style">First Name is required</span>
                )}
              </Space>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>Last Name</Text>
                <Controller
                  as={Input}
                  name="last_name"
                  control={control}
                  placeholder="Last Name"
                  style={{ width: 200 }}
                />
                {errors.last_name && (
                  <span className="error_style">Last Name is required</span>
                )}
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>E-mail</Text>
                <Controller
                  as={Input}
                  name="email"
                  control={control}
                  placeholder="E-mail"
                  style={{ width: 200 }}
                />
                {errors.email && (
                  <span className="error_style">E-mail is required</span>
                )}
              </Space>
            </Col>
          </Row>
          {/* <Space direction="vertical" className="create_client_field">
              <Text>Role Type</Text>
              <Controller
                as={Select}
                control={control}
                options={Role}
                style={{ width: 300 }}
                name="role"
              />
              {errors.role && (
                <span className="error_style">Role Type is required</span>
              )}
            </Space> */}

          <Col className="notes_box">
            <Button type="primary" htmlType="submit" block style={{ top: 10 }}>
              Update
            </Button>
          </Col>
        </form>
      </Modal>
    </>
  );
};
