import constants from '../Utils/constants';
import _ from "lodash";
const initialState = {
    isGetPropertiesLoading: false, isGetSearchPropertiesLoading: false, isCreatePropertyLoading: false, isPublishedPropertyLoading: false, isUpdatePropertyLoading: false, isDeletePropertyLoading: false,
    properties: [], searchProperties: []
}

const propertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PROPERTIES_REQUEST:
            return {
                ...state, isGetPropertiesLoading: true
            }
        case constants.GET_PROPERTIES_SUCCESS:
           const sortBYArea = _.sortBy(action.payload, ['area.name']);
            return {
                ...state, isGetPropertiesLoading: false, properties: sortBYArea
            }
        case constants.GET_PROPERTIES_FAILURE:
            return {
                ...state, isGetPropertiesLoading: false
            }

        case constants.GET_PROPERTY_BY_ID_REQUEST:
            return {
                ...state, isGetPropertyByIdLoading: true
            }
        case constants.GET_PROPERTY_BY_ID_SUCCESS:
            return {
                ...state, isGetPropertyByIdLoading: false
            }
        case constants.GET_PROPERTY_BY_ID_FAILURE:
            return {
                ...state, isGetPropertyByIdLoading: false
            }

            case constants.GET_SEARCH_PROPERTIES_REQUEST:
            return {
                ...state, isGetSearchPropertiesLoading: true
            }
        case constants.GET_SEARCH_PROPERTIES_SUCCESS:
            return {
                ...state, isGetSearchPropertiesLoading: false, searchProperties: action.payload
            }
        case constants.GET_SEARCH_PROPERTIES_FAILURE:
            return {
                ...state, isGetSearchPropertiesLoading: false
            }

        case constants.CREATE_PROPERTY_REQUEST:
            return {
                ...state, isCreatePropertyLoading: true
            }
        case constants.CREATE_PROPERTY_SUCCESS:
            return {
                ...state, isCreatePropertyLoading: false
            }
        case constants.CREATE_PROPERTY_FAILURE:
            return {
                ...state, isCreatePropertyLoading: false
            }

            case constants.PUBLISHED_PROPERTY_REQUEST:
                return {
                    ...state, isPublishedPropertyLoading: true
                }
            case constants.PUBLISHED_PROPERTY_SUCCESS:
                return {
                    ...state, isPublishedPropertyLoading: false
                }
            case constants.PUBLISHED_PROPERTY_FAILURE:
                return {
                    ...state, isPublishedPropertyLoading: false
                }

        case constants.UPDATE_PROPERTY_REQUEST:
            return {
                ...state, isUpdatePropertyLoading: true
            }
        case constants.UPDATE_PROPERTY_SUCCESS:
            return {
                ...state, isUpdatePropertyLoading: false
            }
        case constants.UPDATE_PROPERTY_FAILURE:
            return {
                ...state, isUpdatePropertyLoading: false
            }

        case constants.DELETE_PROPERTY_REQUEST:
            return {
                ...state, isDeletePropertyLoading: true
            }
        case constants.DELETE_PROPERTY_SUCCESS:
            return {
                ...state, isDeletePropertyLoading: false
            }
        case constants.DELETE_PROPERTY_FAILURE:
            return {
                ...state, isDeletePropertyLoading: false
            }
            case "REMOVING_USER_CREDENTIALS":
                return {
                    ...initialState
                }
        default:
            return state
    }
}
export default propertiesReducer;