/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Row,
  Steps,
  Divider,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { createPostPropery } from "../Actions/postPropertyAction";
import "./styles/clients.css";
const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];

const { Step } = Steps;
const steps = [
  {
    title: "Your Information",
    // content: 'First-content',
  },
  {
    title: "Property Details",
    // content: 'Second-content',
  },
  {
    title: "Property Location",
    // content: 'Last-content',
  },
  {
    title: "Fourth Step",
    // content: 'Last-content',
  },
];

const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = yup.object().shape({
  // priority: yup.string().required("Required"),
  // gender: yup.string().required("Required"),
  // dete_of_birth: yup.string().required("Required"),
  // date_of_anniversary: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  // landmark: yup.string().required("Required"),
  // area: yup.string().required("Required"),
  // city: yup.string().required("Required"),
  // floor: yup.string().required("Required"),
  // home_number: yup.string().matches(phoneRegExp).required("Required"),
  // office_number: yup.string().matches(phoneRegExp).required("Required"),
  // email: yup.string().email().required("Required"),
  // business: yup.string().required("Required"),
  // company: yup.string().required("Required"),
  // designation: yup.string().required("Required"),
  // reference: yup.string().required("Required"),
  // note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const PostProperty = (props) => {
  const dispatch = useDispatch();
  const { createDialog, setCreateDialog } = props;

  const { areas, postProperties, isCreatePostPropertyLoading } = useSelector(
    (state) => {
      const { postProperties, isCreatePostPropertyLoading } =
        state.postProperties;
      const { areas } = state.areas;
      return {
        areas,
        postProperties,
        isCreatePostPropertyLoading,
      };
    }
  );

  useEffect(() => {
    dispatch(getAllAreas());
  }, []);
  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  // const watching = watch()

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handlePostProperty = (data, event) => {
    event.preventDefault();
    dispatch(createPostPropery(data));
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  return (
    <Spin spinning={isCreatePostPropertyLoading}>
      <Modal
        title="Create Property"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        centered
        maskClosable={false}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <form onSubmit={handleSubmit(handlePostProperty)}>
          <Col style={{ display: current === 0 ? "block" : "none" }}>
            <Space direction="vertical" className="create_client_field">
              <Text>Name</Text>
              <Controller
                as={Input}
                name="client[name]"
                control={control}
                placeholder="name"
              />
              {errors.name && (
                <span className="error_style">Client Name is required</span>
              )}
            </Space>
            <Space direction="vertical" className="create_client_field">
              <Text>Phone Number</Text>
              <Controller
                as={Input}
                name="client[mobile_number]"
                type="number"
                control={control}
                placeholder="Phone Number"
              />
              {errors.priority && (
                <span className="error_style">Phone Number is required</span>
              )}
            </Space>
            <Space direction="vertical" className="create_client_field">
              <Text>Email-Id</Text>
              <Controller
                as={Input}
                name="client[email]"
                control={control}
                placeholder="Email-Id"
              />
              {errors.priority && (
                <span className="error_style">Email-Id is required</span>
              )}
            </Space>

            {/* <Space direction="vertical" className="create_client_field">
              <Text>Priority</Text>
              <Controller
                as={Input}
                name="property[priority]"
                type="number"
                control={control}
                placeholder="priority"
              />
              {errors.priority && (
                <span className="error_style">Priority is required</span>
              )}
            </Space> */}
          </Col>

          <Col style={{ display: current === 1 ? "block" : "none" }}>
            <Space direction="vertical" className="create_client_field">
              <Text>Property Type</Text>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                style={{ width: 300 }}
                name="property[property_type]"
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Bhk</Text>
              <Controller
                as={Input}
                name="property[bhk]"
                control={control}
                placeholder="Total Bhk"
                type="number"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Floor</Text>
              <Controller
                as={Input}
                name="property[floor]"
                control={control}
                placeholder="Floor"
                type="number"
              />
              {errors.floor && (
                <span className="error_style">Floor is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Total Floor</Text>
              <Controller
                as={Input}
                name="property[total_floor]"
                control={control}
                placeholder="Total Floor"
                type="number"
              />
              {errors.total_floor && (
                <span className="error_style">Total Floor is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Cost</Text>
              <Controller
                name="property[cost]"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: 300 }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Cost is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Society Transfer Fee</Text>
              <Controller
                name="property[society_transfer_fee]"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: 300 }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.society_transfer_fee && (
                <span className="error_style">
                  Society Transfer Fee is required
                </span>
              )}
            </Space>

            <Space direction="horizontal" className="create_client_field">
              <Text>is Fix?</Text>
              <Controller
                name="property[is_fix]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />

              <Text>is Negotiable?</Text>
              <Controller
                name="property[is_negotiable]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Construction Year</Text>
              <Controller
                as={Input}
                name="property[construction_year]"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Visit Time</Text>
              <Controller
                as={Input}
                name="property[visit_time]"
                control={control}
                placeholder="Visit Time"
                type="time"
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>About Key</Text>
              <Controller
                as={Input}
                name="property[about_key]"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Vastu Complient</Text>
              <Controller
                as={Input}
                name="property[vastu_complient]"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Space>

            <Space direction="horizontal" className="create_client_field">
              <Text>is Residential?</Text>
              <Controller
                name="property[is_residential]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_client_field">
              <Text>is Commercial?</Text>
              <Controller
                name="property[is_commercial]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_client_field">
              <Text>is Plot?</Text>
              <Controller
                name="property[is_plot]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_client_field">
              <Text>is New?</Text>
              <Controller
                name="property[is_new]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_client_field">
              <Text>is Resell?</Text>
              <Controller
                name="property[is_resell]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_client_field">
              <Text>is Rent?</Text>
              <Controller
                name="property[is_rent]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_client_field">
              <Text>is Lease?</Text>
              <Controller
                name="property[is_lease]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Facing</Text>
              <Controller
                as={Input}
                name="property[facing]"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Space>

            <Col className="notes_box">
              <Text>Details</Text>
              <Controller
                as={TextArea}
                name="property[details]"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Col>

          <Col style={{ display: current === 2 ? "block" : "none" }}>
            <Space direction="vertical" className="create_client_field">
              <Text>Address</Text>
              <Controller
                as={Input}
                name="property[address]"
                control={control}
                placeholder="Address"
              />
              {errors.address && (
                <span className="error_style">Address is required</span>
              )}
            </Space>
            <Space direction="vertical" className="create_client_field">
              <Text>Area</Text>
              <Controller
                as={Select}
                control={control}
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                style={{ width: 300 }}
                name="property[area_id]"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>Landmark</Text>
              <Controller
                as={Input}
                name="property[landmark]"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Space>

            <Space direction="vertical" className="create_client_field">
              <Text>City</Text>
              <Controller
                as={Input}
                name="property[city]"
                control={control}
                placeholder="City"
              />
              {errors.city && (
                <span className="error_style">City is required</span>
              )}
            </Space>
          </Col>

          <Col style={{ display: current === 3 ? "block" : "none" }}>
            <Divider plain>Property Furniture</Divider>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Fix Kitchen?</Text>
              <Controller
                name="property_furniture_attributes[fix_kitchen]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Modular Kitchen?</Text>
              <Controller
                name="property_furniture_attributes[mod_kitchen]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Fix Drawing Room?</Text>
              <Controller
                name="property_furniture_attributes[fix_drawing_room]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Modular Drawing Room?</Text>
              <Controller
                name="property_furniture_attributes[mod_drawing_room]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Fix Bedroom?</Text>
              <Controller
                name="property_furniture_attributes[fix_bedroom]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Modular Bedroom?</Text>
              <Controller
                name="property_furniture_attributes[mod_bedroom]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Air Condition?</Text>
              <Controller
                name="property_furniture_attributes[air_condition]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Divider plain>Property Amenity</Divider>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Water 24hr?</Text>
              <Controller
                name="property_amenity_attributes[water_24hr]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Png?</Text>
              <Controller
                name="property_amenity_attributes[png]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Security?</Text>
              <Controller
                name="property_amenity_attributes[security]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>cctv?</Text>
              <Controller
                name="property_amenity_attributes[cctv]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Lift?</Text>
              <Controller
                name="property_amenity_attributes[lift]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Common Parking?</Text>
              <Controller
                name="property_amenity_attributes[common_parking]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Paid parking?</Text>
              <Controller
                name="property_amenity_attributes[paid_parking]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Garden?</Text>
              <Controller
                name="property_amenity_attributes[garden]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Children Play Area?</Text>
              <Controller
                name="property_amenity_attributes[children_play_area]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Club House?</Text>
              <Controller
                name="property_amenity_attributes[club_house]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Gymnasium?</Text>
              <Controller
                name="property_amenity_attributes[gymnasium]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Common Plot?</Text>
              <Controller
                name="property_amenity_attributes[common_plot]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Divider plain>Property Nearby</Divider>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Religious Place?</Text>
              <Controller
                name="property_nearby_attributes[religious_place]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Park Garden?</Text>
              <Controller
                name="property_nearby_attributes[park_garden]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Educational Institute?</Text>
              <Controller
                name="property_nearby_attributes[educational_institute]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Hospital/Dispansary?</Text>
              <Controller
                name="property_nearby_attributes[hospital_dispansary]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Space direction="horizontal" className="create_checkbox">
              <Text>Mall/market?</Text>
              <Controller
                name="property_nearby_attributes[mall_market]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>
            <Space direction="horizontal" className="create_checkbox">
              <Text>Bus Stop?</Text>
              <Controller
                name="property_nearby_attributes[bus_stop]"
                control={control}
                defaultValue={false}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </Space>

            <Divider plain>Property Area</Divider>
            <Space direction="vertical" className="create_checkbox">
              <Text>Super Plot Area SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[super_plot_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super Plot Area SqFt"
              />
            </Space>
            <Space direction="vertical" className="create_checkbox">
              <Text>Super Builtup Area SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super Builtup Area SqFt"
              />
            </Space>
            <Space direction="vertical" className="create_checkbox">
              <Text>Less SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less SqFt"
              />
            </Space>
            <Space direction="vertical" className="create_checkbox">
              <Text>Builtup Area SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup Area SqFt"
              />
            </Space>
            <Space direction="vertical" className="create_checkbox">
              <Text>Carpet Area SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet Area SqFt"
              />
            </Space>
            <Space direction="vertical" className="create_checkbox">
              <Text>Land Area SqFt</Text>
              <Controller
                as={Input}
                name="property_area_attributes[land_area_sqFt]"
                type="number"
                control={control}
                placeholder="Land Area SqFt"
              />
            </Space>
          </Col>

          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Post your Property for Free!!
            </Button>
          )}
        </form>
      </Modal>
    </Spin>
  );
};
export default PostProperty;
