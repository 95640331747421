/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { Anchor } from "antd";
const { Link } = Anchor;
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Table,
  Row,
  Popconfirm,
  Steps,
  Divider,
  message,
} from "antd";
import {
  DeleteFilled,
  DownOutlined,
  EditTwoTone,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { getClients } from "../Actions/clientActions";
import { getAllTasks, deleteTask } from "../Actions/tasksAction";
import { getSearchProperties } from "../Actions/propertiesActions";
import {
  getAllLeads,
  createLead,
  updateLead,
  deleteLead,
  getleadtype,
} from "../Actions/leadsActions";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateLead } from "../pages/updateLead";
import { CreateTask } from "../pages/tasks";
import { ViewLead } from "../pages/viewLead";
import Clients from "../pages/clients";
import { CreateInterested } from "../pages/interested";
import { createInterested } from "../Actions/interestedsAction";
import { CSVLink, CSVDownload } from "react-csv";

const LeadType = [
  { value: 1, label: "Flat" },
  { value: 2, label: "Pent House" },
  { value: 3, label: "Bunglow" },
  { value: 4, label: "Tenament" },
  { value: 5, label: "Duplex" },
  { value: 6, label: "Row House" },
  { value: 7, label: "Fam House" },
  { value: 8, label: "Office" },
  { value: 9, label: "Shop" },
  { value: 10, label: "Showroom" },
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 13, label: "Residential" },
  { value: 14, label: "Commercial" },
  { value: 15, label: "Agriculture" },
  { value: 16, label: "NonAgriculture" },
  { value: 17, label: "Redevelopment" },
];
const ClientType = [
  { value: 1, label: "Residential" },
  { value: 2, label: "Residential Rent" },
  { value: 3, label: "Commercial" },
  { value: 4, label: "Commercial Rent" },
  { value: 5, label: "Plot" },
];
const Zone = [
  { value: 1, label: "R1" },
  { value: 2, label: "R2" },
  { value: 3, label: "R3" },
];
const LeadAreaType = [
  { value: 1, label: "SqFt" },
  { value: 2, label: "Yard" },
];
const { Step } = Steps;

const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Lead Details",
    // content: 'Second-content',
  },
  // {
  //   title: "Amenity",
  //   // content: 'Last-content',
  // },
];

const schema = yup.object().shape({
  client_id: yup.string().required("Required"),
  // area_ids: yup.string().required("Required"),
  // types_of_lead: yup
  //   .number()
  //   .transform((value) => (isNaN(value) ? undefined : value))
  //   .required("Required"),
  min_budget: yup.string().required("Required"),
  max_budget: yup.string().required("Required"),
  // priority: yup.string().required("Required"),
  // gender: yup.string().required("Required"),
  // dete_of_birth: yup.string().required("Required"),
  // date_of_anniversary: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  // landmark: yup.string().required("Required"),
  // area: yup.string().required("Required"),
  // city: yup.string().required("Required"),
  // budget: yup.string().required("Required"),
  // home_number: yup.string().matches(phoneRegExp).required("Required"),
  // office_number: yup.string().matches(phoneRegExp).required("Required"),
  // email: yup.string().email().required("Required"),
  // business: yup.string().required("Required"),
  // company: yup.string().required("Required"),
  // designation: yup.string().required("Required"),
  // reference: yup.string().required("Required"),
  // note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const Leads = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const {
    clients,
    areas,
    leads,
    isGetLeadsLoading,
    isCreateLeadLoading,
    isUpdateLeadLoading,
    isDeleteLeadLoading,
    lead_typs,
  } = useSelector((state) => {
    const {
      leads,
      isGetLeadsLoading,
      isCreateLeadLoading,
      isUpdateLeadLoading,
      isDeleteLeadLoading,
      lead_typs,
    } = state.leads;
    const { clients } = state.clients;
    const { areas } = state.areas;
    return {
      clients,
      areas,
      leads,
      isGetLeadsLoading,
      isCreateLeadLoading,
      isUpdateLeadLoading,
      isDeleteLeadLoading,
      lead_typs,
    };
  });

  const [isurgentLead, setIsUrgentLead] = useState(true);
  const [selectMultipleArea, setSelectMultipleArea] = useState([]);
  const [selectMultipleLeadTypes, SetselectMultipleLeadTypes] = useState([]);

  useEffect(() => {
    dispatch(getAllLeads(`/leads?is_urgent=${isurgentLead}`));
  }, [isurgentLead]);

  useEffect(() => {
    dispatch(getClients());
    dispatch(getAllAreas());
    dispatch(getleadtype());
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_commercial: false,
      is_residential: false,
      is_plot: false,
    },
    mode: "onChange",
  });
  const watching = getValues([
    "is_residential",
    "is_commercial",
    "is_plot",
    "client_id",
    "area_ids",
    "types_of_lead",
    "min_budget",
    "max_budget",
  ]);
  const { is_residential, is_commercial, is_plot } = watching;

  const [createDialog, setCreateDialog] = useState(false);
  // const [createNewClientDialog, setcreateNewClientDialog] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    const {
      client_id: cid,
      area_ids: aid,
      types_of_lead: lT,
      min_budget: minbgt,
      max_budget: maxbgt,
    } = getValues([
      "client_id",
      "area_ids",
      "types_of_lead",
      "min_budget",
      "max_budget",
    ]);

    if (selectMultipleArea.length > 0 && selectMultipleLeadTypes.length > 0) {
      if ((current === 0 && (!cid || !aid || !lT)) || !minbgt || !maxbgt) {
        trigger([
          "client_id",
          "area_ids",
          "types_of_lead",
          "min_budget",
          "max_budget",
        ]);
      }
      // else if (current === 1 && !bgt) {
      //   trigger(["budget"]);
      // }
      else {
        // if (Object.keys(errors).length === 0){
        setCurrent(current + 1);
        // }
      }
    } else if (selectMultipleArea.length === 0) {
      setError("area_ids", {
        types: {
          required: "Area is required",
        },
      });
    } else if (selectMultipleLeadTypes.length === 0) {
      setError("types_of_lead", {
        types: {
          required: "Lead type is required",
        },
      });
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // const handleCreateLead = (data, event) => {
  //   // event.preventDefault();
  //   dispatch(createLead(data, `/leads?is_urgent=${isurgentLead}`));
  //   setTimeout(() => {
  //     message.success("Create Lead successful.");
  //   }, 1000);
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset();
  // };
  const handleCreateLead = (data, event) => {
    // data.is_lead = true;
    const area = selectMultipleArea.map((id) => String(id));
    data.area_ids = area;
    data.types_of_lead = { types: selectMultipleLeadTypes };
    dispatch(
      createLead(
        data,
        `/leads?is_urgent=${isurgentLead}`,
        successCreateLead,
        failureCreateLead
        // IsEmpty
      )
    );
  };

  const successCreateLead = () => {
    reset();
    setCurrent(0);
    setCreateDialog(false);
    SetselectMultipleLeadTypes([]);
    setSelectMultipleArea([]);
    clearErrors();
    setTimeout(() => {
      message.success("You have successfully create lead.");
    }, 2000);
    // dispatch(getAllLeads(`/leads?is_urgent=${isurgentLead}`));
  };

  const failureCreateLead = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    setCurrent(0);
    SetselectMultipleLeadTypes([]);
    setSelectMultipleArea([]);
    reset();
    clearErrors();
  };
  const onMultipleSelectAreaHandler = (data) => {
    setSelectMultipleArea(data);
    setError("area_ids", "");
  };

  const onMultipleSelectLeadTypeHandler = (data) => {
    SetselectMultipleLeadTypes(data);
  };

  return (
    <Spin
      spinning={
        isGetLeadsLoading ||
        isCreateLeadLoading ||
        isUpdateLeadLoading ||
        isDeleteLeadLoading
      }
    >
      {/* <Button
				type="text"
				onClick={() => setCreateDialog(true)}
				style={{ fontWeight: 500, color: "#1890ff" }}
			>
				Create Lead
			</Button> */}
      <Modal
        title="Create Lead"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Row>
          {/* <Col 
           xl={24}
           lg={24}
           md={24}
           sm={24}
           xs={24}
         > */}
          <Steps
            current={current}
            size="small"
            responsive="true"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          {/* </Col> */}
        </Row>
        <form onSubmit={handleSubmit(handleCreateLead)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col>
            {/* <Button type="link" onClick={() => setcreateNewClientDialog(true)}>Create New</Button>
        {createDialog && (
        <Clients  
        createNewClientDialog={createNewClientDialog}
        setcreateNewClientDialog={setcreateNewClientDialog} 
          // handleDialog={() => setCreateDialog(true)} 

           />
			)} */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ClientType}
                style={{ width: "100%" }}
                name="client_type"
              />
              {errors.client_type && (
                <span className="error_style">Client Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Zone</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Zone}
                name="zone"
                style={{ width: "100%" }}
              />
              {errors.zone && (
                <span className="error_style">Zone is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Areas</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    showSearch={false}
                    value={selectMultipleArea}
                    options={
                      areas &&
                      areas.length > 0 &&
                      areas.map((val, index) => {
                        return { label: val.name, value: val.id };
                      })
                    }
                    placeholder="Select areas"
                    style={{ width: "100%" }}
                    onChange={(value) => onMultipleSelectAreaHandler(value)}
                  />
                )}
                name="area_ids"
              />

              <span className="error_style">
                {errors?.area_ids?.types?.required}
              </span>
            </Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="vertical" className="create_client_field">
                <Text>Priority</Text>
                <Controller
                  as={Input}
                  name="priority"
                  type="number"
                  control={control}
                  placeholder="priority"
                  style={{ width: 200 }}
                />
                {errors.priority && (
                  <span className="error_style">Priority is required</span>
                )}
              </Space>
            </Col> */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Types</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    showSearch={false}
                    value={selectMultipleLeadTypes}
                    options={lead_typs}
                    placeholder="Select lead types"
                    style={{ width: "100%" }}
                    onChange={(value) => onMultipleSelectLeadTypeHandler(value)}
                  />
                )}
                name="types_of_lead"
              />
              {errors.types_of_lead && (
                <span className="error_style">Lead Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              // style={{
              //   display:
              //     current === 1 && is_residential === true ? "block" : "none",
              // }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Bhk</Text>
              </Row>
              <Controller
                as={Input}
                name="bhk"
                control={control}
                placeholder="Total Bhk"
                type="number"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Residential?</Text>
                <Controller
                  name="is_residential"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_commercial', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Commercial?</Text>
                <Controller
                  name="is_commercial"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Plot?</Text>
                <Controller
                  name="is_plot"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_commercial', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Budget</Text>
              </Row>
              <Controller
                name="min_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.min_budget && (
                <span className="error_style">Minimum Budget is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Budget</Text>
              </Row>
              <Controller
                name="max_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.max_budget && (
                <span className="error_style">Maximum Budget is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction After</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_after"
                control={control}
                placeholder="Construction After"
              />
              {errors.construction_after && (
                <span className="error_style">
                  Construction After is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>New?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={5}
              lg={5}
              md={5}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Urgent?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_urgent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={5}
              lg={5}
              md={5}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 24]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Resell?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>Home Loan?</Text>
                <Controller
                  name="home_loan"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}

          <Row></Row>
          {/* <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Rent?</Text>
                <Controller
                  name="is_rent"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Lease?</Text>
                <Controller
                  name="is_lease"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {" "}
              <Text>Details</Text>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[minimum_sqFt]"
                type="number"
                control={control}
                placeholder="Minimum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[maximum_sqFt]"
                type="number"
                control={control}
                placeholder="Maximum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Area Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={LeadAreaType}
                name="lead_area_type"
                style={{ width: "100%" }}
              />
              {errors.lead_area_type && (
                <span className="error_style">Lead Area Type is required</span>
              )}
            </Col>
          </Row>
          {/* <Button
								type="primary"
								htmlType="submit"
								block
								style={{ top: 10 }}
							>
								Create Lead
							</Button> */}
          <Row>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Furniture</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Air Condition?</Text>
                <Controller
                  name="lead_furniture_attributes[air_condition]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Amenity</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Water 24hr?</Text>
                <Controller
                  name="lead_amenity_attributes[water_24hr]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Png?</Text>
                <Controller
                  name="lead_amenity_attributes[png]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Security?</Text>
                <Controller
                  name="lead_amenity_attributes[security]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>cctv?</Text>
                <Controller
                  name="lead_amenity_attributes[cctv]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Lift?</Text>
                <Controller
                  name="lead_amenity_attributes[lift]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Parking?</Text>
                <Controller
                  name="lead_amenity_attributes[common_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Paid parking?</Text>
                <Controller
                  name="lead_amenity_attributes[paid_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Garden?</Text>
                <Controller
                  name="lead_amenity_attributes[garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Children Play Area?</Text>
                <Controller
                  name="lead_amenity_attributes[children_play_area]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Club House?</Text>
                <Controller
                  name="lead_amenity_attributes[club_house]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Gymnasium?</Text>
                <Controller
                  name="lead_amenity_attributes[gymnasium]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Plot?</Text>
                <Controller
                  name="lead_amenity_attributes[common_plot]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Nearby</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Religious Place?</Text>
                <Controller
                  name="lead_nearby_attributes[religious_place]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Park Garden?</Text>
                <Controller
                  name="lead_nearby_attributes[park_garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Educational Institute?</Text>
                <Controller
                  name="lead_nearby_attributes[educational_institute]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Hospital/Dispansary?</Text>
                <Controller
                  name="lead_nearby_attributes[hospital_dispansary]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>

            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Mall/market?</Text>
                <Controller
                  name="lead_nearby_attributes[mall_market]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Bus Stop?</Text>
                <Controller
                  name="lead_nearby_attributes[bus_stop]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row justify="end" gutter={[0, 8]}>
            <Col span={24}></Col>
            {!isCreateLeadLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <>
                {isCreateLeadLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>
      </Modal>
      {leads && (
        <AllLeads
          data={leads}
          handleDialog={() => setCreateDialog(true)}
          isurgentLead={isurgentLead}
          setIsUrgentLead={setIsUrgentLead}
        />
      )}
    </Spin>
  );
};

const AllLeads = (props) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { clients } = useSelector((state) => state.clients);
  const { tasks, isGetTasksLoading } = useSelector((state) => state.tasks);
  const { searchProperties, isGetSearchPropertiesLoading } = useSelector(
    (state) => state.properties
  );
  const { users } = useSelector((state) => state.users);
  const { handleDialog, isurgentLead, setIsUrgentLead } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentLead, setCurrentLead] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const isUrgentLeadonChange = (e) => {
    setIsUrgentLead(e.target.checked);
  };

  const onRowSelect = (record) => {
    setCurrentLead(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const onMatchPropertyRowClick = (property) => {};
  const onTaskRowClick = (task) => {};
  useEffect(() => {
    if (currentLead.id) {
      dispatch(getAllTasks(currentLead.id));
      dispatch(getSearchProperties(currentLead.id));
    }
  }, [currentLead]);

  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const headers = [
    { label: "Client Name", key: "client_name" },
    { label: "Client Type", key: "client_type" },
    { label: "Zone", key: "zone" },
    { label: "Area", key: "area" },
    { label: "Maximum Area", key: "lead_area_attributes" },
    { label: "Maximum Budget", key: "max_budget" },
    { label: "Entry Date", key: "created_at" },
    { label: "Last communication date", key: "last_communication_date" },
  ];

  const columns = (areas, clients) => [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "1",
      width: "20%",
      ...getColumnSearchProps("client_name"),
      // fixed: 'left',
      render: (record, object) => {
        return (
          <Button
            //  onClick={() => handleViewLead(record)}
            type="link"
          >
            {record}{" "}
          </Button>
        );
      },
    },
    {
      title: "Client Type",
      dataIndex: "client_type",
      key: "2",
      width: "20%",
      ...getColumnSearchProps("client_type"),
      // sorter: (a, b) => a.home_number - b.home_number,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "3",
      width: "20%",
      ...getColumnSearchProps("zone"),
    },
    {
      title: "Areas",
      dataIndex: "area",
      key: "2",
      width: "20%",
      render: (record, object) => {
        const { areas_of_lead } = object;
        const LastAreaOfArray = areas_of_lead[areas_of_lead.length - 1];
        return (
          <div>
            {areas_of_lead?.map(
              (areasName) =>
                areasName?.area_name +
                `${
                  LastAreaOfArray?.area_name === areasName?.area_name
                    ? ""
                    : ", "
                }`
            )}
          </div>
        );
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    {
      title: "Maximum Area",
      dataIndex: "lead_area_attributes",
      key: "5",
      width: "20%",
      render: (record, object) => {
        return <>{record ? record.maximum_sqFt : ""}</>;
      },
      sorter: (a, b) => a.maximum_sqFt - b.maximum_sqFt,
    },
    {
      title: "Maximum Budget",
      dataIndex: "max_budget",
      key: "6",
      width: "20%",
      render: (max_budget) => {
        return (
          <div>{`₹ ${max_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        );
      },
      sorter: (a, b) => a.max_budget - b.max_budget,
    },
    {
      title: "Entry Date",
      dataIndex: "created_at",
      key: "7",
      width: "20%",
      sorter: (a, b) => a.created_at - b.created_at,
    },
    {
      title: "Last communication date",
      dataIndex: "last_communication_date",
      key: "8",
      width: "40%",
      sorter: (a, b) => a.last_communication_date - b.last_communication_date,
    },
    {
      title: "id",
      width: 250,
      dataIndex: "id",
      key: "9",
      ...getColumnSearchProps("id"),
    },
    // {
    //   title: "Lead Type",
    //   // width: 250,
    //   dataIndex: "lead_type",
    //   key: "1",
    //   // fixed: "left",
    //   filters: [
    //     {
    //       text: "lead_type",
    //       value: "lead_type",
    //     },
    //   ],
    //   onFilter: (value, record) => record.lead_type.indexOf(value) === 0,
    // },
    // {
    //   title: "Total BHK",
    //   // width: 250,
    //   dataIndex: "bhk",
    //   key: "1",
    //   // fixed: "left",
    //   sorter: (a, b) => a.bhk - b.bhk,
    // },
    // {
    //   title: "Minimum Budget",
    //   dataIndex: "min_budget",
    //   key: "10",
    //   // width: 150,
    //   render: (min_budget) => {
    //     return <div>{`₹ ${min_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
    //   },
    //   sorter: (a, b) => a.min_budget - b.min_budget,
    // },
    // {
    //   title: "Construction After",
    //   dataIndex: "construction_after",
    //   key: "10",
    //   // width: 150,
    //   filters: [
    //     {
    //       text: "construction_after",
    //       value: "construction_after",
    //     },
    //   ],
    //   onFilter: (value, record) => record.construction_after.indexOf(value) === 0,
    // },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   key: "11",
    //   // width: 150,
    //   filters: [
    //     {
    //       text: "details",
    //       value: "details",
    //     },
    //   ],
    //   onFilter: (value, record) => record.details.indexOf(value) === 0,
    // },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <ActionComponent id={id} isurgentLead={isurgentLead} />,
    },
  ];

  const matchProperties = (areas, clients) => [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "7",
      width: 250,
      ...getColumnSearchProps("client_name"),
      render: (record, object) => {
        return (
          <Button
            //  onClick={() => handleViewLead(record)}
            type="link"
          >
            {record}{" "}
          </Button>
        );
      },
    },
    {
      title: "Property Type",
      // width: 250,
      dataIndex: "property_type",
      key: "1",
      // fixed: "left",
      filters: [
        {
          text: "property_type",
          value: "property_type",
        },
      ],
      onFilter: (value, record) => record.property_type.indexOf(value) === 0,
    },
    {
      title: "Floor",
      // width: 250,
      dataIndex: "floor",
      key: "1",
      // fixed: "left",
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Total Floor",
      // width: 150,
      dataIndex: "total_floor",
      key: "2",
      // fixed: "left",
      sorter: (a, b) => a.total_floor - b.total_floor,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "3",
      // width: 150,
      render: (cost) => {
        return <div>{`₹ ${cost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Society Transfer Fee",
      dataIndex: "society_transfer_fee",
      key: "4",
      // width: 150,
      render: (society_transfer_fee) => {
        return (
          <div>
            {`₹ ${society_transfer_fee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        );
      },
      sorter: (a, b) => a.society_transfer_fee - b.society_transfer_fee,
    },
    {
      title: "Visit Time",
      dataIndex: "visit_time",
      key: "5",
      // width: 200,
      sorter: (a, b) => a.visit_time - b.visit_time,
    },
    {
      title: "Vastu Complient",
      dataIndex: "vastu_complient",
      key: "5",
      // width: 200,
      sorter: (a, b) => a.vastu_complient - b.vastu_complient,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "6",
      // width: 250,
      filters: [
        {
          text: "address",
          value: "address",
        },
      ],
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "7",
      // width: 150,
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.id };
        }),
      onFilter: (value, record) => {
        return record.area_id == value;
      },
    },
    {
      title: "Landmark",
      dataIndex: "landmark",
      key: "8",
      // width: 150,
      filters: [
        {
          text: "landmark",
          value: "landmark",
        },
      ],
      onFilter: (value, record) => record.landmark.indexOf(value) === 0,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "9",
      // width: 150,
      filters: [
        {
          text: "city",
          value: "city",
        },
      ],
      onFilter: (value, record) => record.city.indexOf(value) === 0,
    },
    {
      title: "Construction Year",
      dataIndex: "construction_year",
      key: "10",
      // width: 150,
      sorter: (a, b) => a.construction_year - b.construction_year,
    },
    {
      title: "About Key",
      // width: 250,
      dataIndex: "about_key",
      key: "1",
      // fixed: "left",
      sorter: (a, b) => a.about_key - b.about_key,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "11",
      // width: 150,
      filters: [
        {
          text: "details",
          value: "details",
        },
      ],
      onFilter: (value, record) => record.details.indexOf(value) === 0,
    },
    {
      title: "id",
      width: 250,
      dataIndex: "id",
      key: "12",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <MatchPropertyActionComponent id={id} currentLead={currentLead} />
      ),
    },
  ];
  const Taskcolumns = (users) => [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("user_name"),
      // fixed: 'left',
      render: (record, object) => {
        return (
          <Button
            //  onClick={() => handleViewLead(record)}
            type="link"
          >
            {record}
          </Button>
        );
      },
    },
    {
      title: "Due Date",
      // width: 250,
      dataIndex: "due_date",
      key: "due_date",
      sorter: (a, b) => a.due_date - b.due_date,
    },
    {
      title: "Note",
      // width: 250,
      dataIndex: "note",
      key: "note",
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "id",
      // width: 250,
      dataIndex: "id",
      key: "1",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <TaskActionComponent id={id} currentLead={currentLead} />,
    },
  ];

  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Row gutter={[12, 0]}>
            <Col>
              {isurgentLead ? (
                <Title level={4}>Urgent Leads({props.data.length})</Title>
              ) : (
                <Title level={4}>Leads({props.data.length})</Title>
              )}
            </Col>
            <Col>
              <Checkbox checked={isurgentLead} onChange={isUrgentLeadonChange}>
                Urgent Lead ?
              </Checkbox>
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create Lead
          </Button>
        </Col> */}
        <Col>
          <Row gutter={[16, 0]} align="middle">
            <Col>
              <CSVLink
                data={props.data}
                headers={headers}
                filename={`residential_${new Date().toISOString()}.csv`}
              >
                Download
              </CSVLink>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleDialog()}>
                Create Lead
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas, clients)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
      />
      {showSearch && showTask && (
        <>
          <Row gutter={[8, 0]}>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>
                    Matching Properties({searchProperties.length})
                    {/* for {currentLead.client.name}  */}
                  </Title>
                </Col>
              </Row>
              <Spin spinning={isGetSearchPropertiesLoading}>
                <MyTable
                  columns={matchProperties(areas, clients)}
                  data={searchProperties}
                  onRowSelect={(record) => onMatchPropertyRowClick(record)}
                />
              </Spin>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>
                    Task({tasks.length})
                    {/* for {currentLead.client.name}'s requirements */}
                  </Title>
                </Col>
              </Row>
              <Spin spinning={isGetTasksLoading}>
                <MyTable
                  columns={Taskcolumns(users)}
                  data={tasks}
                  onRowSelect={(record) => onTaskRowClick(record)}
                />
              </Spin>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const ActionComponent = ({ id, isurgentLead }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteLead = (lead_id, event) => {
    // data.is_lead = true;
    lead_id = id;
    dispatch(
      deleteLead(
        id,
        `/leads?is_urgent=${isurgentLead}`,
        successDeleteLead,
        failureDeleteLead
        // IsEmpty
      )
    );
  };
  const successDeleteLead = () => {
    // setCreateDialog(false);
    // setCurrent(0);
    // reset();
    setTimeout(() => {
      message.success("You have successfully delete lead.");
    }, 2000);
    // dispatch(deleteLead(`/leads?is_urgent=${isurgentLead}`));
  };
  const failureDeleteLead = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    // setCreateDialog(false);
    // setCurrent(0);
    // reset();
  };
  const content = (
    <div>
      <CreateTask
        attachment_id={id}
        attachment_type="Lead"
        setPopoverVisible={setPopoverVisible}
      />
      &nbsp;&nbsp;
      <ViewLead id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      <UpdateLead
        id={id}
        isurgentLead={isurgentLead}
        setPopoverVisible={setPopoverVisible}
      />
      &nbsp;&nbsp;
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deleteLead(id, `/leads?is_urgent=${isurgentLead}`))}
        onConfirm={() =>
          handleDeleteLead(id, `/leads?is_urgent=${isurgentLead}`)
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      {/* <DeleteFilled
				onClick={() => dispatch(deleteLead(id))}
				style={{ cursor: "pointer", color: "red" }}
			/> */}
    </div>
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};

const TaskActionComponent = ({ id, currentLead }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <div>
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() =>
          dispatch(deleteTask(id, { attachment_id: currentLead.id }))
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};

const MatchPropertyActionComponent = ({ id, currentLead }) => {
  const dispatch = useDispatch();

  const handleCreateInterested = (data) => {
    // event.preventDefault();

    dispatch(createInterested({ property_id: id, lead_id: currentLead.id }));
  };
  // const dispatch = useDispatch();
  // const content = (
  //   <div>
  //     <CreateInterested property_id={id} lead_id={currentLead} />
  //   </div>
  // );
  return (
    <Button
      type="link"
      onClick={() => handleCreateInterested()}
      block
      style={{ top: 10 }}
    >
      Inteseted
    </Button>
    // <Popover content={content} trigger="focus">
    //   <Button type="link">Action 2</Button>
    // </Popover>
  );
};

export default Leads;
