import API from '../Utils/API';

export const getAllPreTasks = () => dispatch => {
    dispatch({ type: "GET_PRE_TASKS_REQUEST" })
    API.get(`/tasks?date=pre`)
        .then(response => {
            dispatch({ type: "GET_PRE_TASKS_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_PRE_TASKS_FAILURE", payload: error.response.data })
        })
}

export const getAllPostTasks = () => dispatch => {
    dispatch({ type: "GET_POST_TASKS_REQUEST" })
    API.get(`/tasks?date=post`)
        .then(response => {
            dispatch({ type: "GET_POST_TASKS_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_POST_TASKS_FAILURE", payload: error.response.data })
        })
}
export const getAllTasks = (attachment_id) => dispatch => {
    dispatch({ type: "GET_TASKS_REQUEST" })
    API.get(`/tasks?attachment_id=${attachment_id}`)
        .then(response => {
            dispatch({ type: "GET_TASKS_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_TASKS_FAILURE", payload: error.response.data })
        })
}

export const createTask = (data) => dispatch => {
    dispatch({ type: "CREATE_TASK_REQUEST", payload: data })
    API.post('/tasks', data)
        .then(response => {
            dispatch({ type: "CREATE_TASK_SUCCESS", payload: response })
            dispatch(getAllTasks(data.attachment_id))
        })
        .catch(error => {
            dispatch({ type: "CREATE_TASK_FAILURE", payload: error.response.data })
        })
}

export const updateTask = (data, type) => dispatch => {
    dispatch({ type: "UPDATE_TASK_REQUEST", payload: data })
    API.put(`/tasks/${data.id}`, data)
        .then(response => {
            dispatch({ type: "UPDATE_TASK_SUCCESS", payload: response })
            if (type === "pre"){
            dispatch(getAllPreTasks())
            }
            else if (type === "post"){
                dispatch(getAllPostTasks())
            }
            else {
                dispatch(getAllTasks())
            }
        })
        .catch(error => {
            dispatch({ type: "UPDATE_TASK_FAILURE", payload: error.response.data })
        })
}

export const deleteTask = (task_id, {attachment_id}) => dispatch => {
    dispatch({ type: "DELETE_TASK_REQUEST" })
    API.delete(`/tasks/${task_id}`)
        .then(response => {
            dispatch({ type: "DELETE_TASK_SUCCESS", payload: response })
            dispatch(getAllTasks(attachment_id))
        })
        .catch(error => {
            dispatch({ type: "DELETE_TASK_FAILURE", payload: error.response.data })
        })
}