/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Row, Col, Layout, Button, Image } from "antd";
import "../components/header.css";
import logo from "../assets/images/logo.svg";
import { getPostProperties } from "../Actions/postPropertyAction";
import PostProperty from "../pages/postProperty";
import { history } from "../Utils/history";
import { Typography, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const HomeHeader = (props) => {
  const {buyRef,rentRef,
    sellRef,
    serviceRef,
    aboutUsRef, postProperty} = props;
	const { loginWithRedirect, isAuthenticated } = useAuth0();
  const sendToRefs = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
	return (
		<Row justify="space-between">
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Header className="header-fixed header">
					<Row>
						<Col xl={2} lg={2} md={4} sm={4} xs={{ span: 10 }}>
							<Link to="/">
								<Image alt="logo" src={logo} preview={false} />
							</Link>
						</Col>
						<Col xl={6} lg={4} md={2} sm={2} xs={{ span: 12 }}></Col>
						<Col xl={16} lg={18} md={18} sm={18} xs={{ span: 2 }} 
						 style={{
							display:
							  postProperty === true ? "none" : "block",
						  }}
						>
							<Menu
								// style={{ justifyContent: "space-around", display: "flex" }}
								theme="light"
								mode="horizontal"
								expandIcon
								defaultSelectedKeys={["item1"]}
								overflowedIndicator={<MenuOutlined />}
							>
								<Menu.Item key="item1" 
								// onClick={() =>sendToRefs(buyRef)}
								>
									Buy
								</Menu.Item>
								<Menu.Item key={"item2"} 
								// onClick={() =>sendToRefs(rentRef)}
								>
									Rent
								</Menu.Item>
								{/* <Menu.Item key={"item3"} onClick={() =>sendToRefs(sellRef)}>
									Sell
								</Menu.Item> */}
								<Menu.Item key={"item4"}onClick={() =>sendToRefs(serviceRef)}>
									Property Service
								</Menu.Item>
								<Menu.Item key={"item5"} onClick={() =>sendToRefs(aboutUsRef)}>
									About us
								</Menu.Item>
								<Menu.Item key={"item6"} onClick={() => loginWithRedirect()}>
									<Button className="sign-in">Sign In</Button>
								</Menu.Item>
								<Menu.Item key={"item7"}>
									<Link to="/post">
										<Button className="post-property">Post Property</Button>
									</Link>
								</Menu.Item>
							</Menu>
						</Col>
					</Row>
				</Header>
			</Col>
		</Row>
	);
};
export default HomeHeader;
