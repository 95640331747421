/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";
import { Row, Col, Typography } from "antd";
import { getPostProperties } from "../Actions/postPropertyAction";
import "../components/projectgallery.css";
import ProjectCard from "../components/ProjectCard";
import { Carousel } from "antd";
const { Title } = Typography;

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const Projects = (props) => {
  const { refTag, title, projects } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div id='test'>
        <Row  justify="start" style={{ paddingTop: 20 }}>
          <Col span={2}></Col>
          <Col xl={20} lg={20} md={20} sm={20} xs={{ span: 20 }}>
            <Title level={1}>{title}</Title>
          </Col>
        </Row>
        <Row justify="start">
          <Col offset={2}>
            <Title level={5}>
              Fulfill your career dreams, enjoy all the achievements of the city
              center and luxury housing to the fullest.
            </Title>
          </Col>
        </Row>

        <Row style={{ paddingTop: 50 }}>
          <Col span={2}></Col>
          <Col span={20}>
            <Carousel autoplay {...settings}>
              {projects && projects.length > 0 && projects.map((rp, i) => (
                <ProjectCard key={i} project={rp} />
              ))}
            </Carousel>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Projects;
