import API from "../Utils/API";

export const getAllAreas = () => (dispatch) => {
  dispatch({ type: "GET_AREAS_REQUEST" });
  API.get("/areas")
    .then((response) => {
      dispatch({ type: "GET_AREAS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_AREAS_FAILURE", payload: error.response.data });
    });
};

export const createArea =
  (data, successCreateArea, failureCreateArea) => (dispatch) => {
    dispatch({ type: "CREATE_AREA_REQUEST", payload: data });
    API.post("/areas", data)
      .then((response) => {
        dispatch({ type: "CREATE_AREA_SUCCESS", payload: response });
        dispatch(getAllAreas());
        const SAA = successCreateArea ? successCreateArea() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_AREA_FAILURE",
            payload: error.response.data,
          });

          const FAA = failureCreateArea ? failureCreateArea() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "CREATE_AREA_FAILURE", payload: error.response.data })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };

export const updateArea =
  (data, successUpdateArea, failureUpdateArea) => (dispatch) => {
    dispatch({ type: "UPDATE_AREA_REQUEST", payload: data });
    API.put(`/areas/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_AREA_SUCCESS", payload: response });
        dispatch(getAllAreas());
        const SAA = successUpdateArea ? successUpdateArea() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_AREA_FAILURE",
            payload: error.response.data,
          });

          const FAA = failureUpdateArea ? failureUpdateArea() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "UPDATE_AREA_FAILURE", payload: error.response.data })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };

export const deleteArea =
  (area_id, successDeleteArea, failureDeleteArea) => (dispatch) => {
    dispatch({ type: "DELETE_AREA_REQUEST" });
    API.delete(`/areas/${area_id}`)
      .then((response) => {
        dispatch({ type: "DELETE_AREA_SUCCESS", payload: response });
        // dispatch(getAllAreas())
        const SAA = successDeleteArea ? successDeleteArea() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_AREA_FAILURE",
            payload: error.response.data,
          });

          const FAA = failureDeleteArea ? failureDeleteArea() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "DELETE_AREA_FAILURE", payload: error })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };
