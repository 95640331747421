import API from '../Utils/API';
import constants from '../Utils/constants';
export const getInteresteds = () => dispatch => {
    dispatch({ type: constants.GET_INTERESTEDS_REQUEST })
    API.get('/interesteds')
        .then(response => {
            dispatch({ type: constants.GET_INTERESTEDS_SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: constants.GET_INTERESTEDS_FAILURE, payload: error.response.data })
        })
}

export const createInterested = (data) => dispatch => {
    dispatch({ type: constants.CREATE_INTERESTED_REQUEST, payload: data })
    API.post('/interesteds', data)
        .then(response => {
            dispatch({ type: constants.CREATE_INTERESTED_SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: constants.CREATE_INTERESTED_FAILURE, payload: error.response.data })
        })
}

export const updateInterested = (data) => dispatch => {
    dispatch({ type: constants.UPDATE_INTERESTED_REQUEST, payload: data })
    API.put(`/interesteds/${data.id}`, data)
        .then(response => {
            dispatch({ type: constants.UPDATE_INTERESTED_SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: constants.UPDATE_INTERESTED_FAILURE, payload: error.response.data })
        })
}

export const deleteInterested = (interested_id) => dispatch => {
    dispatch({ type: constants.DELETE_INTERESTED_REQUEST })
    API.delete(`/interesteds/${interested_id}`)
        .then(response => {
            dispatch({ type: constants.DELETE_INTERESTED_SUCCESS, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: constants.DELETE_INTERESTED_FAILURE, payload: error.response.data })
        })
}