import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Select,
  Row,
} from "antd";
// import { EditTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateTask } from "../Actions/tasksAction";
import "./styles/clients.css";

const schema = yup.object().shape({
  due_date: yup.string().required("Required"),
  note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdatTask = ({ id, type, setPopoverVisible }) => {
  const dispatch = useDispatch();
  let allTasks = [];
  const { preTask, postTask, tasks } = useSelector((state) => state.tasks);
  if (type === "pre") {
    allTasks = preTask;
  } else if (type === "post") {
    allTasks = postTask;
  } else {
    allTasks = tasks;
  }

  const { users } = useSelector((state) => state.users);
  const the_task = allTasks.filter((task) => task.id === id);

  const [taskOpen, setTaskOpen] = useState(false);
  the_task[0].user_id = the_task[0].user.id;
  const newDate = the_task[0].due_date ? the_task[0].due_date.split("/") : {};
  the_task[0].due_date =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_task[0],
  });
  // const watching = watch();
  // const { area_id } = watch();
  const handleUpdateTask = (data, event) => {
    event.preventDefault();
    data.id = id;
    dispatch(updateTask(data, type));
    setTaskOpen(false);
    reset(data);
  };
  const handleOpenUT = () => {
    setPopoverVisible(false);
    setTaskOpen(true);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenUT()}
      >
        Edit
      </Button>
      {/* <EditTwoTone
        style={{ cursor: "pointer" }}
        onClick={() => setTaskOpen(true)}
      /> */}
      <Modal
        title="Edit Task details"
        visible={taskOpen}
        onCancel={() => setTaskOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateTask)}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>User Name</Text>
                <Controller
                  as={Select}
                  showSearch
                  control={control}
                  options={
                    users &&
                    users.length > 0 &&
                    users.map((val, index) => {
                      return { label: val.first_name, value: val.id };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                  name="user_id"
                />
                {errors.user_id && (
                  <span className="error_style">User name is required</span>
                )}
              </Space>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Space direction="vertical" className="create_client_field">
                <Text>Due Date</Text>
                <Controller
                  as={Input}
                  format="DD-MM-YYYY"
                  name="due_date"
                  control={control}
                  placeholder="Due Date"
                  type="date"
                  style={{ width: 200 }}
                />
                {errors.due_date && (
                  <span className="error_style">Due Date is required</span>
                )}
              </Space>
            </Col>
          </Row>

          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="notes_box">
              <Text>Note</Text>
              <Controller
                as={TextArea}
                name="note"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.note && (
                <span className="error_style">Note is required</span>
              )}
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                block
                // style={{ top: 10 }}
              >
                Update Task
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
