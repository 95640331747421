import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Typography, Button, Modal, Row, Card } from "antd";
import "./styles/clients.css";
const { Text, Title } = Typography;
export const ViewProperty = ({ id, setPopoverVisible }) => {
  const { properties } = useSelector((state) => state.properties);
  const the_property = properties.filter((property) => property.id === id);
  const [open, setOpen] = useState(false);
  the_property[0].area_id = the_property[0].area.id;
  the_property[0].client_id = the_property[0].client.id;
  const defaultValues = the_property[0];
  const {
    client_name,
    cost,
    society_transfer_fee,
    floor,
    total_floor,
    is_fix,
    visit_time,
    about_key,
    address,
    area,
    landmark,
    google_link,
    construction_year,
    vastu_complient,
    is_property,
    deal_for,
    bhk,
    property_type,
    facing,
    is_residential,
    is_commercial,
    is_plot,
    is_furnished,
    details,
    property_furniture_attributes,
    property_amenity_attributes,
    property_nearby_attributes,
    property_area_attributes,
  } = defaultValues;
  const {
    fix_drawing_room, 
    mod_drawing_room,
    fix_bedroom,
    mod_bedroom,
    fix_kitchen,
    mod_kitchen,
    electronics,
    pentry,
    attachable_washroom,
    air_condition,
  } = property_furniture_attributes;
  const {
    water_supply,
    water_24hr,
    png,
    security,
    cctv,
    lift,
    common_parking,
    paid_parking,
    garden,
    children_play_area,
    club_house,
    gymnasium,
    common_plot,
  } = property_amenity_attributes;
  const {
    religious_place,
    park_garden,
    educational_institute,
    hospital_dispansary,
    mall_market,
    bus_stop,
  } = property_nearby_attributes;
  const {
    builtup_area_sqFt,
    carpet_area_sqFt,
    land_area_sqFt,
    less_sqFt,
    super_builtup_area_sqFt,
    super_plot_area_sqFt,
  } = property_area_attributes;
  const handleViewBP = () => {
    setPopoverVisible(false)
    setOpen(true)
  }
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleViewBP()}
      >
        ViewProperty{" "}
      </Button>
      <Modal
        title="View Property Details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={1000}
      >
        <Row gutter={[8, 0]}>
          <Col>
            <Title level={5}>
              Client:{" "}
              <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                {client_name}
              </Text>
            </Title>
          </Col>
        </Row>
        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[0, 0]}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Title level={5}>
                Cost:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {`₹ ${cost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </Title>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Society Transfer Fee:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {`₹ ${society_transfer_fee}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                </Text>
              </Title>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Floor:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {floor}
                </Text>
              </Title>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Total Floor:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {total_floor}
                </Text>
              </Title>
            </Col>
          </Row>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Fixed / sNegotiable:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {is_fix === true ? "Fix" : "Negotiable"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
        <Card>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Visit Time:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {visit_time}
                </Text>
              </Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Title level={5}>
                About Key:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {about_key}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[0, 0]}>
            <Col>
              <Title level={5}>
                Address:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {address}
                </Text>
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Area:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {area.name}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Landmark:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {landmark}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                City:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {google_link}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card>
          <Row gutter={[48, 0]}>
            <Col>
              <Title level={5}>
                Constuction Year:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {construction_year}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Vastu Complient:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {vastu_complient}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Residential / Commercial/Plot{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {is_property}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
        <Card style={{ backgroundColor: "#F3F3FA" }}>
          <Row gutter={[0, 0]}>
            <Col>
              <Title level={5}>
                New / Resell / Rent / Lease{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {deal_for}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Card>
          <Row gutter={[0, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24} style={{display: (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",}}>
              {is_residential === true ? (
                <Title level={5}>
                  BHK:{" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {bhk}
                  </Text>
                </Title>
              ) : is_commercial === true ? (
                <Title level={5}>
                  Furnished:{" "}
                  <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                    {is_furnished === true ? "Yes" : "No"}
                  </Text>
                </Title>
              ) : null}
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Property Type:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {property_type}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Facing:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {facing}
                </Text>
              </Title>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Details:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {details}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>
        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Furniture</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
            backgroundColor: "#F3F3FA",
          }}
        >
          <Row gutter={[48, 0]}>
            {/* <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Drawing Room{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_drawing_room === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col> */}
             <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Furnished{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {is_furnished === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_residential === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Drawing Room{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_drawing_room === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            {/* <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Bedroom{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_bedroom === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col> */}
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_residential === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Bedroom{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_bedroom === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            {/* <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_residential === true ? "block" : "none" }}
            >
              <Title level={5}>
                Fix Kitchen{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {fix_kitchen === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col> */}
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_commercial === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Electronics{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {electronics === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_commercial === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Pentry{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {pentry === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_commercial === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Attachble Washroom{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {attachable_washroom === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>

            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: (is_residential === true && is_furnished === true) ? "block" : "none" }}
            >
              <Title level={5}>
                Modular Kitchen{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mod_kitchen === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            
            <Col xl={8} lg={8} md={24} sm={24} xs={24} style={{display: is_furnished === true ? "block" : "none"}}>
              <Title level={5}>
                Air Condition{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {air_condition === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Amenity</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Row gutter={[48, 0]}>
          <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ display: is_commercial === true ? "block" : "none" }}
            >
              <Title level={5}>
                Water Supply{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {water_supply === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Water 24hr{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {water_24hr === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Png{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {png === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Security{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {security === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Cctv{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {cctv === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Lift{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {lift === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Common Parking{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {common_parking === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Paid Parking{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {paid_parking === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Garden{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {garden === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Children Play Area{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {children_play_area === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Club House{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {club_house === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Gymnasium{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {gymnasium === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Common Plot{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {common_plot === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
          }}
        >
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Nearby</Title>
          </Col>
        </Row>
        <Card
          style={{
            display:
              is_residential === true || is_commercial === true
                ? "block"
                : "none",
            backgroundColor: "#F3F3FA",
          }}
        >
          <Row gutter={[48, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Religious Place{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {religious_place === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Park/Garden{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {park_garden === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Educational/Institute{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {educational_institute === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Hospital/Dispansary{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {hospital_dispansary === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Mall/market{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {mall_market === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Bus Stop{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {bus_stop === true ? "Yes" : "No"}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={5}>Area SqFt.</Title>
          </Col>
        </Row>
        <Card style={{display: is_residential ? "flex" : "none"}}>
        <Row gutter={[48, 0]}>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Super Builtup Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Less %:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {less_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Carpet Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {carpet_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Plot in Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_plot_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
              Construction in Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            </Row>
          </Card>
          <Card style={{display: is_commercial ? "flex" : "none"}}>
        <Row gutter={[48, 0]}>
        <Col>
              <Title level={5}>
                Super Builtup Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Less %:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {less_sqFt}
                </Text>
              </Title>
            </Col>
            <Col>
              <Title level={5}>
                Carpet Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {carpet_area_sqFt}
                </Text>
              </Title>
            </Col>
            </Row>
          </Card>
        <Card style={{display: is_plot ? "block" : "none", backgroundColor: "#F3F3FA"}}>
        <Row gutter={[48, 0]}>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
              Super Plot Area Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_plot_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Super Builtup Area Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Less %:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {less_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Carpet Area Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {carpet_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
              Construction Yard:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {land_area_sqFt}
                </Text>
              </Title>
            </Col>
        </Row>
        </Card>
        {/* <Card
          style={{ backgroundColor: is_plot === true ? "#F3F3FA" : "#fffff" }}
        >
          <Row gutter={[48, 0]}>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Builtup Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Carpet Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {carpet_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{
                display: is_plot === true ? "block" : "none",
              }}
            >
              <Title level={5}>
                Land Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {land_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Less SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {less_sqFt}
                </Text>
              </Title>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Title level={5}>
                Super Builtup Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_builtup_area_sqFt}
                </Text>
              </Title>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{
                display:
                  is_residential === true || is_plot === true
                    ? "block"
                    : "none",
              }}
            >
              <Title level={5}>
                Super Plot Area SqFt:{" "}
                <Text style={{ fontSize: 15, fontWeight: "lighter" }}>
                  {super_plot_area_sqFt}
                </Text>
              </Title>
            </Col>
          </Row>
        </Card> */}
      </Modal>
    </>
  );
};
